body,
input[type=text],
input[type=email],
input[type=url],
input[type=password],
textarea,
input[type=tel] {
    font-family: Lato, Helvetica, Arial, sans-serif;
}

body {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
input[type=submit],
input[type=button],
a.button,
.button,
.post_quote_title,
label,
.portfolio_filter_dropdown,
strong[itemprop="author"],
#page_content_wrapper .posts.blog li a,
.page_content_wrapper .posts.blog li a {
    font-family: Oswald, Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
    font-weight: 300;
}

h1 {
    font-size: 34px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

body,
#wrapper,
#page_content_wrapper.fixed,
#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle span,
h2.widgettitle span,
#gallery_lightbox h2,
#body_loading_screen,
h3#reply-title span {
    background-color: #ffffff;
}

body,
.pagination a,
#gallery_lightbox h2,
.post_info a {
    color: #000000;
}

::selection {
    background-color: #000000;
}

a {
    color: #222222;
}

a:hover,
a:active,
.post_info_comment a i {
    color: #999999;
}

h1,
h2,
h3,
h4,
h5,
pre,
code,
tt,
blockquote,
.post_header h5 a,
.post_header h3 a,
.post_header.grid h6 a,
.post_header.fullwidth h4 a,
.post_header h5 a,
blockquote,
.site_loading_logo_item i {
    color: #222222;
}

#social_share_wrapper,
hr,
#social_share_wrapper,
.post.type-post,
#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle,
h2.widgettitle,
h5.widgettitle,
.comment .right,
.widget_tag_cloud div a,
.meta-tags a,
.tag_cloud a,
#footer,
#post_more_wrapper,
#page_content_wrapper .inner .sidebar_content,
#page_caption,
#page_content_wrapper .inner .sidebar_content.left_sidebar,
.ajax_close,
.ajax_next,
.ajax_prev,
.portfolio_next,
.portfolio_prev,
.portfolio_next_prev_wrapper.video .portfolio_prev,
.portfolio_next_prev_wrapper.video .portfolio_next,
.separated,
.blog_next_prev_wrapper,
#post_more_wrapper h5,
#ajax_portfolio_wrapper.hidding,
#ajax_portfolio_wrapper.visible,
.tabs.vertical .ui-tabs-panel,
table tr td,
table tr th,
.ui-tabs li:first-child,
.ui-tabs .ui-tabs-nav li,
.ui-tabs.vertical .ui-tabs-nav li,
.ui-tabs.vertical.right .ui-tabs-nav li.ui-state-active,
.ui-tabs.vertical .ui-tabs-nav li:last-child,
#page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li.current-menu-item a,
.page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li.current-menu-item a,
.pricing_wrapper,
.pricing_wrapper li,
.ui-accordion .ui-accordion-header,
.ui-accordion .ui-accordion-content,
#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle:before,
h2.widgettitle:before,
.page_tagline,
.ppb_blog_minimal .one_third_bg,
.portfolio_desc.wide {
    border-color: #e1e1e1;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
textarea,
input[type=tel] {
    background-color: #ffffff;
    color: #000;
    border-color: #e1e1e1;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=url]:focus,
textarea:focus,
input[type=tel]:focus {
    border-color: #000000;
}

input[type=submit],
input[type=button],
a.button,
.button,
a.button.fullwidth {
    font-family: Oswald, Helvetica, Arial, sans-serif;
}

input[type=submit],
input[type=button],
a.button,
.button,
.pagination span,
.pagination a:hover {
    background-color: #888888;
}

.pagination span,
.pagination a:hover {
    border-color: #888888;
}

input[type=submit],
input[type=button],
a.button,
.button,
.pagination a:hover,{
    color: #ffffff;
    border-color: #888888;
}

.frame_top,
.frame_bottom,
.frame_left,
.frame_right {
    background: #ffffff;
}

#menu_wrapper .nav ul li a,
#menu_wrapper div .nav li > a {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #666666;
}

#menu_wrapper .nav ul li a.hover,
#menu_wrapper .nav ul li a:hover,
#menu_wrapper div .nav li a.hover,
#menu_wrapper div .nav li a:hover {
    color: #222222;
}

#menu_wrapper div .nav > li.current-menu-item > a,
#menu_wrapper div .nav > li.current-menu-parent > a,
#menu_wrapper div .nav > li.current-menu-ancestor > a,
#menu_wrapper div .nav li ul li.current-menu-item a,
#menu_wrapper div .nav li.current-menu-parent ul li.current-menu-item a {
    color: #222222;
}

#menu_wrapper .nav ul li ul li a,
#menu_wrapper div .nav li ul li a,
#menu_wrapper div .nav li.current-menu-parent ul li a {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #888888;
}

.mobile_main_nav li a:hover,
.mobile_main_nav li a:active,
#sub_menu li a:hover,
#sub_menu li a:active,
.mobile_menu_wrapper .sidebar_wrapper h2.widgettitle {
    color: #222222;
}

#menu_wrapper .nav ul li ul li a:hover,
#menu_wrapper div .nav li ul li a:hover,
#menu_wrapper div .nav li.current-menu-parent ul li a:hover,
#menu_wrapper .nav ul li.megamenu ul li ul li a:hover,
#menu_wrapper div .nav li.megamenu ul li ul li a:hover,
#menu_wrapper .nav ul li.megamenu ul li ul li a:active,
#menu_wrapper div .nav li.megamenu ul li ul li a:active {
    background: #f9f9f9;
}

#menu_wrapper .nav ul li ul,
#menu_wrapper div .nav li ul {
    background: #ffffff;
    border-color: #e1e1e1;
}

#menu_wrapper div .nav li.megamenu ul li > a,
#menu_wrapper div .nav li.megamenu ul li > a:hover,
#menu_wrapper div .nav li.megamenu ul li > a:active {
    color: #444444;
}

#menu_wrapper div .nav li.megamenu ul li {
    border-color: #eeeeee;
}

.above_top_bar {
    background: #222222;
}

#top_menu li a,
.top_contact_info,
.top_contact_info i,
.top_contact_info a,
.top_contact_info a:hover,
.top_contact_info a:active {
    color: #ffffff;
}

.mobile_menu_wrapper #searchform {
    background: #ffffff;
}

.mobile_menu_wrapper #searchform input[type=text],
.mobile_menu_wrapper #searchform button i {
    color: #222222;
}

.mobile_menu_wrapper {
    background-color: #f9f9f9;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: inherit;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
}

.mobile_main_nav li a,
#sub_menu li a {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.mobile_main_nav li a,
#sub_menu li a,
.mobile_menu_wrapper .sidebar_wrapper a,
.mobile_menu_wrapper .sidebar_wrapper,
#close_mobile_menu i {
    color: #222222;
}

#page_caption {
    background-color: #ffffff;
    padding-top: 80px;
    padding-bottom: 80px;
}

#page_caption h1,
.ppb_title {
    font-size: 48px;
}

#page_caption h1,
.ppb_title,
.post_caption h1 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #222222;
}

#page_caption.hasbg {
    height: 70vh;
}

h2.ppb_title {
    font-size: 42px;
    text-transform: uppercase;
}

.page_tagline,
.ppb_subtitle,
.post_header .post_detail,
.recent_post_detail,
.post_detail,
.thumb_content span,
.portfolio_desc .portfolio_excerpt,
.testimonial_customer_position,
.testimonial_customer_company {
    color: #999999;
}

.page_tagline,
.post_header .post_detail,
.recent_post_detail,
.post_detail,
.thumb_content span,
.portfolio_desc .portfolio_excerpt,
.testimonial_customer_position,
.testimonial_customer_company {
    font-size: 13px;
}

.page_tagline {
    font-weight: 400;
}

.post_header .post_detail,
.recent_post_detail,
.post_detail,
.thumb_content span,
.portfolio_desc .portfolio_excerpt,
.testimonial_customer_position,
.testimonial_customer_company {
    letter-spacing: 2px;
    text-transform: uppercase;
}

#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle,
h2.widgettitle,
h5.widgettitle {
    font-family: Oswald, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222222;
}

#page_content_wrapper .inner .sidebar_wrapper .sidebar .content,
.page_content_wrapper .inner .sidebar_wrapper .sidebar .content {
    color: #444444;
}

#page_content_wrapper .inner .sidebar_wrapper a,
.page_content_wrapper .inner .sidebar_wrapper a {
    color: #222222;
}

#page_content_wrapper .inner .sidebar_wrapper a:hover,
#page_content_wrapper .inner .sidebar_wrapper a:active,
.page_content_wrapper .inner .sidebar_wrapper a:hover,
.page_content_wrapper .inner .sidebar_wrapper a:active {
    color: #999999;
}

.footer_bar {
    background-color: #ffffff;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
}

#footer,
#copyright {
    color: #000000;
}

#copyright a,
#copyright a:active,
#footer a,
#footer a:active,
#footer_menu li a,
#footer_menu li a:active {
    color: #000000;
}

#copyright a:hover,
#footer a:hover,
.social_wrapper ul li a:hover,
#footer_menu li a:hover {
    color: #000000;
}

.footer_bar_wrapper,
.footer_bar {
    border-color: #e1e1e1;
}

.footer_bar_wrapper .social_wrapper ul li a {
    color: #000000;
}
