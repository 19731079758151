@keyframes subNavIn{
	from{
		transform: translateX(180px);
		-webkit-transform: translateX(180px);
		opacity: 0;
	}
	to{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes subNavIn{
	from{
		transform: translateX(180px);
		-webkit-transform: translateX(180px);
		opacity: 0;
	}
	to{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes subNavOut{
	from{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
	to{
		transform: translateX(180px);
		-webkit-transform: translateX(180px);
		opacity: 0;
	}
}

@-webkit-keyframes subNavOut{
	from{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
	to{
		transform: translateX(180px);
		-webkit-transform: translateX(180px);
		opacity: 0;
	}
}

@keyframes mainNavOut{
	from{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
	to{
		transform: translateX(-180px);
		-webkit-transform: translateX(-180px);
		opacity: 0;
	}
}

@-webkit-keyframes mainNavOut{
	from{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
	to{
		transform: translateX(-180px);
		-webkit-transform: translateX(-180px);
		opacity: 0;
	}
}

@keyframes mainNavIn{
	from{
		transform: translateX(-180px);
		-webkit-transform: translateX(-180px);
		opacity: 0;
	}
	to{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes mainNavIn{
	from{
		transform: translateX(-180px);
		-webkit-transform: translateX(-180px);
		opacity: 0;
	}
	to{
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

.fadeIn{
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 0.5s;	
	-webkit-animation-duration: 0.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;
	
	-webkit-animation-fill-mode:forwards; 
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

	visibility: visible !important;
	display: inline-block !important;
}

@keyframes fadeIn {
	0% {
		transform: scale(1);
		opacity: 0.0;		
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(1);
		opacity: 0.0;		
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1;
	}		
}

.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 0.5s;	
	-webkit-animation-duration: 0.5s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;
	
	-webkit-animation-fill-mode:forwards; 
    -moz-animation-fill-mode:forwards;
    -ms-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
    
    visibility: visible !important;	
}

@keyframes slideUp {
	0% {
		opacity: 0;
		visibility: hidden;
		transform: translateY(10%);
	}
	100% {
		opacity: 1;
		visibility: visible;
		transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(10%);
	}
	100% {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translateY(0%);
	}	
}

.slideUp2{
    animation-name: slideUp2;
    -webkit-animation-name: slideUp2;	

    animation-duration: 0.2s;	
    -webkit-animation-duration: 0.2s;

    animation-timing-function: ease;	
    -webkit-animation-timing-function: ease;
    
    -webkit-animation-fill-mode:forwards; 
    -moz-animation-fill-mode:forwards;
    -ms-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
}

@keyframes slideUp2 {
    0% {
    	opacity: 0;
    	transform: translateY(15%);
    }
    100% {
    	opacity: 1;
    	transform: translateY(0%);
    }	
}

@-webkit-keyframes slideUp2 {
    0% {
    	opacity: 0;
    	transform: translateY(15%);
    }
    100% {
    	opacity: 1;
    	transform: translateY(0%);
    }	
}

.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	visibility: visible !important;					
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}			
	100% {
		opacity: 1;
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}			
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
	}	
}

.slideDown2{
	animation-name: slideDown2;
	-webkit-animation-name: slideDown2;	

	animation-duration: 0.2s;	
	-webkit-animation-duration: 0.2s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	visibility: visible !important;
	display: inline-block !important;				
}

@keyframes slideDown2 {
	0% {
		opacity: 0;
		transform: translateY(-50%);
	}			
	100% {
		opacity: 1;
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-50%);
	}			
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
	}	
}

.bigEntrance{
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;

	visibility: visible !important;			
}

@keyframes bigEntrance {
	0% {
		transform: scale(0.5);
		opacity: 0.5;
	}
	30% {
		transform: scale(1.03);		
		opacity: 1 !important;
	}
	45% {
		transform: scale(0.98);
		opacity: 1 !important;
	}
	60% {
		transform: scale(1.01);		
		opacity: 1 !important;
	}	
	100% {
		transform: scale(1);
		opacity: 1 !important;
	}		
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.5);
		opacity: 0.5;
	}
	30% {
		-webkit-transform: scale(1.03);		
		opacity: 1 !important;
	}
	45% {
		-webkit-transform: scale(0.98);
		opacity: 1 !important;
	}
	60% {
		-webkit-transform: scale(1.01);		
		opacity: 1 !important;
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1 !important;
	}				
}

.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(10%);
		opacity: 0;
	}		
	100% {
		transform: translateX(0%);
		opacity: 1 !important;
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(10%);
		opacity: 0;
	}			
	100% {
		-webkit-transform: translateX(0%);
		opacity: 1 !important;
	}
}

.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		opacity: 0;
		transform: translateX(-10%);
	}		
	100% {
		transform: translateX(0%);
		opacity: 1 !important;
	}	
}

@-webkit-keyframes slideRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-10%);
	}		
	100% {
		-webkit-transform: translateX(0%);
		opacity: 1 !important;
	}
}