#wrapper
{
  padding-top: 61px;
}

.top_bar .standard_wrapper
{
  height: 60px;
}

#logo_wrapper
{
  text-align: left;
  border: 0;
  padding: 0;
  float: left;
  width: 100%;
  height: 100%;
}

#logo_wrapper .logo_container
{
  display: table;
  padding: 0;
  height: 100%;
}

.top_bar.scroll #logo_wrapper
{
  display: block;
}

#nav_wrapper
{
  border-top: 0 !important;
}

.logo_container
{
  display: block;
  float: left;
  width: auto;
  height: auto;
}

html[data-menu=leftalign] #menu_wrapper
{
  width: auto;
  float: right;
  padding: 0;
  height: 100%;
}

#menu_wrapper .nav ul li a, #menu_wrapper div .nav li > a
{
  position: relative;
  top: -1px;
  border-bottom: 1px solid  transparent;
}

#menu_wrapper .nav ul li ul, #menu_wrapper div .nav li ul
{
  margin-top: -2px;
}

#menu_wrapper .nav ul li a.hover, #menu_wrapper .nav ul li a:hover, #menu_wrapper div .nav li a.hover, #menu_wrapper div .nav li a:hover, #menu_wrapper div .nav > li.current-menu-item > a, #menu_wrapper div .nav > li.current-menu-parent > a, #menu_wrapper div .nav > li.current-menu-ancestor > a
{
  border-bottom: 1px solid #222;
}

html[data-menu=leftalign] #logo_right_button
{
  position: relative;
  display: table-cell;
    vertical-align: middle;
    right: 0;
    padding-left: 5px;
}

#mobile_nav_icon
{
  top: 3px;
}

/*
#Tablet (Portrait)
==================================================
*/

@media only screen and (min-width: 768px) and (max-width: 960px) {
  html[data-menu=leftalign] #logo_wrapper { padding: 0; }
  html[data-menu=leftalign] .logo_container { margin-top: 0px; }
}

/*
#Mobile (Portrait)
==================================================
*/

@media only screen and (max-width: 767px) {
  html[data-menu=leftalign] .top_bar .standard_wrapper
  {
    height: 48px;
    padding: 0;
  }

  html[data-menu=leftalign] #nav_wrapper, html[data-menu=leftalign] #menu_wrapper
  {
    display: table;
  }

  html[data-menu=leftalign] .top_bar
  {
    padding-bottom: 15px;
  }
}
