.entry-content img {
	margin: 0 0 1.5em 0;
}

div.alignleft, img.alignleft {
	display: inline-block;
	float: left;
	margin-right: 1em;
	margin-bottom: 1em;
}
div.alignright, img.alignright {
	display: inline-block;
	float: right;
	margin-left: 1em;
	margin-top: 1em;
}
div.aligncenter, img.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-caption {
	text-align: center;
	margin-bottom: 1.5em;
	font-size: 12px;
}
.img-caption img {
	border: 0 none;
	margin: 0;
	padding: 0;
}
.img-caption p.img-caption-text {
	margin: 0;
	font-style: italic;
	opacity: 0.5;
	text-align: center;
	padding-bottom: 0 !important;
}
.gallery dl {
	margin: 0;
	border: 0;
	padding: 0;
}

blockquote.left {
	float: left;
	margin-left: 0;
	margin-right: 20px;
	text-align: right;
	width: 33%;
}
blockquote.right {
	float: right;
	margin-left: 20px;
	margin-right: 0;
	text-align: left;
	width: 33%;
}

.gallery-caption
{
	font-size: 12px;
}

html
{
	height: 100%;
}

blockquote:before, blockquote:after, q:before, q:after
{
	content:"";
}
blockquote, q
{
	quotes:"" "";
}
a img
{
	border:none;
}

p {
	padding-top:0.1em;
	padding-bottom:0.1em;
}

em
{
	font-style: italic;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
	max-width: 100%;
	height: auto;
}

.center
{
	text-align: center;
}

#page_content_wrapper p, .page_content_wrapper p
{
	padding-top:0.5em;
	padding-bottom:0.5em;
}

#page_content_wrapper p:empty, .page_content_wrapper p:empty
{
	padding: 0 !important;
	margin: 0 !important;
}

#footer p {
	padding-top:0.5em;
	padding-bottom:0.5em;
}

html, body {
	min-height: 100%;
}

img, a img {
	image-rendering: optimizeQuality;
}

em { font-style: italic; }

::selection {
	background: #efc337;
	color: #fff;
}

table
{
	border-spacing: 0;
}

.sticky
{
	background: #ffffe0;
	padding: 30px 20px 20px 20px;
	border: 1px solid #e6db55 !important;
	box-sizing: border-box;
}

.marginright
{
	margin-right: 7px;
}

img.alignright
{
	float:right; margin:0 0 1em 2em;
}

img.alignleft
{
	float:left; margin:0 2em 1em 0;
}

img.aligncenter
{
	display: block; margin-left: auto; margin-right: auto
}

a img.alignright
{
	float:right; margin:0 0 1em 2em;
}

a img.alignleft
{
	float:left; margin:0 2em 1em 0;
}

a img.aligncenter
{
	display: block; margin-left: auto; margin-right: auto
}

.sticky, .bypostauthor
{
	font-weight: bold;
}

.paging {
  margin-left: 4em;
  margin-right: 4em;
}

.left {
  text-align: left;
}

.right {
  float: right;
}
