.parallax-block {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  max-width: none !important;
  max-height: none !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
}
.parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none !important;
  max-height: none !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
}