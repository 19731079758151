.pswp__dynamic-caption {
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transition: opacity 120ms linear !important; /* override default */
}

.pswp-caption-content {
  display: none;
}

.pswp__dynamic-caption a {
  color: #fff;
}

.pswp__dynamic-caption--faded {
  opacity: 0 !important;
}

.pswp__dynamic-caption--aside {
  width: auto;
  max-width: 300px;
  padding: 20px 15px 20px 20px;
  margin-top: 70px;
}

.pswp__dynamic-caption--below {
  width: auto;
  max-width: 700px;
  padding: 15px 0 0;
}

.pswp__dynamic-caption--on-hor-edge {
  padding-left: 15px;
  padding-right: 15px;
}

.pswp__dynamic-caption--mobile {
  width: 100%;
  background: rgba(0,0,0,0.5);
  padding: 10px 15px;

  right: 0;
  bottom: 0;

  /* override styles that were set via JS.
    as they interfere with size measurement */
  top: auto !important;
  left: 0 !important;
}
