/*------------------------------------------------------------------
[Master Stylesheet]

Project:	Photo Me Wordpress Theme
Version:	1.0
Last change:	09/07/15 [initial release]
Assigned to:	ThemeGoods
Primary use:	Wordpress Theme
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Color codes]

Background:	#ffffff (white)
Content:	#444444 (grey)
Header:		#222222 (dark grey)
Footer:		#ffffff (white)

a (standard):	#222222 (dark grey)
a (hover):		#000000 (black)
a (active):		#000000 (black)
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Typography]

Body copy:		13px/1.65em 'Oswald', 'Helvetica Neue', Arial, Verdana, sans-serif
Header:			30px/1.65em 'Oswald', 'Helvetica Neue', Arial, Verdana, sans-serif
Input, textarea:	13px 'Oswald','Helvetica Neue', Arial, Verdana, sans-serif
Sidebar heading:	14px 'Oswald','Helvetica Neue', Arial, Verdana, sans-serif
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Body
2. Navigation / .top_bar
3. Footer / #footer
4. Content / #page_content_wrapper
5. Social Sharing / #social_share_wrapper
6. Sidebar / .sidebar_wrapper
7. Form & Input
8. Pagination / .pagination
9. Widgets
10. Portfolio Template Elements
11. Contact Form Captcha / #captcha-wrap
-------------------------------------------------------------------*/

body
{
	background: #ffffff;
	font-family: Lato, 'Helvetica Neue', Arial,Verdana,sans-serif;
	padding: 0;
	margin: 0;
	color: #444;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	line-height: 1.6;
	word-wrap: break-word;
	-webkit-overflow-scrolling: touch;
}

body.overflow_hidden
{
	overflow: hidden;
}

body.loading #wrapper
{
	opacity: .3;
}

body.notouch
{
	-webkit-overflow-scrolling: auto !important;
}

html[data-style=flow] body
{
	overflow: hidden;
}

html[data-style=fullscreen], html[data-style=fullscreen] body
{
	width: 100%;
	height: 100%;
	overflow: hidden;
}

html[data-style=fullscreen] #wrapper
{
  	background:  transparent !important;
	height: 100%;
	overflow: hidden;
}

body.js_nav .tf_bg img
{
	position: static;
}

.floatleft
{
	float: left !important;
}

.floatright
{
	float: right !important;
}

.one .floatleft
{
	width: 100%;
}

.divider
{
	clear: both;
}

#wrapper
{
	float: left;
	width: 100%;
	-webkit-transition: -webkit-transform 500ms ease;
	-moz-transition: transform 500ms ease;
	-o-transition: -o-transform 500ms ease;
	transition: transform 500ms ease;
	padding-top: 138px; /* Default menu height */
}

#wrapper.hasbg
{
	padding-top: 0 !important;
}

hr
{
	height: 1px;
	border: 0;
	border-top: 1px solid #ebebeb;
	background: transparent;
	margin: auto;
	width: 100%;
	clear: both;
}

hr.small
{
	width: 20%;
}

hr.thick
{
	border-top: 3px solid #ebebeb;
}

hr.dotted
{
	border-top: 2px dotted #ebebeb;
}

hr.dashed
{
	border-top: 2px dashed #ebebeb;
}

hr.faded {
    border: 0;
    height: 1px;

}
hr.faded:before {
    top: -0.5em;
    height: 1em;
}
hr.faded:after {
    content:'';
    height: 0.5em;
    top: 1px;
}

hr.faded, hr.faded:before {
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
}

.hr_totop
{
	position: relative;
	top: -15px;
	right: 0;
	float: right;
	display: block;
	background: #fff;
	z-index: 2;
	padding: 0 0 0 7px;
	font-size:13px;
	color: #000;
	font-weight: 900;
}

.small_line
{
	height: 1px;
	border: 0;
	border-top: 1px solid #666;
	background: transparent;
	margin: auto;
	width: 30px;
	display: block;
	margin-top: 30px;
}

a
{
	color: #222;
	text-decoration: none;
	-webkit-transition: color .2s linear, background .1s linear;
	-moz-transition: color .2s linear, background .1s linear;
	-ms-transition: color .2s linear, background .1s linear;
	-o-transition: color .2s linear, background .1s linear;
	transition: color .2s linear, background .1s linear;
}

a:hover, .post_header h5 a:hover
{
	color: #000;
	text-decoration: none;
}

a:active, .post_header h5 a:active
{
	color: #000;
	text-decoration: none;
}

a.comment-reply-link
{
	color: #222;
}

p a {
  text-decoration: underline;
}

h1
{
	font-size: 34px;
}

h2
{
	font-size: 30px;
}

h2.number
{
	font-size: 50px;
	line-height: 50px;
}

.mobile_menu_wrapper #searchform
{
	background: #333;
}

.mobile_menu_wrapper #searchform input[type=text]
{
	background: transparent;
	width: 80%;
	border: 0;
	display: inline-block;
	color: #fff;
}

.mobile_menu_wrapper #searchform button
{
	background: transparent;
	display: inline-block;
	border: 0;
}

.mobile_menu_wrapper #searchform button i
{
	color: #fff;
	font-size: 13px;
	opacity: 0.5;
}

.mobile_menu_wrapper .widget_search #searchform
{
	background: transparent !important;
	width: 100%
}

.mobile_menu_wrapper .widget_search #searchform input[type=text]
{
	background: #333;
	width: 100%;
}

h3
{
	font-size: 26px;
}

h3#reply-title
{
	font-size: 18px;
	display: none;
}

h4
{
	font-size: 22px;
}

.gmap_marker h4
{
	font-size: 18px;
	color: #000;
}

h5
{
	font-size: 18px;
}

h5.related_post, .fullwidth_comment_wrapper h5.comment_header
{
	text-align: center;
}

h6
{
	font-size: 16px;
}

h7
{
	font-size: 16px;
}

h1, h2, h3, h4, h5, h6, h7
{
	color: #222;
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	letter-spacing: 0px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.space
{
	margin-bottom: 5px;
}

.middle
{
	vertical-align: -30%;
}

pre, code, tt
{
	font:12px 'andale mono', 'lucida console', monospace;
	line-height:1.5;
	padding: 15px 10px 15px 10px;
	display: block;
	overflow: auto;
	margin-top: 20px;
	margin: 20px 0 20px 0;
	width:92%;
	border: 0;
	color: #000;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.clear
{
	clear: both;
}

img.mid_align
{
	vertical-align: middle;
	margin-right: 5px;
	border: 0;
}

.fullwidth_comment_wrapper
{
	width: 100%;
	float: left;
	margin-top: 40px;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.fullwidth_comment_wrapper:empty
{
	display: none;
}

#commentform
{
	margin-left: -1.33333rem;
}

#commentform label
{
	display: none;
}

.logged-in-as
{
	display: none;
}

#commentform > p
{
	padding-left: 24px;
	box-sizing: border-box;
}

#commentform p.comment-form-author, #commentform p.comment-form-email, #commentform p.comment-form-url
{
	float: left;
	width: 33.33%;
}

#page_content_wrapper .inner .sidebar_content #commentform p.comment-form-author, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-email, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-url
{
	width: 33.33%;
}

::-webkit-input-placeholder {
   color: #999;
   font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */
   color: #999;
   font-style: italic;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #999;
   font-style: italic;
}

:-ms-input-placeholder {
   color: #999;
   font-style: italic;
}

#contact_form input[type="text"], #contact_form textarea, #commentform input[type="text"], #commentform input[type="email"], #commentform input[type="url"], #commentform textarea {
  margin-bottom: 15px;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#commentform input[type=text]:focus, #commentform input[type=email]:focus, #commentform input[type=url]:focus, #commentform textarea:focus
{
	border-color: #444;
}

.dropcap1
{
	display: block;
	float: left;
	margin: -1px 8px 0 0;
	font-size: 70px;
	line-height: 70px;
	text-shadow: none;
}

blockquote
{
	font-size: 24px;
	color: #444;
	margin: auto;
	margin-top: 15px;
	margin-bottom: 15px;
	opacity: 0.4;
	text-transform: uppercase;
	letter-spacing: 2px;
}

blockquote p
{
	padding: 0 !important;
}

blockquote h2
{
	font-weight: normal;
	font-size: 22px;
}

blockquote h3
{
	font-weight: normal;
	font-size: 20px;
}

#respond
{
	width: 100%;
	float: left;
}

#respond h3, #respond .comment-notes
{
	text-align: center;
}

.round-photo
{
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/*------------------------------------------------------------------
[2. Navigation]
*/

#logo_wrapper
{
  text-align: center;
  padding: 30px 0 30px 0;
}

.top_bar.scroll #logo_wrapper
{
  display: none;
}

html[data-style=fullscreen] .top_bar.hasbg, .top_bar.hasbg
{
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.top_bar.hasbg #nav_wrapper
{
  border-color: rgba(255, 255, 255, .3);
}

.logo_container
{
  display: table;
  width: 100%;
  height: 100%;
}

.logo_align
{
  display: table-cell;
  vertical-align: middle;
  line-height: 0;
}

.logo_wrapper
{
  margin: 0;
  display: inline-block;
  line-height: 0;

  -webkit-transition: margin 0.3s, display 0.3s;
  -moz-transition: margin 0.3s, display 0.3s;
  transition: margin 0.3s, display 0.3s;
}

.logo_wrapper.hidden
{
  display: none;
}

.logo_wrapper img.zoom
{
  transform: scale(0.8) !important;
}

.above_top_bar
{
  height: 30px;
  background: #222;
  position: relative;
  z-index: 3;
}

.header_style_wrapper
{
  width: 100%;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
}

body.admin-bar .header_style_wrapper
{
  padding-top: 32px;
}

.header_style_wrapper.nofixed
{
  display: none;
}

.top_bar
{
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  background: rgba(255,255,255,0.95);
  border-bottom: 1px solid #e1e1e1;
  float: left;

  -webkit-transition: height 0.5s, background 0.5s;
  -moz-transition: height 0.5s, background 0.5s;
  transition: height 0.5s, background 0.5s;
}

.top_bar.hasbg
{
  background: rgba(0,0,0,0.1);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

html[data-style=fullscreen] .top_bar.hasbg, .top_bar.hasbg
{
  background: rgba(0,0,0,0.1);
}

.top_bar.scroll.dark
{
  background: #333;
  border-color: #333;
}

.top_bar.scroll.light
{
  background: #fff;
  border-color: #fff;
}

html[data-menu=centeralign] body .top_bar.scroll #nav_wrapper
{
  border: 0;
}

.top_bar.noopacity
{
  background: #fff !important;
}

.top_bar.scroll.dark .header_cart_wrapper a, .top_bar.scroll.dark .post_share_wrapper a#page_share, .top_bar.scroll.dark .post_download_wrapper a#gallery_download, .top_bar.scroll.dark .social_wrapper ul li a, .top_bar.scroll.dark #menu_wrapper div .nav > li > a
{
  color: #fff;
}

.top_bar.scroll.light .header_cart_wrapper a, .top_bar.scroll.light .post_share_wrapper a#page_share, .top_bar.scroll.light .post_download_wrapper a#gallery_download, .top_bar.scroll.light .social_wrapper ul li a, .top_bar.scroll.light #menu_wrapper div .nav > li > a
{
  color: #222;
}

#mobile_menu
{
  display: none;
  cursor: pointer;
}

#menu_wrapper
{
  margin:auto;
  width: 960px;
  height: 100%;
}

#nav_wrapper
{
  float: left;
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  border-top: 1px dashed #ccc;
}

.nav_wrapper_inner
{
  display: table-cell;
    vertical-align: middle;
}

#menu_border_wrapper > div
{
  width: 100%;
  float: left;
}

#menu_border_wrapper .notice
{
  padding: 10px 0 10px 0;
}

#mobile_nav_icon
{
  display: inline-block;
  border-bottom: 9px double #444;
  border-top: 3px solid #444;
  height: 3px;
  width: 25px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;

  cursor: pointer;
  position: relative;
  margin-left: 10px;
  top: 2px;
}

.top_bar.scroll.dark #mobile_nav_icon
{
  border-color: #fff;
}

.top_bar.scroll.light #mobile_nav_icon
{
  border-color: #222;
}

#logo_right_button
{
  position: absolute;
  text-align: right;
  right: 30px;
}

.top_bar.hasbg #mobile_nav_icon
{
  border-color: #fff;
}

.header_cart_wrapper
{
  display: inline-block;
  margin-left: 10px;
  position: relative;
}

.header_cart_wrapper .cart_count
{
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 9px;
  border-radius: 50px;
  background: #888;
  color: #fff;
  z-index: 2;
  width: 18px;
    height: 18px;
    line-height: 18px;
  font-weight: 600;
  text-align: center;
}

.header_cart_wrapper i
{
  font-size: 18px !important;
  margin-right: 0 !important;
}

.view_fullscreen_wrapper, .post_share_wrapper, .post_download_wrapper
{
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.view_fullscreen_wrapper, .post_download_wrapper
{
  margin-left: 10px;
}

.view_fullscreen_wrapper i, .post_share_wrapper i, .post_download_wrapper i
{
  font-size: 18px;
}

.mobile_menu_wrapper
{
  right: 0px;

  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;

  -webkit-transform: translate(360px, 0px);
  -ms-transform: translate(360px, 0px);
  transform: translate(360px, 0px);
  -o-transform: translate(360px, 0px);

  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
   -webkit-overflow-scrolling: touch;

   width: 360px;
   padding: 35px 40px 35px 40px;
   box-sizing: border-box;
   background: #111111;
   position: fixed;
   top: 0px;
   height: 100%;
   z-index: 999;
   color: #999;
}

body.admin-bar .mobile_menu_wrapper
{
  padding-top: 65px;
}

body.admin-bar #close_mobile_menu
{
  top: 68px;
}

body.home.blog .page_content_wrapper
{
  margin-top: 40px;
}

body.js_nav .mobile_menu_wrapper, html[data-menu=leftmenu] body.js_nav .mobile_menu_wrapper
{
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#close_mobile_menu
{
  position: fixed;
  top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#close_mobile_menu.open
{
  z-index: 99;
}

.mobile_main_nav, #sub_menu
{
  margin-top: 40px;
  margin-bottom: 40px;
  list-style: none;
  overflow: hidden;
  width: 100%;
}

#sub_menu .sub-menu
{
  margin-left: 15px;
  list-style: none;
  font-style: italic;
}

#sub_menu li
{
  width: 100%;
}

.mobile_main_nav li a, #sub_menu li a
{
  color: #777;
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 3.5em;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Montserrat', 'Helvetica Neue', Arial,Verdana,sans-serif;
}

.mobile_main_nav li.current-menu-item a, #sub_menu li.current-menu-item a
{
  font-weight: 600;
}

.mobile_main_nav li a:hover, .mobile_main_nav li a:active, #sub_menu li a:active
{
  color: #fff;
}

.mobile_main_nav li.menu-item-has-children > a:after
{
  font-size: 12px;
  position: absolute;
  left: 310px;
  font-family: 'FontAwesome';
  content: "\f105";
  margin-top: 1px;
}

.mobile_main_nav li ul.sub-menu
{
  display: none;
}

.mobile_main_nav.mainnav_in
{
  -webkit-animation: mainNavIn 0.2s;
  animation: mainNavIn 0.2s;
}

.mobile_main_nav.mainnav_out
{
  -webkit-animation: mainNavOut 0.2s;
  animation: mainNavOut 0.2s;
}

#sub_menu.subnav_out
{
  -webkit-animation: subNavOut 0.2s;
  animation: subNavOut 0.2s;
}

#sub_menu.subnav_in
{
  -webkit-animation: subNavIn 0.2s;
  animation: subNavIn 0.2s;
}

#sub_menu li ul a:after
{
  display: none;
}

#menu_back
{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px !important;
  margin-bottom: 5px;
  font-weight: 600 !important;
}

#menu_back:before
{
  font-size: 12px;
  margin-right: 0.7em;
  position: relative;
  display: inline;
  font-family: 'FontAwesome';
  content: "\f104";
}

#overlay_background
{
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 9;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

#overlay_background.visible
{
  opacity: 1;
  visibility: visible;
}

body.js_nav #overlay_background {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-360px,0px);
  -moz-transform: translate(-360px,0px);
  transform: translate(-360px,0px);
  cursor: pointer;
}

.header_style_wrapper.fixed .top_bar #menu_border_wrapper .notice
{
  margin-top: 24px;
}

.nav, .subnav, .nav_page_number
{
  list-style: none;
  padding: 10px 0 10px 0;
}

.subnav
{
  background: none;
}

.nav > li, .nav_page_number > li
{
  display: inline-block;
}

.nav_page_number
{
  margin-top: 2px;
}

#menu_wrapper .nav ul, #menu_wrapper div .nav
{
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

#menu_wrapper .nav ul li, #menu_wrapper div .nav li
{
  display: inline-block;
  margin: 0;
  padding:0;
  background-color: rgba(255, 255, 255, 0);
}

#menu_wrapper .nav ul li ul li, #menu_wrapper div .nav li ul li
{
  clear: both;
  width: 100%;
  padding: 0 !important;
  margin: 0;
  text-align: left !important;
}

#menu_wrapper .nav ul li:last-child, #menu_wrapper div .nav li:last-child
{
  margin-right: 0;
}

#menu_wrapper .nav ul li a, #menu_wrapper div .nav li > a
{
  display: inline-block;
  padding: 0;
  margin: 0;
  color: #666;
  font-size: 12px;
  padding: 22px 13px 21px 13px;
  font-weight: 400;
  font-family: 'Montserrat', 'Helvetica Neue', Arial,Verdana,sans-serif;
  text-transform: uppercase;

  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
}

.top_bar.fullscreen_video #menu_wrapper div .nav > li > a, .top_bar.fullscreen_video i, .top_bar.fullscreen_video #searchform label, .top_bar.hasbg #menu_wrapper div .nav > li > a, .top_bar.hasbg i, .top_bar.hasbg .top_contact_info, .top_bar.hasbg .top_contact_info a, .top_bar.hasbg .top_contact_info a:hover, .top_bar.hasbg .top_contact_info a:active, .top_bar.dark.scroll #menu_wrapper div .nav > li > a
{
  color: #fff !important;
  border: 0;
}

#menu_wrapper .nav li.arrow > a:after, #menu_wrapper div .nav li.arrow > a:after
{
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: 10%;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
  font-family: 'FontAwesome';
  content: "\f107";
  float: right;
  margin-left: 8px;
  margin-top: 3px;
}

#menu_wrapper .nav ul li.arrow > a:after, #menu_wrapper div .nav li.arrow > a:after
{
  font-size: 11px;
}

#menu_wrapper .nav ul li a.hover, #menu_wrapper .nav ul li a:hover, #menu_wrapper div .nav li a.hover, #menu_wrapper div .nav li a:hover
{
  color: #222;
  z-index: 2;
  position: relative;
}

#menu_wrapper div .nav > li.current-menu-item > a, #menu_wrapper div .nav > li.current-menu-parent > a, #menu_wrapper div .nav > li.current-menu-ancestor > a
{
  color: #222;
  z-index: 2;
  position: relative;
}

#menu_wrapper .nav ul li ul, #menu_wrapper div .nav li ul
{
  display: none;
  list-style: none;
  background: #fff;
  border: 0;
  position: absolute;
  padding: 0;
  width: 210px;
  height: auto;
  z-index: 10;
  margin: 0;
  margin-left: -5px;
  margin-top: 0;
  z-index: 9;
  border: 0;
  border: 1px solid #e1e1e1;
}


#menu_wrapper .nav ul li ul li ul, #menu_wrapper div .nav li ul li ul
{
  position: absolute;
  left: 210px;
  padding: 0;
  margin-top: -40px;
  margin-left: 0;
  display: none;
  z-index: 9;
  opacity: 1;
}

#menu_wrapper .nav ul li ul li ul:before, #menu_wrapper div .nav li ul li ul:before
{
  display: none;
}

#menu_wrapper .nav ul li:hover ul, #menu_wrapper div .nav li:hover ul
{
  display: block;
}

#menu_wrapper div .nav li ul li a, #menu_wrapper div .nav li.current-menu-item ul li a, #menu_wrapper div .nav li ul li.current-menu-item a,#menu_wrapper .nav ul li ul li a, #menu_wrapper .nav ul li.current-menu-item ul li a, #menu_wrapper .nav ul li ul li.current-menu-item a, #menu_wrapper div .nav li.current-menu-parent ul li a, #menu_wrapper div .nav li ul li.current-menu-parent a
{
  color: #888;
  display: block;
  background: transparent;
  height: auto;
}

#menu_wrapper .nav ul li ul li a, #menu_wrapper div .nav li ul li a, #menu_wrapper div .nav li.current-menu-parent ul li a
{
  width: 170px;
  display: block;
  border-top: 0;
  border: 0;
  margin: 0;
  font-size: 11px;
  padding: 10px 20px 10px 20px;
  letter-spacing: 0;
  font-weight: 400;
  text-transform: none;
  border-bottom: 0 !important;

  -webkit-transition: color .2s linear, background .2s linear;
  -moz-transition: color .2s linear, background .2s linear;
  -ms-transition: color .2s linear, background .2s linear;
  -o-transition: color .2s linear, background .2s linear;
  transition: color .2s linear, background .2s linear;
}

#menu_wrapper .nav ul li ul li ul li a:hover, #menu_wrapper .nav ul li ul li ul li a:hover, #menu_wrapper div .nav li ul li ul li a:hover, #menu_wrapper div .nav li ul li ul li a:hover, #menu_wrapper div .nav li.current-menu-item ul li ul li a:hover, #menu_wrapper div .nav li.current-menu-parent ul li ul li a:hover
{
  color: #444;
}

#menu_wrapper .nav ul li.megamenu > ul, #menu_wrapper div .nav li.megamenu > ul
{
  position: absolute;
  width: 960px;
  left: 0;
  right: 0;
  margin-left:auto;
    margin-right:auto;
    padding: 15px;
  box-sizing: border-box;
}

#menu_wrapper .nav ul li:not(.megamenu) ul.sub-menu li.arrow > a:after, #menu_wrapper div .nav li:not(.megamenu) ul.sub-menu li.arrow > a:after
{
  font-size:11px;
  margin-left: 0.5em;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: 10%;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
  font-family: 'FontAwesome';
  content: "\f105";
  float: right;
  margin-right: 0px;
  margin-top: 5px;
}

#menu_wrapper div .nav li.megamenu ul li
{
  display: block;
  box-sizing: border-box;
  clear: none;
  float: left;
  border-left: 1px solid #eeeeee;
}

#menu_wrapper div .nav li.megamenu ul li.hidden > a
{
  display: none;
}

#menu_wrapper div .nav li.megamenu ul li:first-child
{
  border: 0;
}

#menu_wrapper div .nav li.megamenu.col2 ul li
{
  width: 50%;
  padding: 0px 12px;
}

#menu_wrapper div .nav li.megamenu.col3 ul li
{
  width: 33.3%;
  padding: 0px 12px;
}

#menu_wrapper div .nav li.megamenu.col4 ul li
{
  width: 25%;
  padding: 0px 12px;
}

#menu_wrapper .nav ul li.megamenu ul li ul, #menu_wrapper div .nav li.megamenu ul li ul
{
  position: relative;
  width: 100%;
  margin: 0;
  border: 0;
  box-shadow: 0 0 0;
  display: block !important;
  opacity: 1 !important;
  left: 0;
}

#menu_wrapper .nav ul li.megamenu ul li ul li, #menu_wrapper div .nav li.megamenu ul li ul li
{
  width: 100% !important;
  border: 0 !important;
}

#menu_wrapper div .nav li.megamenu ul li > a, #menu_wrapper div .nav li.megamenu ul li > a:hover, #menu_wrapper div .nav li.megamenu ul li  > a:active
{
  width: 100%;
  color: #444;
  box-sizing: border-box;
  background: transparent;
}

#menu_wrapper .nav ul li.megamenu ul li ul li a, #menu_wrapper div .nav li.megamenu ul li ul li a
{
  color: #888;
}

#menu_wrapper .nav ul li.megamenu ul li ul li a:before, #menu_wrapper div .nav li.megamenu ul li ul li a:before
{
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: 10%;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
  font-family: 'FontAwesome';
  content: "\f105";
  float: left;
  margin-right: 8px;
  margin-top: 2px;
}

#menu_wrapper .nav ul li.megamenu ul li ul li a:hover, #menu_wrapper div .nav li.megamenu ul li ul li a:hover, #menu_wrapper .nav ul li.megamenu ul li ul li a:active, #menu_wrapper div .nav li.megamenu ul li ul li a:active
{
  width: auto;
}

#menu_wrapper div .nav li.megamenu ul li a:after
{
  display: none;
}

#menu_wrapper .nav ul li.megamenu ul li ul li, #menu_wrapper div .nav li.megamenu ul li ul li a
{
  width: auto;
  display: inline-block;
  margin-left: 20px;
  padding: 7px 20px 7px 5px;
}

@media only screen and (min-width: 1100px) {
  #menu_wrapper .nav ul li.megamenu > ul, #menu_wrapper div .nav li.megamenu > ul
  {
    max-width: 1425px;
    width: 100%;
    width: calc(100% - 180px);
    box-sizing: border-box;
  }
}

.top_contact_info_container
{
  display: table;
  float: right;
  height: 100%;
  font-family: 'Montserrat', 'Helvetica Neue', Arial,Verdana,sans-serif;
}

.top_contact_info
{
  font-size: 12px;
  color: #222;
  float: right;
  line-height: 30px;
}

.top_contact_info i
{
  margin-right: 10px;
  color: #222;
  font-size: 14px;
  vertical-align: text-bottom;
  line-height: 20px;
}

.top_contact_info span
{
  display: inline-block;
  margin-right: 10px;
}

.top_contact_info a
{
  color: #444;
}

#top_menu
{
  float: left;
  display: block;
  list-style: none;
}

#top_menu li
{
  float: left;
  margin-right: 20px;
}

#top_menu li a
{
  font-size: 11px;
  font-weight: 400;
  color: #222;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#footer_menu li ul
{
  display: none;
}











/*------------------------------------------------------------------
[3. Footer]
*/

.footer_photostream_wrapper
{
	margin-top: 20px;
}

.footer_photostream
{
	margin-top: 20px;
}

.footer_photostream li
{
	width: 10%;
	margin: 0;
	display: inline;
	float: left;
	line-height: 0;
}

.footer_photostream li a
{
	line-height: 0;
}

.footer_photostream li img
{
	width: 100%;
	height: auto;
}

.footer_photostream_wrapper h2.widgettitle:before
{
	display: none;
}

.footer_bar
{
	width: 100%;
	margin: auto;
	padding: 0 0 10px 0;
	float: left;
	background: #fff;
	border-top: 1px solid #e1e1e1;

	-webkit-transition: -webkit-transform 500ms ease;
	-o-transition: -o-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
}

.footer_bar.noborder
{
	border: 0 !important;
}

.footer_bar.noborder .footer_bar_wrapper
{
	border: 0 !important;
}

.footer_bar.wall
{
	padding-top: 0;
	margin-top: 0;
}

.footer_bar.split, .footer_bar.fullscreen, .footer_bar.flow
{
	display: none;
}

.footer_bar.fullscreen_video
{
	position: fixed;
	bottom: 0;
	z-index: 0;
}

#footer
{
	width: 100%;
	margin: auto;
	padding-bottom: 50px;
	word-wrap: break-word;
	margin-bottom: 0;
	padding-top: 50px;
	float: left;
}

#footer strong.title
{
	font-weight: normal;
}

#footer.wall
{
	margin-top: 0;
}

#footer.fullscreen_video
{
	display: none;
}

#footer a
{
	color: #000;
}

#footer a:hover, #footer a:active
{
	color: #000;
}

#footer .sidebar_widget li h2.widgettitle
{
	margin-top: 15px;
	margin-bottom: 15px;
}

#footer ul.sidebar_widget
{
	width: 960px;
	list-style: none;
	margin: 0;
	margin: auto;
}

#footer ul.sidebar_widget li ul
{
	list-style: none;
	margin-left: 0;
}

#footer ul.sidebar_widget li ul li ul.children
{
	margin-left: 10px;
	margin-bottom: 0;
}

#footer ul.sidebar_widget li ul li ul.children li:last-child
{
	border: 0;
	margin-bottom: 0;
}

#footer .sidebar_widget.four > li
{
	width: 20%;
	float: left;
	margin-right: 5%;
}

#footer .sidebar_widget.four > li:nth-child(4)
{
	margin-right: 0;
	width: 25%;
}

#footer .sidebar_widget.three > li
{
	width: 31%;
	float: left;
	margin-right: 2%;
}

#footer .sidebar_widget.three > li:nth-child(3)
{
	margin-right: 0;
	width: 33%;
}

#footer .sidebar_widget.two > li
{
	width: 48%;
	float: left;
	margin-right: 2%;
}

#footer .sidebar_widget.two > li:nth-child(2)
{
	margin-right: 0;
	width: 50%;
}

#footer .sidebar_widget.one > li
{
	width: 100%;
	clear: both;
	float: left;
	margin-bottom: 30px;
}

#footer ul.sidebar_widget li ul.posts.blog li img
{
	width: 60px;
	float: left;
	margin: 0 15px 15px 0;
}

#footer ul.sidebar_widget li ul.posts.blog li
{
	clear: both !important;
	float: left;
	border: 0;
}

#footer ul.sidebar_widget li ul li
{
	margin: 0 0 10px 0;
}

#footer ul.sidebar_widget li ul li ul.sub-menu
{
	margin-left: 15px;
}

#footer ul.sidebar_widget li ul.flickr li
{
	margin: 0 2% 0 0;
	border-bottom: 0;
	width: 31%
}

#footer ul.sidebar_widget li ul.flickr li img
{
	width: 96px;
	height: 96px;
	object-fit: cover;
}

#copyright
{
	float: left;
	width: 50%;
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 10px;
	letter-spacing: 0;
	text-align: left;
}

#page_content_wrapper.split #copyright, .page_content_wrapper.split #copyright
{
	width: 100%;
	margin: 40px 0 0 0;
	border-top: 1px solid #e1e1e1;
	padding-top: 20px;
	text-align: center;
}

#page_content_wrapper.split.wide #copyright, .page_content_wrapper.split.wide #copyright
{
	border: 0;
	margin: 0;
	padding-bottom: 20px;
}

#footer ul.address,  ul.address
{
	margin-left: 0 !important;
	list-style: none;
}

#footer ul.address li, ul.address li
{
	margin-bottom: 5px;
}

#footer ul.address li i, ul.address li i
{
	margin-right: 10px;
}

.footer_bar.fullscreen #copyright
{
	color: #fff;
}

.footer_bar.fullscreen .footer_bar_wrapper, .footer_bar.wall .footer_bar_wrapper, .footer_bar.static .footer_bar_wrapper
{
	margin: auto;
	float: none;
}

#copyright p.attachment
{
	display: none;
}

.footer_bar_wrapper
{
	width: 960px;
	margin: auto;
	font-size: 13px;
	font-weight: 600;
	-webkit-transition: -webkit-transform 500ms ease;
	transition: -webkit-transform 500ms ease;
	-webkit-font-smoothing: antialiased;
	clear: both;
	color: #fff;
	border-top: 1px solid #444;
	margin-top: 40px;
	padding-top: 20px;
}

#toTop
{
	width:25px;
    background: #eee;
    opacity: 0;
    text-align:center;
    padding:10px 10px 10px 10px;
    position:fixed; /* this is the magic */
    bottom: 7px; /* together with this to put the div at the bottom*/
    right:7px;
    cursor:pointer;
	transition: color 300ms, background-color 300ms, opacity 300ms;
	-moz-transition: color 300ms, background-color 300ms, opacity 300ms;
	-o-transition: color 300ms, background-color 300ms, opacity 300ms;
	-webkit-transition: color 300ms, background-color 300ms, opacity 300ms;
	z-index: 9;
	border-radius: 5px;
	display: block;
}

#toTop:hover
{
	opacity: 1 !important;
}

#toTop i
{
	color: #000;
	font-size: 1.5em;
}

/*------------------------------------------------------------------
[4. Content]
*/

#content_wrapper
{
	width: 72%;
	float: left;
	margin: 0;
}

#page_content_wrapper, .page_content_wrapper
{
	width: 960px;
	margin: auto;
	padding-bottom: 0;
}

body.home.blog #page_content_wrapper, body.single-product #page_content_wrapper
{
	margin-top: 40px;
}

#page_content_wrapper.wide, .page_content_wrapper.wide
{
	width: 100%;
}

#page_content_wrapper.split, .page_content_wrapper.split, #page_content_wrapper.split.wide, .page_content_wrapper.split.wide
{
	width: 50%;
	padding: 30px;
	height: 100%;
	height: 100vh;
	position: fixed;
	right: 0;
	top: 129px;
	background: #fff;
	overflow-y: scroll;
	box-sizing: border-box;
}

#page_content_wrapper.split.wide, .page_content_wrapper.split.wide
{
	padding: 0;
}

#page_content_wrapper.split .animate, .page_content_wrapper.split .animate
{
	opacity: 1 !important;
	visibility: visible !important;
}

.page_content_wrapper
{
	width: 100%;
}

#page_content_wrapper img, .page_content_wrapper img
{
	max-width: 100%;
	height: auto;
}

#page_content_wrapper .map_shortcode_wrapper img, .page_content_wrapper .map_shortcode_wrapper img
{
	max-width: none;
}

.standard_wrapper
{
	width: 960px;
	margin: auto;
}

.one_half .standard_wrapper, .one_third .standard_wrapper, .one_fourth .standard_wrapper, .one_fifth .standard_wrapper, .two_third .standard_wrapper, .one_half .page_content_wrapper, .one_third .page_content_wrapper, .one_fourth .page_content_wrapper, .one_fifth .page_content_wrapper, .two_third .page_content_wrapper
{
    max-width: 100%;
    width: 100%;
    padding: 0;
}

.page_content_wrapper:empty
{
	margin-top: 0 !important;
}

.one.withbg
{
	background-size: cover;
	background-position: center center;
}

.one.ppb_fullwidth_button
{
	overflow-x: hidden;
}

.page_content_wrapper.fullwidth, #page_content_wrapper.fullwidth
{
	width: 100%;
	padding: 0;
}

.one
{
	clear: both;
	position: relative;
	float: left;
	width: 100%;
	box-sizing: border-box;
}

.one.skinbg
{
	background: #000;
}

.one .page_content_wrapper, .one_half .page_content_wrapper, .one_third .page_content_wrapper, .one_fourth .page_content_wrapper
{
	margin-top: 0;
	width: 100%;
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
}

.one.withpadding
{
	padding: 60px 0 60px 0;
	float: left;
	width: 100%;
}

.one.withpadding.nopadding
{
	padding: 0 !important;
}

.one.withsmallpadding
{
	padding: 30px 0 30px 0;
	float: left;
	width: 100%;
}

.one.withsmallpadding.ppb_text
{
	float: none;
	margin: auto;
}

.animate_counter_wrapper i
{
	font-size: 48px;
	height: 48px;
	line-height: 48px;
	margin-bottom: 10px;
	color: #000000;
}

.count_separator
{
	display: none;
}

.count_separator span
{
	display: inline-block;
	text-align: center;
	width: 40px;
	margin: 5px auto 5px;
	height: 1px;
	background: #e1e1e1;
}

.animate_counter_wrapper .counter_subject
{
	font-size: 14px;
	text-transform: uppercase;
	clear: both;
	margin-top: 5px;
	font-weight: 400;
	letter-spacing: 2px;
	opacity: 0.7;
}

#content_wrapper .inner
{
	overflow: hidden;
}

#page_content_wrapper .inner
{
	width: 100%;
	margin: auto;
}

#page_content_wrapper .inner .inner_wrapper
{
	padding-top: 0;
}

.post.type-post
{
	width: 100%;
	margin-bottom: 35px;
	float: left;
	position: relative;
}

body.search-results .hentry
{
	float: left;
	width: 100%;
	margin-bottom: 20px;
}

#page_content_wrapper .inner .sidebar_content .post.type-post.last_post
{
	margin-bottom: 20px;
}

#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content
{
	margin-right: 0;
	padding-right: 0;
	width: 690px;
	box-sizing: border-box;
	margin-bottom: 40px;
}

#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content.left_sidebar
{
	padding-left: 20px;
	padding-right: 0;
}

#blog_grid_wrapper.sidebar_content:not(.full_width) .post.type-post
{
	width: 46%;
}

#page_content_wrapper .inner .sidebar_content.blog_f .post.type-post
{
	border-bottom: 0;
	margin-bottom: 30px;
}

body.single .post.type-post
{
	border: 0;
	margin-bottom: 0;
}

.post.type-post.last-child
{
	border: 0;
	margin: 0;
	padding: 0;
}

#blog_grid_wrapper
{
	padding-top: 30px;
}

#blog_grid_wrapper.ppb_blog_posts, .blog_grid_wrapper.ppb_blog_posts
{
	padding-top: 0;
}

#blog_grid_wrapper .post.type-post, .blog_grid_wrapper .post.type-post
{
	margin-bottom: 20px;
	box-sizing: border-box;
	padding: 0;
	opacity: 0;
	padding: 5px;
	box-sizing: border-box;

	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
}

body.page-template-blog_g-php .post.type-post, body.error404 .post.type-post, body.page-template-galleries-php .galleries.type-galleries, body.tax-gallerycat .galleries.type-galleries, .ppb_blog_posts .post.type-post, body.archive #blog_grid_wrapper .post.type-post
{
	float: left;
	width : 31%;
	margin-right: 20px;
	margin-bottom: 20px;
}

.post.type-post:last-child
{
	margin-bottom: 0;
}

.post_wrapper
{
	padding: 20px 0 25px 0;
}

.post_wrapper.grid_layout
{
	padding: 0;
}

.blog_grid_content
{
	width: 100%;
	clear: both;
	box-sizing: border-box;
	float: left;
}

body.single .post_wrapper
{
	padding-bottom: 0;
}

.post_wrapper:first-child
{
	padding-top: 0;
	border: 0;
}

body.search .post_wrapper:first-child
{
	padding: 20px 0 25px 0;
}

.post_wrapper.single
{
	width: 100%;
	margin: 0;
}

.post_header
{
	width: 100%;
	float: left;
	margin-bottom: 20px;
	text-align: center;
}

body.single .post_header
{
	text-align: left;
}

.post_header p
{
	text-align: left;
}

.post_header.search
{
	width: calc(100% - 50px);
	margin-bottom: 0;
	margin-left: 10px;
	text-align: left;
}

body.search-results .post_header.search
{
	width: calc(100% - 70px);
}

.post_header.quote
{
	margin-bottom: 0;
}

.readmore
{
	font-size: 11px;
	display: inline-block;
	margin: auto;
	margin-top: 10px;
	letter-spacing: 2px;
	border-bottom: 1px solid #222;
}

.post_header.grid
{
	margin-bottom: 15px;
	width: 100%;
	float: none;
}

.post_header.full
{
	width: 100%;
}

body.page-template-blog_f-php .post_excerpt_full
{
	margin-bottom: 0;
}

.post_header.grid h6, .portfolio_desc h5
{
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.post_header h3 a, .post_header.grid h6 a
{
	color: #222222;
}

.post_header h5
{
	font-size: 28px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.post_header h6
{
	font-size: 18px;
}

.post_header h5 a, .post_header h6 a
{
	color: #222222;
}

.post_excerpt
{
	width: 70%;
	margin: auto;
}

.post_excerpt br:first:child
{
	display: none;
}

.post_related strong
{
	font-size: 17px;
}

.post_related .post_detail
{
	font-size: 11px;
}

.post_related .post_header
{
	text-align: left;
}

.animate
{
	opacity: 0;
}

.animate.visible
{
	animation-name: slideUp2;
	-webkit-animation-name: slideUp2;
	animation-duration: 0.6s;
	-webkit-animation-duration: 0.6s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.post_excerpt.post_tag
{
	width: 100%;
	margin: auto;
	text-align: center;
	margin-top: 10px;
}

.post_excerpt.post_tag a
{
	display: inline-block;
	padding: 3px 10px;
	background: #f0f0f0;
	margin-left: 5px;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

#about_the_author
{
	margin-top: 20px;
}

#about_the_author .gravatar
{
	float: left;
	margin-right: 20px;
	margin-bottom: 15px;
}

.author_label
{
	float: left;
}

.author_content strong
{
	font-size: 20px;
}

.post_content_wrapper
{
	padding: 0;
	margin: 0;
}

.post_content_wrapper.fullwidth
{
	width: 100%;
}

.post_header .post_detail, .recent_post_detail, .post_detail, .thumb_content span, .portfolio_desc .portfolio_excerpt, .testimonial_customer_position, .testimonial_customer_company, .page_tagline
{
	width: 100%;
	padding: 0;
	float: left;
	margin: 5px 0 15px 0;
	font-size: 13px;
}

.portfolio_title .post_detail
{
	margin: 0;
}

.search_form_wrapper
{
	margin-top: 20px;
	margin-bottom: 30px;
}

body.search .search_form_wrapper
{
	margin-top: 0;
}

.search_form_wrapper .content
{
	font-size: 16px;
	text-align: center;
	width: 60%;
	margin: auto;
	margin-bottom: 40px;
}

.post_header .post_detail.grid
{
	margin-top: 5px;
}

.post_header .post_detail.full
{
	width: 100%;
	margin-top: 7px;
	margin-bottom: 7px;
}

.post_header .post_detail.grid
{
	margin-bottom: 0;
}

.post_attribute, .comment_date
{
	padding: 0;
	margin-top: 0;
	color: #999;
	letter-spacing: 0;
	text-transform: uppercase;
	opacity: 0.8;
	font-size: 12px;
}

.post_info_comment
{
	padding-left: 10px;
	border-left: 1px solid #999;
}

.post_type_icon
{
	float: left;
	margin-right: 0;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	width: 30px;
	height: 30px;
	padding: 5px;
	display: block;
	text-align: center;
	background: #222;
}

body.search-results .post_type_icon
{
	margin-right: 15px;
}

.post_type_icon i
{
	font-size: 16px;
	line-height: 16px;
	display: block;
	position: relative;
	top: 50%;
	line-height: 30px;
}

.post_type_icon a
{
	color: #fff !important;
	display: block;
}

.search_thumb
{
	float: left;
	border-radius: 200px;
	width: 40px;
	height: 40px;
	overflow: hidden;
	position: relative;
	margin-top: 5px;
}

body.search-results .search_thumb
{
	margin-right: 15px;
}

.search_thumb img
{
	width: 40px;
	height: 40px;
}

.post_qoute_mark
{
	float: left;
	display: block;
	margin: 10px 39px 0 0;
	color: #ccc;
	font-size: 60px;
}

.post_qoute_mark.grid
{
	margin: 10px 20px 0 0;
	font-size: 30px;
	color: #ccc;
}

.post_quote_wrapper
{
	background: #222;
	width: 100%;
	padding: 40px;
	box-sizing: border-box;
	float: left;
	margin-bottom: 30px;
}

.post_quote_title
{
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	font-size: 22px;
	width: 100%;
	margin: auto;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.post_quote_wrapper .post_detail
{
	color: #fff !important;
	margin: 10px 0 0 0;
}

.post_quote_title p
{
	padding: 0 !important;
}

.post_quote_title a
{
	color: #000000;
}

.post_quote_title.grid
{
	font-size: 15px;
}

#page_caption.hasbg .post_detail, #page_caption.hasbg .post_detail a, #page_caption.hasbg .post_detail a:hover, #page_caption.hasbg .post_detail a:active, .page_tagline
{
	padding: 0;
	color: #fff;
	margin-top: 10px;
	font-size: 16px;
	opacity: 0.8;
}

#page_caption .post_detail
{
	float: none;
}

.post_detail_wrapper
{
	float: left;
	width: 96%;
	margin: 0 0 10px 0;
}

.gravatar
{
	position: relative;
	z-index: 2;
	overflow: hidden;
	float: left;
	width: 100px;
	height: 100px;
	border-radius: 200px;
}

.comment .gravatar
{
	width: 60px;
	height: 60px;
}

.gravatar img
{
	max-width: 100% !important;
}

#about_the_author .header span
{
	display: block;
	padding: 10px 0 10px 20px;
	font-size: 14px;
}

#about_the_author .thumb
{
	width: 80px;
	float: left;
	margin: 20px 0 0 20px;
}

#about_the_author .thumb img
{
	padding: 3px;
	width: 50px;
}

#about_the_author .description
{
	width: 550px;
	float: left;
	padding: 0 0 0 20px;
}

.comment .left img.avatar
{
	width: 50px;
	height: 50px;
}

.comment
{
	width: 770px;
	padding-bottom: 0;
	float: left;
}

.fullwidth_comment_wrapper .comment
{
	width: 100%;
}

.comment .left
{
	float: left;
	margin-right: 15px;
}

.comment_arrow
{
	width: 10px;
	height: 10px;
	float: left;
	position: relative;
	left: 5px;
	margin-top: 20px;
	display: none;
}

.comment .right
{
	width: 74%;
	float: left;
	margin-left: 25px;
	padding: 0 15px 30px 0;
}

.comment .right h7
{
	display: block;
	float: left;
	font-size: 13px;
	font-weight: 600;
	line-height: 28px;
	margin-right: 10px;
}

.fullwidth_comment_wrapper .comment .right
{
	width: 88%;
}

.fullwidth_comment_wrapper.sidebar .comment .right
{
	width: 74%;
}

ul.children div.comment .right
{
	width: 77%;
}

.fullwidth_comment_wrapper ul.children div.comment .right
{
	width: 84%;
}

.comment .right p
{
	margin: 0;
	padding: 0;
}

.comment .right strong
{
	font-weight: #fff;
}

#content_wrapper ul.children
{
	list-style: none;
	margin: -40px 0 0px 74px;
	border-left: 1px solid #ccc;
	padding: 5px 20px 2px 0;
	float: left;
	width: 106%;
}

#content_wrapper ul.children ul.children
{
	margin-top: -10px;
	padding-bottom: 0;
	width: 83.5%;
}

.comment_wrapper
{
	float: left;
	margin-left: 25px;
	border-left: 1px solid #eeeeee;
}

.comment_date
{
	margin: 0;
	line-height: 30px;
	float: left;
	margin-left: 0;
}

ul.children .comment
{
	width: 100%;
	margin: 0;
	padding: 20px 0 10px 0;
	margin-top: 20px;
	margin-left: 45px;
	float: left;
}

ul.children .comment:last-child
{
	padding-top: 0;
	padding-bottom: 15px;
}

ul.children .comment:first-child
{
	padding-top: 10px;
	margin-top: 0px;
}

.jp-play, .jp-pause
{
	position: fixed;
	top: 46.5%;
	left: 4px;
	width: 22px;
	height: 20px;
	background:transparent url(/images/sound_off.png) no-repeat left center;
	text-indent: -9999px;
	z-index: 99999;
}

.jp-pause
{
	background:transparent url(/images/sound_on.png) no-repeat left center;
}

.social_wrapper
{
	width: 100%;
	margin: 20px 0 5px 0;
	text-align: center;
}

#logo_wrapper .social_wrapper
{
	width: auto;
	margin: 0;
	left: 30px;
	text-align: left;
	position: absolute;
}

.footer_bar_wrapper .social_wrapper
{
	width: 50%;
	float: right;
	text-align: right;
	margin: 0;
}

.above_top_bar .social_wrapper
{
	margin: 0;
	float: right;
	width: auto;
}

.social_wrapper.shortcode, .social_wrapper.shortcode ul
{
	margin: 0;
	text-align: left;
}

.social_wrapper ul
{
	list-style: none;
	margin-left: 0 !important;
	text-align: left;
}

.above_top_bar .social_wrapper ul
{
	text-align: right;
}

.footer_bar_wrapper .social_wrapper ul
{
	text-align: right;
}

#page_content_wrapper .social_wrapper ul
{
	list-style: none;
	margin-top: 12px;
}

#page_content_wrapper .social_wrapper.shortcode ul
{
	margin-top: 0;
}

.social_wrapper ul li, .social_wrapper.small ul li
{
	display: inline-block;
	margin-right: 15px;
}

#logo_wrapper .social_wrapper ul li
{
	margin-right: 10px;
}

.social_wrapper.shortcode ul li, .social_wrapper.small.shortcode ul li
{
	margin: 0 10px 10px 10px !important;
}

.footer_bar_wrapper .social_wrapper ul li
{
	background: transparent !important;
	border: 0 !important;
	width: 16px;
	height: 16px;
	margin-left: 5px;
	float: right;
}

.social_wrapper.light ul li
{
	border: 0 !important;
}

.social_wrapper.large ul li
{
	width: 50px;
	height: 50px;
}

.above_top_bar .social_wrapper ul li
{
	background: transparent;
	border: 0;
	width: auto;
	height: auto;
	margin-right: 0px;
}

.social_wrapper.shortcode ul li
{
	margin: 0 2px 4px 2px;
	clear: none !important;
	float: none !important;
	width: auto !important;
	display: inline-block !important;
}

.sidebar_content ul li .social_wrapper.shortcode, .sidebar_content ul li .social_wrapper.shortcode ul
{
	text-align: center !important;
}

.social_wrapper ul li a
{
	opacity: 1;
	display: block;
	line-height: 0;
}

.social_wrapper.shortcode ul li a
{
	color: #fff;
}

.social_wrapper.shortcode.light ul li a
{
	color: #444;
}

.mobile_menu_wrapper .sidebar_wrapper h2.widgettitle
{
	text-align: left;
}

.mobile_menu_wrapper .sidebar_wrapper h2.widgettitle:before
{
	border: 0;
}

.mobile_menu_wrapper .sidebar_wrapper h2.widgettitle span
{
	background: transparent;
	padding-left: 0;
	padding-right: 0;
}

.mobile_menu_wrapper .social_wrapper ul li a
{
	opacity: 0.5;
}

.mobile_menu_wrapper .social_wrapper ul li a:hover, .mobile_menu_wrapper .social_wrapper ul li a:active
{
	opacity: 1;
}

.above_top_bar .social_wrapper ul li:last-child a
{
	margin-right: 0;
}

.above_top_bar .social_wrapper ul li a
{
	display: inline-block;
	color: #fff;
	padding: 0;
	margin-left: 5px;
	margin-right: 5px;
	line-height: 30px;
}

.above_top_bar .page_content_wrapper
{
	margin: auto;
}

.footer_bar_wrapper .social_wrapper ul li a
{
	color: #fff;
	opacity: .4;
}

.footer_bar_wrapper .social_wrapper ul li a:hover, .footer_bar_wrapper .social_wrapper ul li a:active
{
	opacity: 1;
}

.social_wrapper ul li a i, .social_wrapper.small ul li a i
{
	line-height: 18px;
	font-size: 18px;
}

.above_top_bar .social_wrapper ul li a i
{
	font-size: 14px;
	line-height: 18px;
}

.footer_bar_wrapper .social_wrapper ul li a i
{
	line-height: 20px;
	font-size: 18px;
}

.social_wrapper.large ul li a i
{
	line-height: 32px;
	font-size: 24px;
}

.above_top_bar .social_wrapper ul li:hover
{
	background: transparent !important;
	opacity: 1;
}

.team_card_wrapper
{
	margin: 50px 0 50px 0;
}

.team_content
{
	margin: 20px 0 20px 0;
}

.social_wrapper.team
{
	list-style: none;
	display: block;
	margin: 0;
	width: 100%;
	margin-top: 30px;
	text-align: center;
}

.social_wrapper.team li
{
	display: inline-block;
	margin: 0 10px 0 10px;
}

.social_wrapper.team li i
{
	font-size: 18px;
}

.one_fourth .social_wrapper.team li img
{
	max-width: 14px;
	height: auto;
}

.one_fourth .social_wrapper.team li
{
	display: inline-block;
	margin: 0 3px 0 3px;
}

.social_wrapper ul li a img
{
	opacity: .4;
}

.social_wrapper.shortcode ul li a img, .social_wrapper ul li:hover a img
{
	opacity: 1;
}

#page_caption
{
	padding: 25px 0 30px 0;
	width: 100%;
	background: #ffffff;
	margin-bottom: 10px;
}

#page_caption.hasbg
{
	height: 60%;
	max-height: 750px;
	position: relative;
	top: 0;
	right: 0;
	width: 100%;
	border: 0;
	padding: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	margin-bottom: 0;
	z-index: 2;
}

body.single-galleries #page_caption.hasbg
{
	margin-bottom: 20px;
}

#page_caption.split, #page_caption.hasbg.split
{	width: 50%;
	height: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 129px;
	min-height: 100%;
}

#page_caption.hasbg #bg_regular, #page_caption.hasbg #bg_blurred
{
	width: 100%;
	min-height: 100%;
	position: absolute;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

#page_caption.hasbg #bg_regular
{
	opacity: 1;
}

#page_caption.hasbg #bg_blurred
{
	opacity: 0;
  filter: blur(6px);
}

#page_caption.hasbg:after
{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(60%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.4)),to(rgba(0,0,0,0.59)));
  background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 60%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
  background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 60%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
  z-index: 2;
}

body.single-post #page_caption.hasbg:after, body.single-portfolios #page_caption.hasbg:after, #page_caption.split:after
{
	display: none;
}

.post_caption
{
	text-align: center;
	margin: 40px 0 40px 0;
}

#page_content_wrapper.split .post_caption, .page_content_wrapper.split .post_caption
{
	margin-top: 0;
	margin-bottom: 20px;
}

.post_caption h1
{
	font-size: 36px;
}

.post_caption .post_detail
{
	margin: auto;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #e1e1e1;
	letter-spacing: 5px;
	font-size: 12px;
	opacity: 0.8;
	display: inline-block;
	width: auto;
	float: none;
}

.parallax_overlay_header
{
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

#page_caption.nomargin
{
	margin-bottom: 0;
}

#page_caption .page_title_wrapper
{
	width: 100%;
	margin: auto;
	text-align: center;
}

#page_caption.hasbg .page_title_wrapper
{
	z-index: 3;
	position: absolute;
	bottom: 30px;
	width: 100%;
}

#page_caption.split .page_title_wrapper
{
	position: relative;
	top: 0;
	left: 0;
	width: 90%;
	height: 100%;
	display: table;
}

#page_caption.split .page_title_wrapper .page_title_inner
{
	vertical-align: middle;
	display: table-cell;
}

#page_caption.hasbg h1, h1.hasbg
{
	color: #fff;
	float: none;
	margin: auto;
}

#page_caption.hasbg .page_title_wrapper .page_title_inner
{
	vertical-align: middle;
	width: 100%;
}

#page_caption.hasbg h1.withtopbar, h1.hasbg.withtopbar, #page_caption.hasbg.notransparent h1.withtopbar
{
	margin-top: 160px;
}

.page_tagline
{
	width: auto;
	display: inline-block;
	margin: auto;
	margin-top: 20px;
	padding-top: 30px;
	border-top: 1px solid #e1e1e1;
	float: none;
}

#page_caption.hasbg .page_tagline
{
	color: #fff;
	border: 0;
	margin-top: 0;
	padding-top: 0;
}

h2.ppb_title
{
	font-size: 50px;
	margin-top: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.ppb_subtitle
{
	margin: 0 0 20px 0;
	font-size: 14px;
	color: #777;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.ppb_content
{
	margin: 15px 0 15px 0;
	width: 96%;
}

#crumbs
{
	float: right;
	font-size: 10px;
	line-height: 34px;
}

#crumbs.center, #page_caption.hasbg #crumbs
{
	display: block !important;
}

#page_caption.hasbg #crumbs
{
	color: #fff;
	float: none;
	margin: auto;
	display: none;
}

#page_caption.hasbg #crumbs a
{
	color: #fff;
}

#crumbs a, #crumbs a:hover, #crumbs a:active
{
	color: #999;
}

#page_caption h2
{
	color: #333;
	padding: 0 0 0 0;
	font-size: 22px;
	font-weight: normal;
}

.gallery_img_slides
{
	z-index: 2;
	visibility:hidden;
	margin: 0 !important;
	position: absolute;
	width: 100%;
	height: 100%;

	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}

.gallery_img_slides li, .gallery_img_slides li img
{
	width: 100% !important;
	max-width: 100% !important;
	height: 100% !important;
}

.gallery_img_slides.active
{
	visibility: visible;
}

#portfolio_wall_filters, .portfolio_wall_filters
{
	width: 100%;
	text-align: center;
	margin-left: 0 !important;
}

.filter li
{
	margin: 0 0 0 0;
	display: inline-block;
	list-style: none;
}

.filter li:first-child, .filter li:first-child a
{
	margin-left: 0 !important;
}

.filter li a
{
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	padding: 1em 1em 1em;
	background: transparent;
	font-style: italic;
	text-shadow: none;
	color: #999;
}

.filter li .separator
{
	display: none !important;
}

.filter li:last-child .separator
{
	display: none;
}

.filter li a:hover
{
	color: #222;
}

.filter li a.active, #portfolio_wall_filters li a.active,  #portfolio_wall_filters li a:hover
{
	color: #222;
}

#page_minimize
{
	display: none;
}

#post_featured_slider
{
	margin-top: -1px;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	transition: all 0.6s ease;
	background-color: #f9f9f9;
	margin-bottom: 60px;
}

#post_featured_slider li .slider_image:after
{
	content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(76%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.5)),to(rgba(0,0,0,0.59)));
    background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.5) 93%,rgba(0,0,0,0.59) 100%);
    background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.5) 93%,rgba(0,0,0,0.59) 100%);
    z-index: 2;
}

#post_featured_slider li .slider_image
{
	width: 100%;
	height: 500px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
}

#post_featured_slider li .slider_image.three_cols
{
	width: 33.3%;
	float: left;
	height: 350px;
}

#post_featured_slider li .slider_image .slide_post
{
	position: absolute;
	bottom: 15px;
	left: 0;
	width: 100%;
	text-align: center;
	color: #fff;
	z-index: 3;
}

#post_featured_slider li .slider_image.three_cols .slide_post
{
	text-align: left;
}

#post_featured_slider li .slider_image .slide_post h2
{
	color: #fff;
	font-size: 24px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

#post_featured_slider li .slider_image.three_cols .slide_post
{
	padding: 0 20px 0 20px;
	box-sizing: border-box;
}

#post_featured_slider li .slider_image.three_cols .slide_post h2
{
	font-size: 18px;
}

#post_featured_slider li .slider_image .slide_post .slide_post_date
{
    font-size: 11px;
    margin-bottom: 0;
    color: #fff;
}

#post_featured_slider li .slider_image.three_cols .slide_post ,slide_post_date
{
	font-size: 11px;
}

.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe
{
	max-width: 100%;
	height: auto;
}

#vimeo_bg, #youtube_bg
{
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}

#vimeo_bg .fluidvids, #youtube_bg .fluidvids
{
	width: 100%;
	height: 100%;
}

#vimeo_bg > iframe, #youtube_bg > iframe
{
	display: block;
	height: 100%;
	width: 100%;
}

.icon-scroll,
.icon-scroll:before
{
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 20px;
  height: 35px;
  margin-left: -10px;
  position: absolute;
  bottom: 20px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 3px #fff;
  border-radius: 25px;
  z-index: 99;
}

.icon-scroll:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #fff;
  margin-left: -2px;
  top: 4px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

.one.fullwidth.slideronly .icon-scroll
{
	display: none;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
}

/*------------------------------------------------------------------
[5. Social Sharing]
*/

.post.type-post .post_share
{
	color: #444;
	display: none;
}

.post.type-post:hover .post_share, .post.type-post .post_share.visible
{
	display: block;
}

.post_share_bubble
{
	position: absolute;
	bottom: 20px;
	right: 0;
	z-index: 9;
	width: 15px;
}

body.single.single-post .post_share_bubble, body.single.single-projects .post_share_bubble, body.single.single-attachment .post_share_bubble
{
	position: fixed;
	left: 7px;
	bottom: 7px;
}

body.single.single-post .post_share_bubble a.post_share, body.single.single-projects .post_share_bubble a.post_share, body.single.single-attachment .post_share_bubble a.post_share
{
	display: block;
	padding:10px 10px 10px 10px;
	width: 25px;
	background: #eee;
	border-radius: 5px;
	text-align: center;
	opacity: 0.5;

	-webkit-transition: opacity .2s linear;
	-moz-transition: opacity .2s linear;
	-ms-transition: opacity .2s linear;
	-o-transition: opacity .2s linear;
	transition: opacity .2s linear;
}

a.post_share
{
	color: #000;
}

body.single.single-post .post_share_bubble a.post_share:hover, body.single.single-projects .post_share_bubble a.post_share:hover, body.single.single-attachment .post_share_bubble a.post_share:hover
{
	opacity: 1;
}

.post_share_bubble_wrapper
{
	position: relative;
}

.post_img
{
	position: relative;
	height: auto;
	text-align: center;
	overflow: hidden;
	float: left;
	line-height: 0;
	width: 100%;
	margin-bottom: 20px;
}

.post_img.team
{
	width: 100%;
	margin: auto;
	float: none;
	margin-bottom: 0;
	overflow: hidden;
}

.post_img.team img
{
	max-width: 100%;
}

.post_ft_img
{
	opacity: 0;
}

.post_img.small.square_thumb
{
	width: auto;
}

.post_img.fadeIn, .post_img.grid.fadeIn
{
	opacity: 0;
	animation-duration: 0.7s;
	-webkit-animation-duration: 0.7s;
}

.post_img img
{
	width: 100%;
	height: auto !important;
}

body.single .post_img img
{
	width: auto;
}

#content_slider_wrapper
{
	width: 100%;
	height: 20px;
	background: transparent;
	position: fixed;
	bottom: 200px;
}

#social_share_wrapper, .social_share_wrapper
{
	margin-left: 0 !important;
	margin-top: 20px;
	padding-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	clear: both;
}

#social_share_wrapper a i, .social_share_wrapper.shortcode a i
{
	font-size: 20px;
}

#social_share_wrapper a, .social_share_wrapper.shortcode a
{
	opacity: 0.3;
	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

#social_share_wrapper a:hover, .social_share_wrapper.shortcode a:hover
{
	opacity: 1;
}

.social_share_wrapper.shortcode
{
	margin: 0;
}

#social_share_wrapper h5,  .social_share_wrapper.shortcode h5
{
	display: inline-block;
	margin-bottom: 5px;
}

#social_share_wrapper ul, .social_share_wrapper.shortcode ul, .social_share_bubble ul
{
	display: block;
	clear: both;
	list-style: none;
	margin: 0;
	margin-left: 0 !important;
}

#social_share_wrapper ul li, .social_share_wrapper.shortcode ul li, .social_share_bubble ul li
{
	display: block;
	margin: 0 0 5px 0;
}

.social_share_wrapper.shortcode ul li
{
	display: inline-block;
	margin: 0 10px 0 10px;
	border-left: 0 !important;
}

.social_share_bubble ul li
{
	display: inline-block;
	margin: 0 7px 0 7px;
	font-size: 14px;
}

#social_share_wrapper.inline, , .social_share_bubble.inline
{
	text-align: center;
	border:0;
	margin-top: 30px;
}

.imageFlow_gallery_info_wrapper #social_share_wrapper ul li, .imageFlow_gallery_info_wrapper .social_share_wrapper ul li, #social_share_wrapper.inline ul li, .social_share_wrapper.inline ul li
{
	display: inline-block;
	margin: 0 5px 0 5px;
}

#fullscreen_share_wrapper
{
	display: table;
	width: 100%;
	height: 100%;
	z-index: 9;
	color: #fff;
  	position: relative;
  	visibility: hidden;
}

.fullscreen_share_content
{
	display: table-cell;
	vertical-align: middle;
	position: relative;
	text-align: center;
}

.fullscreen_share_content #social_share_wrapper ul li
{
	display: inline-block;
	margin: 0 20px 0 20px;
}

.fullscreen_share_content #social_share_wrapper ul li a
{
	color: #fff;
	opacity: 1;
	display: block;
}

.fullscreen_share_content #social_share_wrapper ul li a i
{
	font-size: 4em;
}

.parallax
{
	position: relative;
	clear: both;
	background-size: 100%;
}

.archive_parallax
{
	height: 420px;
}

.archive_parallax a:before
{
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;

	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(30%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(83%,rgba(0,0,0,0.4)),to(rgba(0,0,0,0.59)));
	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
}

.archive_parallax .gallery_archive_desc
{
	opacity: 1;
	bottom: 40px;
}

.archive_parallax .gallery_archive_desc .post_detail
{
	color: #fff;
}

/*------------------------------------------------------------------
[6. Sidebar]
*/

#page_content_wrapper .inner .sidebar_content, .page_content_wrapper .inner .sidebar_content
{
	width: 690px;
	padding: 0;
	float: left;
	padding-right: 0px;
	padding-top: 0;
	margin-right: 10px;
}

#page_content_wrapper .inner .sidebar_content.portfolio
{
	width: 690px;
	padding-right: 0;
}

#page_content_wrapper .inner .sidebar_content.page_content, .page_content_wrapper .inner .sidebar_content.page_content
{
	padding: 20px;
	box-sizing: border-box;
	background: #fff;
}

.page_content_wrapper .inner .sidebar_content.page_content
{
	background: transparent;
	padding: 0;
}

#page_content_wrapper .inner #portfolio_filter_wrapper.sidebar_content
{
	width: 100%;
	padding-right: 0;
	margin-right: 0;
	border: 0;
}

#page_content_wrapper .inner .sidebar_content.nopadding, .page_content_wrapper .inner .sidebar_content.nopadding
{
	padding-top: 0 !important;
}

#page_content_wrapper .inner .sidebar_content.left_sidebar, .page_content_wrapper .inner .sidebar_content.left_sidebar
{
	border-right: 0;
	float: right;
}

#page_content_wrapper .inner .sidebar_content.full_width, .page_content_wrapper .inner .sidebar_content.full_width
{
	width: 100%;
	margin-top: 0;
	margin-right: 0;
	border: 0;
}

body.single-portfolios #page_content_wrapper .inner .sidebar_content.full_width
{
	margin-top: 40px;
}

#page_content_wrapper.horizontal, .page_content_wrapper.horizontal
{
	padding: 0;
	width: 100%;
	max-width: 100% !important;
}

#page_content_wrapper img:not(.horizontal_gallery_img), .page_content_wrapper img:not(.horizontal_gallery_img)
{
	max-width: 100%;
}

#horizontal_gallery, .horizontal_gallery
{
	opacity: 0;
	overflow-x: scroll;
	clear: both;
	margin-top: 0;
	margin-bottom: 0;
}

#horizontal_gallery table, .horizontal_gallery table
{
	margin: 0;
}

#horizontal_gallery.tg_post, #horizontal_gallery.tg_post table, .horizontal_gallery.tg_post, .horizontal_gallery.tg_post table
{
	margin: 0;
}

#horizontal_gallery.visible, .horizontal_gallery.visible
{
	opacity: 1;
}

#horizontal_gallery_wrapper tbody tr td, .horizontal_gallery_wrapper tbody tr td
{
	display:table-cell;
	vertical-align: middle;
	padding: 0;
	padding-top: 0;
	padding-bottom: 0;
	border: 0 !important;
}

#horizontal_gallery_wrapper tbody tr td:last-child, .horizontal_gallery_wrapper tbody tr td:last-child
{
	padding-right: 0;
}

#horizontal_gallery_wrapper tbody tr td:first-child, .horizontal_gallery_wrapper tbody tr td:first-child
{
	padding-left: 0;
}

#horizontal_gallery_wrapper tbody tr td.horizontal_gallery_info, .horizontal_gallery_wrapper tbody tr td.horizontal_gallery_info
{
	padding: 40px;
}

#horizontal_gallery_wrapper tbody tr td a, .horizontal_gallery_wrapper tbody tr td a
{
	outline: none;
}

#horizontal_gallery_wrapper tbody tr td .img-caption, .horizontal_gallery_wrapper tbody tr td .img-caption
{
	min-height: 33px;
	font-weight: normal;
}

#horizontal_gallery_wrapper tbody tr td.horizontal_gallery_info div, .horizontal_gallery_wrapper tbody tr td.horizontal_gallery_info div
{
	width: 250px;
	text-align: left;
}

#horizontal_gallery_wrapper img.horizontal_gallery_img, .horizontal_gallery_wrapper img.horizontal_gallery_img
{
	max-width: none;
	height: auto;
}

#horizontal_gallery_wrapper .gallery_image_wrapper, .horizontal_gallery_wrapper .gallery_image_wrapper
{
	text-align: center;
	display: none;
}

#horizontal_gallery_wrapper .gallery_image_wrapper.visible, .horizontal_gallery_wrapper .gallery_image_wrapper.visible
{
	display: block;
}

.image_caption.fullscreen
{
	clear: both;
	padding-top: 15px;
}

.password_container
{
	padding: 100px 0 140px 0;
}

.lock_wrapper
{
	text-align: center;
	margin: auto;
	margin-top: 0;
	margin-bottom: 20px;
}

.lock_wrapper i
{
  	font-size: 8em;
}

input#gallery_password
{
	width: 30%;
}

#imageFlow {
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0%;
    top: 20%;
    overflow: hidden;
    background: transparent;
}
#imageFlow .diapo {
    position: absolute;
    left: -4000px;
    cursor: pointer;
    -ms-interpolation-mode: nearest-neighbor;
}
#imageFlow .link {
    border: dotted #fff 1px;
    margin-left: -1px;
    margin-bottom: -1px;
}
#imageFlow .text {
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 26%;
    text-align: center;
    z-index: 99;
}
#imageFlow .title {
    font-style: italic;
    opacity: 0.5;
}
#imageFlow .legend {
    font-size: 0.8em;
}
#imageFlow .scrollbar {
    position: absolute;
    left: 10%;
    bottom: 10%;
    width: 80%;
    height: 16px;
    z-index: 1000;
}
#imageFlow .track {
    position: absolute;
    left: 1%;
    width: 98%;
    height: 10px;
    -moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
}
#imageFlow .arrow-left {
    position: absolute;
    display: none;
}
#imageFlow .arrow-right {
    position: absolute;
    right: 0px;
    display: none;
}
#imageFlow .bar {
    position: absolute;
    height: 10px;
    left: 0;
    width: 100px;
    -moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
}

.diapo {
	opacity: 1;
}

.diapo.current {
	opacity: 1;
}

.ppb_wrapper
{
	margin-top: 0;
	width: 100%;
	overflow-x: hidden;
}

#page_content_wrapper.hasbg.nomargintop
{
	margin-top: 0 !important;
}

#page_content_wrapper .inner .sidebar_content.full_width.nopadding, .page_content_wrapper .inner .sidebar_content.full_width.nopadding
{
	padding: 0 !important;
}

#page_content_wrapper .inner .sidebar_content img, .page_content_wrapper .inner .sidebar_content img
{
	max-width: 100%;
	height: auto;
}

#page_content_wrapper .inner .sidebar_content .map_shortcode_wrapper img, .page_content_wrapper .inner .sidebar_content .map_shortcode_wrapper img
{
	max-width: none;
}

#page_content_wrapper .inner .sidebar_content div.img-caption, .page_content_wrapper .inner .sidebar_content div.img-caption
{
	max-width: 100%;
}

#page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper, .page_content_wrapper .inner .sidebar_content.full_width.blog_grid_wrapper
{
	width: 103%;
	padding: 0;
}

#blog_grid_wrapper, .blog_grid_wrapper
{
	width: 1195px;
}

#page_content_wrapper .inner .sidebar_wrapper, .page_content_wrapper .inner .sidebar_wrapper
{
	width: 220px;
	float: left;
	margin-top: 0;
	margin-left: 30px;
	padding-top: 0;
}

#page_content_wrapper .inner .sidebar_wrapper.adjust, #page_content_wrapper .inner .sidebar_wrapper.left_sidebar.adjust
{
	padding-top: 50px;
}

#page_content_wrapper .inner .sidebar_wrapper.left_sidebar
{
	width: 230px;
	margin-right: 0;
	margin-left: 0;
}

#page_content_wrapper .inner .sidebar_wrapper a, .page_content_wrapper .inner .sidebar_wrapper a
{
	color: #222;
}

#page_content_wrapper .inner .sidebar_wrapper .sidebar, .page_content_wrapper .inner .sidebar_wrapper .sidebar
{
	width: 100%;
	float: left;
	margin-bottom: 40px;
}

#page_content_wrapper .inner .sidebar_wrapper .sidebar .content, .page_content_wrapper .inner .sidebar_wrapper .sidebar .content
{
	width: 100%;
	margin: 0 0 0 0;
}

.mobile_menu_wrapper .sidebar_wrapper h2.widgettitle
{
	color: #fff;
}

.mobile_menu_wrapper .sidebar_wrapper a
{
	color: #777;
}

.mobile_menu_wrapper .page_content_wrapper
{
	width: 100%;
}

.mobile_menu_wrapper .sidebar_wrapper
{
	width: 100%;
	margin-top: 40px;
}

.mobile_menu_wrapper .sidebar_wrapper .sidebar .content .sidebar_widget li
{
	margin-bottom: 20px;
}

/*------------------------------------------------------------------
[7. Form & Input]
*/

input[type=text], input[type=password], input[type=email], input[type=url], input[type=date], input[type=tel], input.wpcf7-text, select
{
	padding: 13px 10px 13px 10px;
	font-size: 100%;
	font-family: Lato, 'Helvetica Neue', Arial,Verdana,sans-serif;
	margin: 0;
	background: #fcfcfc;
	border: 1px solid #e1e1e1;
	outline: none;
	-webkit-transition: border-color linear .3s;
	-moz-transition: border-color linear .3s;
	-o-transition: border-color linear .3s;
	transition: border-color linear .3s;
	box-sizing: border-box;
	-webkit-appearance: none;
}

input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=url]:focus, input[type=date]:focus, input[type=tel]:focus, input.wpcf7-text:focus
{
	background: #f9f9f9;
	outline: 0;
}

.contact_form_wrapper input[type=text], .contact_form_wrapper input[type=email], .contact_form_wrapper input[type=date]
{
	width: 46%;
	display: inline;
	box-sizing: border-box;
	margin-right: 3%;
	margin-bottom: 3%;
}

.contact_form_wrapper input[type=submit]
{
	float: right;
	margin-right: 4%;
}

.contact_form_wrapper label
{
	display: none;
}

.contact_form_response ul
{
	margin-left: 20px;
	margin-bottom: 20px;
}

.contact_form_wrapper
{
	margin-top: 30px;
}

input[type=text].blur, textarea.blur
{
	color: #999;
}

#contact_form input[type="text"], #contact_form textarea
{
	margin-bottom: 15px;
	width: 97%;
}

div.wpcf7-response-output
{
	padding: 20px;
}

.wpcf7-form input.wpcf7-text, .wpcf7-form textarea, .wpcf7-form input.wpcf7-date
{
	width: 100%;
	box-sizing: border-box;
}

.fullwidth_comment_wrapper #commentform textarea
{
	width: 100%;
}

.fullwidth_comment_wrapper .post_content_wrapper
{
	background: transparent !important;
	padding: 30px 0 30px 0 !important;
	width: 100% !important;
}

label
{
	clear: both;
	float: none;
	width: 100%;
	margin-left: 0;
	margin-bottom: 10px;
	display: block;
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 600;
}

.form-allowed-tags
{
	display: none !important;
}

p.comment-notes
{
	margin-bottom: 20px;
}

textarea
{
	font-size: 100%;
	border: 1px solid #e1e1e1;
	background: #fcfcfc;
	margin: 0;
	height: 200px;
	overflow: auto;
	padding: 13px 10px 13px 10px;
	font-family: Lato, 'Helvetica Neue', Arial,Verdana,sans-serif;
	outline: none;
	-webkit-transition: border-color linear .2s;
	-moz-transition: border-color linear .2s;
	-o-transition: border-color linear .2s;
	transition: border-color linear .2s;
	box-sizing: border-box;
	-webkit-appearance: none;
}

input[type=submit], input[type=button], a.button, .button
{
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	padding: .4em 1.5em .4em 1.5em;
	color: #fff;
	background: #efc337;
	border: 2px solid #efc337;
	text-transform: uppercase;
	font-size: 13px;
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	text-shadow: none;
	text-shadow: none;
	-webkit-appearance: none;
	letter-spacing: 1px;
	box-shadow: 0 0 0 0 !important;
	line-height: 1.5 !important;
	letter-spacing: 2px;

	-webkit-transition: color .2s linear, background .1s linear, opacity .2s linear;
	-moz-transition: color .2s linear, background .1s linear, opacity .2s linear;
	-ms-transition: color .2s linear, background .1s linear, opacity .2s linear;
	-o-transition: color .2s linear, background .1s linear, opacity .2s linear;
	transition: color .2s linear, background .1s linear, opacity .2s linear;
}

a.button.continue_ppb_blog
{
	margin: auto;
	float: none;
	display: block;
	clear: both;
	padding: 2em 1.5em 2em 1.5em;
}

.post_button_wrapper
{
	margin-top: 20px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

a.button.fullwidth.ppb
{
	padding: 3.5em 1.5em 3em 1.5em !important;
	border: 0;
	width: 100%;
	box-sizing: border-box;
}

a.button.fullwidth.ppb .subtitle
{
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 5px;
	text-transform: uppercase;
}

a.button.fullwidth.ppb .title
{
	font-size: 32px;
	font-style: italic;
	margin-bottom: 20px;
}

a.button.fullwidth.ppb i
{
	font-size: 30px;
}

input[type=submit].white, input[type=button].white, a.button.white, .button.white, a.button.white:hover, .button.white:hover, a.button.white:active, .button.white:active
{
	color: #fff;
	background: transparent;
	border-color: #fff;
}

input[type=submit].whitebg, input[type=button].whitebg, a.button.whitebg, .button.whitebg, a.button.whitebg:hover, .button.whitebg:hover, a.button.whitebg:active, .button.whitebg:active
{
	background: #fff;
	color: #000;
	border-color: #fff;
}

input[type=submit].black, input[type=button].black, a.button.black, .button.black, a.button.black:hover, .button.black:hover, a.button.black:active, .button.black:active
{
	color: #000;
	border-color: #000;
}

input[type=button]:hover, input[type=submit]:hover, a.button:hover, .button:hover, .button.submit, a.button.white:hover, .button.white:hover, a.button.white:active, .button.white:active
{
	color: #fff;
	border-color: #222;
	background: #222;
}

.error
{
	color: #ff0000;
}

a.comment-reply-link
{
	margin: 0 0 0 10px;
	float:left;
	line-height: 28px;
	font-weight: 600;
}

input[type=submit].medium, input[type=button].medium, a.button.medium
{
	font-size: 15px;
	padding: 12px 20px 11px 20px;
}

input[type=submit].large, input[type=button].large, a.button.large
{
	font-size: 17px;
	padding: 16px 25px 15px 25px;
}

#cancel-comment-reply-link
{
	display: block;
	margin: 0 20px 0 10px;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

input[type=submit].left, input[type=button].left, a.button.left
{
	float: left;
}

input[type=submit].right, input[type=button].right, a.button.right
{
	float: right;
}

input[type=submit].center, input[type=button].center, a.button.center
{
	margin: auto;
}

/*------------------------------------------------------------------
[8. Pagination]
*/

.pagination {
	margin: 0 0 40px 0;
	float: left;
}

.pagination p a:first-child {
	float: left;
}

.pagination p a:last-child {
	float: right;
}

.pagination a, .pagination span
{
	height: 30px;
	width: 30px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	color: #777;
	background: #f9f9f9;
	margin-right: 10px;
	border-radius: 50px;
	overflow: hidden;
	font-size: 12px;
}

.pagination span, .pagination a:hover
{
	color: #fff;
	font-weight: bold;
	background: #888;
}

.pagination_detail
{
	margin-top: 9px;
	float: right;
	font-size: 12px;
}

#page_content_wrapper ul
{
	margin: 0;
}

#page_content_wrapper ol
{
	margin: 15px 0 15px 40px;
}

/*------------------------------------------------------------------
[9. Widgets]
*/

#page_content_wrapper ul.flickr
{
	margin: 0 0 10px 0;
}

#page_content_wrapper ul.posts.blog
{
	list-style: none;
	margin-left: 0 !important;
	float: left;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
}

#page_content_wrapper ul.flickr li img
{
	width: 65px;
	height: auto;
}

#page_content_wrapper .sidebar .content .posts.blog li img, #page_content_wrapper .posts.blog li img
{
	float: left;
	margin: 0 10px 2px 0;
 	width: 52px;
}

.post_circle_thumb
{
	position: relative;
	z-index: 2;
	overflow: hidden;
	float: left;
	width: 70px;
	height: 70px;
	float: left;
	margin-right: 0;
}

.post_circle_thumb img
{
	width: 60px;
	height: auto;
}

.post_circle_thumb.flickr
{
	margin-right: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget, #page_content_wrapper .sidebar .content .posts.blog, .page_content_wrapper .sidebar .content .sidebar_widget, .page_content_wrapper .sidebar .content .posts.blog
{
	list-style: none;
	margin-left: 0;
	margin-top: 0;
	padding: 0;
}

#page_content_wrapper .sidebar .content .posts.blog li, .page_content_wrapper .sidebar .content .posts.blog li
{
	padding: 0 0 20px 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li, .page_content_wrapper .sidebar .content .sidebar_widget li
{
	margin: 50px 0 0px 0;
	padding: 0;
	float: left;
	clear: both;
	width: 100%;
}

#page_content_wrapper .sidebar .content .sidebar_widget li select, .textwidget select, .page_content_wrapper .sidebar .content .sidebar_widget li select
{
	margin: 10px 0 10px 0;
	max-width: 100%;
}

#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle, h2.widgettitle
{
	font-size: 13px;
	display: block;
	text-align: center;
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	color: #222;
	font-weight: 400;
	letter-spacing: 2px;
	text-transform: uppercase;
	position: relative;
}

#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle:before, h2.widgettitle:before
{
  	content: "";
  	border-bottom: 1px solid #d4d4d4;
  	width: 100%;
  	position: absolute;
  	left: 0;
  	top: 50%;
  	z-index: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle span, h2.widgettitle span
{
  	display: inline-block;
  	padding: 0 1.2em;
  	background: #ffffff;
  	position: relative;
  	z-index: 2;
}

#page_content_wrapper .sidebar .content .sidebar_widget li:first-child, .page_content_wrapper .sidebar .content .sidebar_widget li:first-child
{
	margin-top: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul, .page_content_wrapper .sidebar .content .sidebar_widget li ul
{
	list-style: none;
	padding: 20px 0 0 0;
	margin: 0 0 15px 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul li ul.children, .page_content_wrapper .sidebar .content .sidebar_widget li ul li ul.children
{
	margin-left: 10px;
	margin-bottom: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul li ul.children li:last-child, .page_content_wrapper .sidebar .content .sidebar_widget li ul li ul.children li:last-child
{
	border: 0;
	margin-bottom: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li.widget_pages ul li ul.children, .page_content_wrapper .sidebar .content .sidebar_widget li.widget_pages ul li ul.children
{
	margin-left: 20px;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul li, #footer .sidebar_widget li ul li, .page_content_wrapper .sidebar .content .sidebar_widget li ul li, #footer .sidebar_widget li ul li
{
	padding: 0;
	margin-top: 0;
	margin-bottom: 5px;
}

#footer .sidebar_widget li.widget_nav_menu ul li, #page_content_wrapper .sidebar .content .sidebar_widget li.widget_pages ul li
{
	border: 0;
}

#footer .sidebar_widget li ul li
{
	border-color: #000000;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul li:first-child, .page_content_wrapper .sidebar .content .sidebar_widget li ul li:first-child, #footer ul.sidebar_widget li ul li:first-child, #footer ul.sidebar_widget li .textwidget
{
	padding-top: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li:first-child, .page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li:first-child, #footer ul.sidebar_widget li ul.flickr li:first-child
{
	border: 0;
	padding-top: 0;
}

#page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li a, .page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li a
{
	width: 100%;
	box-sizing: border-box;
	padding: 5px 10px 5px 0;
	display: block;
}

#page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li.current-menu-item a, .page_content_wrapper .inner .sidebar_wrapper ul.sidebar_widget li.widget_nav_menu ul.menu li.current-menu-item a
{
	font-weight: bold;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
}

#wp-calendar tr td, #wp-calendar tr th
{
	padding: 5px 0 5px 0;
	text-align: center;
	background: transparent;
}

#wp-calendar tr td#prev, #wp-calendar tr td#next
{
	text-transform: uppercase;
	padding-top: 20px;
}

#wp-calendar tr td.pad, #wp-calendar tr td#prev, #wp-calendar tr td#next
{
	background: transparent !important;
}

#wp-calendar caption
{
	display:none;
}

#wp-calendar
{
	margin: 20px 0 0 0;
	width: 100%;
}

.mobile_menu_wrapper .widget_calendar table tr th, .mobile_menu_wrapper .widget_calendar table tr td
{
	border-color: #555;
}

table
{
	margin: 10px 0 10px 0;
}

table tr td
{
	border-bottom: 1px solid #e1e1e1;
}

table tr th, table tr td
{
	padding: 20px;
	text-align: left;
	font-weight: bold;
	border-bottom: 1px solid #e1e1e1;
}

#footer table tr th, #footer table tr td
{
	border-bottom: 1px solid #000000 !important;
}

#footer .widget_tag_cloud div a
{
	border-color: #000000 !important;
}

#footer_menu
{
	float: right;
	display: block;
	list-style: none;
}

#footer_menu li
{
	float: left;
	margin-left: 20px;
}

#footer_menu li a
{
	font-size: 11px;
	font-weight: normal;
	color: #fff;
}

#footer_menu li a:hover
{
	color: #fff;
}

#footer_menu li ul
{
	display: none;
}

table thead tr th
{
	background: transparent;
}

table#wp-calendar thead tr th
{
	text-align: center;
}

table tfoot tr, table tfoot tr td
{
	background: transparent;
	border-bottom: 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr, .page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr
{
	list-style: none;
	margin: 5px 0 30px 0;
	float: left;
	display: block;
	padding: 15px 0 3px 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li, .page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li
{
	display: block;
	float: left;
	margin: 0 0 5px 0;
	padding: 0;
	border: 0;
	clear: none;
	width: 33.3%;
	padding-right: 10px;
	box-sizing: border-box;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li img, .page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li img
{
	width: 75px;
	height: 75px;
	object-fit: cover;
}

.mobile_menu_wrapper .sidebar .content .sidebar_widget li ul.flickr li
{
	width: 33.3%;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter, .page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter
{
	margin: 0;
	margin-top: 20px;
	list-style:none;
	padding: 0 0 0 0;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter li, .page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter li
{
	padding-left:32px;
	padding-bottom: 15px;
	border: 0;
	position: relative;
	box-sizing: border-box;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter li:before, .page_content_wrapper .sidebar .content .sidebar_widget li ul.twitter li:before
{
	position: absolute;
	left: 0;
	top: 0;
	font-family: 'FontAwesome';
	content: "\f099";
	font-size: 22px;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.social_media, .page_content_wrapper .sidebar .content .sidebar_widget li ul.social_media
{
	list-style: none;
	margin: 10px 0 15px 0;
	float: left;
	display: block;
	padding: 0 0 3px 0;
	margin-left: -5px;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.social_media li, .page_content_wrapper .sidebar .content .sidebar_widget li ul.social_media li
{
	display: block;
	float: left;
	margin: 0 5px 0 0;
}

ul.list
{
	margin: 5px 0 0 20px;
}

.highlight_yellow
{
	background: #ffc600;
	color: #000;
	text-shadow: -1px 0 1px #fff;
}

.highlight_black
{
	background: #000;
	color: #fff;
}

#page_content_wrapper ul.twitter
{
	margin: 0;
	list-style:none;
}

#page_content_wrapper ul.twitter li
{
	padding-left:32px !important;
	padding-top: 0 !important;
	background-repeat:no-repeat;
	background-position:0px 0.3em;
	padding-bottom: 2em;
}

#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr, ul.flickr, .page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr, ul.flickr
{
	list-style: none;
	margin: 10px 0 -10px 0;
	float: left;
	display: block;
	clear: both;
	width: 100%;
}

ul.flickr li
{
	float: left;
	margin: 0 5px 0 0;
}

#page_content_wrapper .sidebar .content .posts.blog, .page_content_wrapper .sidebar .content .posts.blog
{
	list-style: none;
	margin-top: 30px;
}

#page_content_wrapper .sidebar .content .posts.blog.withthumb, .page_content_wrapper .sidebar .content .posts.blog.withthumb
{
	margin-top: 20px;
}

#page_content_wrapper .sidebar .content .posts.blog li, .page_content_wrapper .sidebar .content .posts.blog li
{
	line-height: 1em;
	clear: both;
	border:0;
	background: none;
	width: 100%;
	float: left;
	margin: 0;
}

#page_content_wrapper .posts.blog li, .page_content_wrapper .posts.blog li
{
	float: left;
	width: 49.4%;
	margin-right: 1.2%;
	margin-bottom: 1.2%;
	padding: 20px;
	box-sizing: border-box;
	border: 1px solid #e1e1e1;
	display: table;
	text-align: left;

	-webkit-transition: background .2s linear;
	-moz-transition: background .2s linear;
	-ms-transition: background .2s linear;
	-o-transition: background .2s linear;
	transition: background .2s linear;
}

#page_content_wrapper .posts.blog li:nth-child(2n), .page_content_wrapper .posts.blog li:nth-child(2n)
{
	margin-right: 0;
	float: right;
}

#page_content_wrapper .posts.blog li a, .page_content_wrapper .posts.blog li a
{
	width: 100%;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

#page_content_wrapper .sidebar .content .posts.blog.withthumb li, #page_content_wrapper .posts.blog.withthumb li, .page_content_wrapper .sidebar .content .posts.blog.withthumb li, .page_content_wrapper .posts.blog.withthumb li
{
	line-height: 1.5em;
	border: 0;
	display: block;
}

#page_content_wrapper .sidebar .content .posts.blog li, .page_content_wrapper .sidebar .content .posts.blog li
{
	padding-top: 10px;
}

#page_content_wrapper .sidebar .content .posts.blog li strong.header, #page_content_wrapper .posts.blog li strong.header, .page_content_wrapper .sidebar .content .posts.blog li strong.header, .page_content_wrapper .posts.blog li strong.header
{
	font-weight: normal;
}

#cover_content
{
	position:fixed;top:20%;left:40px;z-index:9;
}

#page_content_wrapper .sidebar .content .textwidget, .page_content_wrapper .sidebar .content .textwidget
{
	margin-top: 0;
	padding: 10px 0 0 0;
}

.textwidget img
{
	max-width: 95%;
	height: auto;
}

#searchsubmit
{
	margin: -8px;
	line-height: 18px;
	padding: .6em 1.5em 0.3em 1.5em !important;
}

.widget_search input#searchsubmit
{
	margin: 10px 0 0 0;
}

.widget_tag_cloud div, .tag_cloud
{
	margin: 20px 0 10px 0;
}

.widget_tag_cloud div a, .meta-tags a, .tag_cloud a
{
	display: block;
	width: 100%;
	-webkit-transition: background .2s linear, border .1s linear;
	-moz-transition: background .2s linear, border .1s linear;
	-ms-transition: background .2s linear, border .1s linear;
	-o-transition: background .2s linear, border .1s linear;
	transition: background .2s linear, border .1s linear;

	font-size: 14px !important;
	letter-spacing: 0px;
	text-transform: capitalize;
}

.widget_tag_cloud div a:before, .meta-tags a, .tag_cloud a:before
{
	font-family: 'FontAwesome';
	content: "\f02b";
	margin-right: 10px;
}

#gallery_caption a, #gallery_caption a:hover, #gallery_caption a:active
{
	color: #fff;
}

#gallery_caption
{
	bottom: 20px;
	text-align: center;
	position: absolute;
	z-index: 9;
	width: 100%;
	color: #fff;
	font-style: italic;
}

#gallery_caption .tg_caption
{
	color: #fff;
	font-style: italic;
}

.social_share_button_wrapper
{
	text-align: center;
	width: 100%;
	margin-bottom: 20px;
}

.social_share_button_wrapper ul
{
	list-style: none;
	display: inline-block;
	margin-left: 0 !important;
	margin-bottom: 30px;
}

.social_share_button_wrapper ul li
{
	display: inline-block;
	margin-right: 3px;
	margin-left: 3px;
}

.social_share_button_wrapper ul li a
{
	display: block;
	padding: 0 25px 0 25px;
	line-height: 40px;
	height: 40px;
	color: #fff;
	text-align: center;
}

.social_share_button_wrapper ul li a.email_share
{
	background: #1980be;
}

.social_share_button_wrapper ul li a.facebook_share
{
	background: #455fa1;
}

.social_share_button_wrapper ul li a.facebook_share i, .social_share_button_wrapper ul li a.twitter_share i
{
	margin-right: 7px;
}

.social_share_button_wrapper ul li a.twitter_share
{
	background: #58abf5;
}

.social_share_button_wrapper ul li a.pinterest_share
{
	background: #cd1d1f;
}

.social_share_button_wrapper ul li a.google_share
{
	background: #eb4026;
}

/*------------------------------------------------------------------
[11. Contact Form Captcha]
*/

#captcha-wrap{
	border:solid #870500 1px;
	width:270px;
	-webkit-border-radius: 10px;
	float:left;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background:#870500;
	text-align:left;
	padding:3px;
	margin-top:3px;
	height:112px;
}
#captcha-wrap .captcha-box{
	-webkit-border-radius: 7px;
	background:#fff;
	-moz-border-radius: 7px;
	border-radius: 7px;
	text-align:center;
	border:solid #fff 1px;
}
#captcha-wrap .text-box{
	-webkit-border-radius: 7px;
	background:#ffdc73;
	-moz-border-radius: 7px;
	width:140px;
	height:48px;
	float:left;
	margin:4px;
	border-radius: 7px;
	text-align:center;
	border:solid #ffdc73 1px;
}

#captcha-wrap .text-box input{
	width:120px;
	-webkit-appearance: textfield;
padding: 1px;
background-color: white;
border-image: initial;
-webkit-rtl-ordering: logical;
-webkit-user-select: text;
cursor: auto;
float: none;
margin-top: 12px;

}
#captcha-wrap .text-box label{
	 color:#333;
	 font-family: helvetica,sans-serif;
	 font-size:11px;
	 width:140px;
	 padding-top:3px;
	 padding-bottom:3px;
	 margin: 0;
	 font-weight: normal;
	 text-transform: none;
}
#captcha-wrap .captcha-action{
	float:right; width:117px;
	background:url(/wp-content/themes/photome/images/logos.jpg) top right no-repeat;
	height:44px; margin-top:3px;
}
#captcha-wrap  img#captcha-refresh{
	margin-top:9px;
	border:solid #333333 1px;
	margin-right:6px;
	cursor:pointer;
}

.map_shortcode_wrapper
{
	max-width: 100%;
}

.map_shadow
{
	width: 100%;
	margin-top: 0;
}

.map_shadow.fullwidth
{
	width: 100%;
	margin-top: 0;
	clear: both;
}

#reponse_msg ul:empty
{
	margin: 0;
}

#reponse_msg ul
{
	margin-left: 20px;
}

#reponse_msg ul li:last-child
{
	margin-bottom: 20px;
}

#reponse_msg ul li.error
{
	color: #ff0000;
}

.mejs-container
{
	background: #333 !important;
	width: 100% !important;
}

.mejs-container:hover
{
	background: transparent !important;
	color: #000 !important;
}

.ppb_background_overlay
{
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.service_content_wrapper
{
	margin: 0;
	width: 100%;
	float: left;
}

.service_wrapper
{
	margin: 5px 0 5px 0;
	padding: 5px 0 5px 0;
}

.service_wrapper.center.boxed
{
	padding: 25px 20px 20px 20px;
	background: #fff;
	box-sizing: border-box;
	margin: 15px 0 15px 0;
}

.service_wrapper.center, .service_wrapper.center_nocircle
{
	text-align: center;
}

.service_title
{
	margin: 0 0 15px 0;
	float: left;
	width: 71%;
	width: calc(100% - 60px);
	margin-top: 0;
	text-align: left;
}

.service_title.list
{
	width: 100%;
}

.service_wrapper.center .service_title, .service_wrapper.center_nocircle .service_title
{
	width: 100%;
	max-width: 100%;
	float: none;
	clear: both;
	text-align: center;
	margin-top: 20px;
}

.service_wrapper.center_nocircle .service_title
{
	margin-top: 10px;
}

.service_wrapper.center .service_title h6
{
	margin-bottom: 10px;
}

.service_icon
{
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	width: 50px !important;
	height: 50px !important;
	-webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
	margin: auto;
	margin-bottom: 10px;
	float: left;
	margin-right: 10px;
	text-align: center;
}

.service_wrapper.center .service_icon, .service_icon.vertical
{
	float: none;
	clear: both;
	margin: auto !important;
	width: 90px !important;
	height: 90px !important;
	background: #f0f0f0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	margin-bottom: 15px;
}

.service_wrapper.center_nocircle .service_icon
{
	float: none;
	clear: both;
	margin: auto !important;
	width: 90px !important;
	height: 90px !important;
	background: transparent;
	margin-bottom: 15px;
}

.service_wrapper.center_nocircle .service_icon
{
	border: 0;
}

.service_wrapper.center.boxed .service_icon, .service_wrapper.center .service_icon, .service_icon.vertical
{
	width: 80px !important;
	height: 80px !important;
	position: relative;
}

.service_icon.vertical
{
	float: left;
	margin-top: 15px !important;
}

.service_wrapper.center.boxed .service_icon .service_border, .service_wrapper.center .service_icon .service_border, .service_icon.vertical .service_border
{
	border: 1px solid transparent;
	width: 80px;
	height: 80px;
	position: absolute;
	top: -1px;
	left: -1px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.service_wrapper.center.boxed .service_icon:hover .service_border, .service_wrapper.center .service_icon:hover .service_border, .service_icon.vertical:hover .service_border
{
	transform: scale(1.18);
	border-color: #333;
	-moz-transform: scale(1.18);
	-o-transform: scale(1.18);
}

.service_wrapper.center .service_icon:hover, .service_icon.vertical:hover
{
	background: #000;
	border-color: #000;
}

.service_wrapper.center .service_icon:hover i, .service_icon.vertical:hover i
{
	color: #fff !important;
}

.service_icon img
{
	max-width: 24px;
	height: auto;
	margin-top: 10px;
}

.service_wrapper.center .service_icon img
{
	max-width: 36px;
	line-height: 36px;
}

.service_icon i
{
	font-size: 36px;
	line-height: 36px;
	color: #000;
}

.service_wrapper.center .service_icon i
{
	font-size: 24px;
	margin-top: 22px;
	color: #000;
}

.service_wrapper.center_nocircle .service_icon i
{
	font-size: 45px;
	margin-top: 20px;
	color: #000;
}

.service_wrapper.center.boxed .service_icon i, .service_icon.vertical i
{
	font-size: 24px;
	margin-top: 22px;
	color: #000;
}

.service_title h3
{
	font-size: 18px;
}

.one_fourth .service_title h3
{
	font-size: 18px;
}

.one_fourth .service_title
{
	max-width: 71%;
}

.service_content
{
	clear: both;
	text-align: left;
	margin-top: 5px;
}

.service_wrapper.center .service_content, .service_wrapper.center_nocircle .service_content
{
	text-align: center;
}

.gallery_classic_entry
{
	text-align: center;
}

.gallery_classic_entry img
{
	width: auto;
	max-height: 600px;
}

.promo_box
{
	padding: 25px;
	background: #f3f3f3;
	border: 1px solid #e1e1e1;
	position: relative;
	clear: both;
}

.promo_box_wrapper .promo_box
{
	padding: 55px 0 55px 0;
	background: transparent;
	border: 0;
	color: #fff;
}

.promo_box_wrapper .promo_box h5
{
	color: #fff !important;
}

.promo_box.shadow:after, .promo_box.shadow:before
{
	content: " ";
	position: absolute;
	width: 50%;
	height: 30%;
	z-index: -10;
}

.promo_box.shadow:before
{
	top: 52px;
	left: 5px;
	-webkit-transform: rotate(-5deg);
	-moz-transform: rotate(-5deg);
	-ms-transform: rotate(-5deg);
	-o-transform: rotate(-5deg);
	transform: rotate(-5deg);
	-webkit-box-shadow: 7px 6px 15px #333;
	-moz-box-shadow: 7px 6px 15px #333;
	-ms-box-shadow: 7px 6px 15px #333;
	-o-box-shadow: 7px 6px 15px #333;
	box-shadow: 7px 6px 15px #333;
}

.promo_box.shadow:after
{
	top: 52px;
	right: 5px;
	-webkit-transform: rotate(5deg);
	-moz-transform: rotate(5deg);
	-ms-transform: rotate(5deg);
	-o-transform: rotate(5deg);
	transform: rotate(5deg);
	-webkit-box-shadow: -7px 6px 15px #333;
	-moz-box-shadow: -7px 6px 15px #333;
	-ms-box-shadow: -7px 6px 15px #333;
	-o-box-shadow: -7px 6px 15px #333;
	box-shadow: -7px 6px 15px #333;
}

.promo_box h5
{
	margin-bottom: 5px;
	color: #222 !important;
	display: block;
	padding-right: 20%;
}

.promo_box p
{
	display: block;
}

.promo_box .button
{
	float: right;
	display: inline-block;
	margin-left: 10px;
	margin-top: -8px;
	background: #000;
	border-color: #000;
	color: #fff;
	padding: .7em 1.5em .7em 1.5em !important;
}

.promo_box .button.transparent
{
	background: transparent;
	border-color: #fff !important;
}

.promo_box .button.transparent:hover
{
	background: #000;
	color: #fff !important;
	border-color: #000 !important;
}

.alert_box
{
	padding: 20px;
	text-align: center;
	position: relative;
}

.alert_box_msg
{
	max-width: 85%;
	margin: auto;
}

.alert_box.general
{
	background: #d1edf5;
}

.alert_box.error
{
	color: #b13c3c;
	background: #ffe9e9;
}

.alert_box.error a
{
	color: #b13c3c;
}

.alert_box.success
{
	color: #6a863b;
	background: #e9f3d2;
}

.alert_box.success a
{
	color: #6a863b;
}

.alert_box.notice
{
	color: #a18e0f;
	background: #fbf6e3;
}

.alert_box.notice a
{
	color: #a18e0f;
}

.alert_box a.close_alert
{
	position: absolute;
	top: 5px;
	right: 10px;
	display: block;
	font-size: 11px;
	opacity: 0.5;
}

.alert_box .alert_icon
{
	position: absolute;
	top: 18px;
	left: 25px;
	display: block;
	font-size: 14px;
	padding: 5px;
}

.alert_box.general .alert_icon
{
	color: #4e7883;
	background: transparent;
	border-radius: 25px;
	border: 2px solid #4e7883;
}

.alert_box.error .alert_icon
{
	color: #b13c3c;
	background: transparent;
	border-radius: 25px;
	border: 2px solid #b13c3c;
}

.alert_box.success .alert_icon
{
	color: #6a863b;
	background: transparent;
	border-radius: 25px;
	border: 2px solid #6a863b;
}

.alert_box.notice .alert_icon
{
	color: #a18e0f;
	background: transparent;
	border-radius: 25px;
	border: 2px solid #a18e0f;
}

.accordion
{
 	border: 1px solid #e1e1e1;
 	visibility: hidden;
}

.accordion h3, .accordion div
{
	border: 0;
	background: transparent;
}

.pp_accordion, .pp_accordion_close
{
 	margin:0;
	margin-bottom: 10px !important;
	visibility: hidden;
}

.pp_accordion.ui-accordion, .pp_accordion_close.ui-accordion
{
	visibility: visible;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus
{
	border: 1px solid #e1e1e1;
}

.ui-corner-all, .ui-corner-top, .ui-corner-bottom
{
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.ui-state-default, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, .ui-widget-content
{
	border: 0;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active
{
	background: transparent;
}

.ui-accordion .ui-accordion-header
{
	font-weight: normal;
	display: block;
	position: relative;
	border: 1px solid #e1e1e1;
	margin-top: 0;
	background: #fff;

	-webkit-transition: color .2s linear, background .1s linear;
	-moz-transition: color .2s linear, background .1s linear;
	-ms-transition: color .2s linear, background .1s linear;
	-o-transition: color .2s linear, background .1s linear;
	transition: color .2s linear, background .1s linear;
}

.ui-accordion .ui-accordion-header:hover
{
	background: #f9f9f9;
}

.ui-accordion .ui-accordion-content
{
	border: 1px solid #e1e1e1;
	border-top: 0;
}

.ui-accordion .ui-accordion-header a
{
	padding: 1em .3em 1em 1.5em;
	font-size: 13px;
}

.ui-accordion .ui-accordion-header a i
{
	opacity: 0.3;
}

.accordion div
{
	border-top: 1px solid #ebebeb;
}

.accordion h3
{
	padding: 5px 0 5px 8px;
}

.tabs
{
	visibility: hidden;
}

.ui-tabs .ui-tabs-nav
{
	margin-left: 0 !important;
}

.ui-tabs .ui-tabs-nav li.ui-state-active
{
  z-index: 8;
}

.ui-accordion .ui-accordion-header .ui-icon
{
	width: 16px;
	height: 16px;
	background: transparent !important;
	margin-top: -9px;
	text-indent: 0;
	overflow: visible;
}

.ui-accordion .ui-accordion-header .ui-icon:after
{
	font-size:12px;
	text-decoration: inherit;
	-webkit-font-smoothing: antialiased;
	display: inline;
	width: auto;
	height: auto;
	line-height: normal;
	vertical-align: 10%;
	background-image: none;
	background-position: 0% 0%;
	background-repeat: repeat;
	margin-top: 0;
	font-family: 'FontAwesome';
	content: "\f107";
	position: absolute;
	margin-right: 0px;
	margin-top: 3px;
	color: #000000;
}

.ui-accordion .ui-accordion-header .ui-icon.ui-icon-triangle-1-s:after
{
	content: "\f106";
}

.ui-accordion.has_icon .ui-accordion-header .ui-icon:after
{
	display: none !important;
}

.ui-accordion .ui-accordion-header .ui-icon
{
	left: auto;
	right: 1em !important;
}

.ui-accordion .ui-accordion-header i
{
	margin-right: 10px;
	margin-left: 5px;
}

.ui-tabs
{
	list-style: none;
	visibility: visible;
}

.ui-tabs-panel
{
	margin-top: 10px;
}

.ui-widget-header
{
	border: 0;
}

.vertical .ui-widget-header
{
	width: 30%;
	float: left;
	display: block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	padding: 0;
	border: 0;
}

.vertical.right .ui-widget-header
{
	float: right;
}

.ui-tabs .ui-tabs-nav li
{
	background: #f0f0f0;
	margin: 0;
	border: 1px solid #e1e1e1 !important;
	border-left: 0 !important;
	padding-top: 2px;
	top: 1px;

	-webkit-transition: color .2s linear, background .1s linear;
	-moz-transition: color .2s linear, background .1s linear;
	-ms-transition: color .2s linear, background .1s linear;
	-o-transition: color .2s linear, background .1s linear;
	transition: color .2s linear, background .1s linear;
}

.ui-tabs.vertical .ui-tabs-nav li
{
	top: 0;
	left: 1px;
	position: relative;
	border: 1px solid #e1e1e1 !important;
	border-bottom: 0 !important;
}

.ui-tabs.vertical.right .ui-tabs-nav li
{
	left: -1px;
	border-left: 0 !important;
}

.vertical.ui-tabs .ui-tabs-nav li
{
	width: 100%;
	clear: both;
}

.ui-tabs .ui-tabs-nav li:hover
{
	background: #fff;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-selected
{
	border: 1px solid #e1e1e1;
	border-top: 3px solid #e1e1e1;
}

.ui-tabs .ui-tabs-nav li.ui-state-active
{
	background: #fff !important;
	border-bottom: 1px solid #fff !important;
	outline: none;
	position: relative;
	z-index: 9;
}

.ui-tabs.vertical .ui-tabs-nav li.ui-state-active
{
	border-right: 0 !important;
	position: relative;
	z-index: 9;
}

.ui-tabs.vertical.right .ui-tabs-nav li.ui-state-active
{
	border-right: 1px solid #e1e1e1 !important;
}

.ui-tabs.vertical .ui-tabs-nav li:last-child
{
	border-bottom: 1px solid #e1e1e1 !important;
}

.ui-tabs .ui-tabs-nav li.ui-state-active a
{
	outline: none;
}

.ui-tabs .ui-tabs-nav li a
{
	padding: 8px 20px 7px 20px;
}

.ui-tabs.vertical .ui-tabs-nav li a
{
	padding: 10px 20px 9px 20px;
	width: 100%;
	display: block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.tabs .ui-tabs .ui-tabs-nav li
{
	font-weight: bold;
	text-shadow: 0 1px 1px #fff;
	display: block;
}

#content_wrapper .tabs .ui-widget-header, .content_wrapper .tabs .ui-widget-header
{
	border: 0;
	margin-left: 0;
}

.tabs .ui-tabs-panel
{
	border: 1px solid #e1e1e1;
	margin:0;
	padding-top: 0em;
	background: #fff;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	z-index: 2;
	position: relative;
}

.tabs.vertical .ui-tabs-panel
{
	border-top: 1px solid #e1e1e1;
	width: 70%;
	float: right;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	z-index: 2;
	position: relative;
}

.tabs .ui-state-active a
{
	color: #333;
	font-weight: bold;
}

.ui-tabs li:first-child
{
	margin-left: -3px;
	border-left: 1px solid #e1e1e1 !important;
}

.ui-tabs.vertical li:first-child
{
	margin-left: 0;
}

.icon_list
{
	margin: 0;
	margin-left: 0 !important;
	padding: 0;
	list-style: none;
}

.icon_list li
{
	margin: 0;
	padding: 0;
	list-style-type: none;
	list-style-position: outside;
	clear: both;
	position: relative;
	float: left;
}

.icon_list li .service_title
{
	margin: 0;
}

.icon_list li .service_title strong
{
	color: #333;
	font-size: 16px;
}

.icon_list li .service_wrapper
{
	float: left;
	width: 71%;
	width: calc(100% - 100px);
	padding-top: 0;
	margin: 25px 0 25px 20px;
}

.icon_list li .service_wrapper.right
{
	margin-left: 0;
	margin-right: 2%;
}

.icon_list li .service_wrapper.right .service_content
{
	text-align: right;
}

.icon_list li .service_wrapper.right .service_title
{
	float: right;
	text-align: right;
}

.icon_list li .service_icon.right
{
	float: right;
	margin-right: 0;
	margin-left: 10px;
}

.client_logo
{
	max-width: 190px;
	height: auto;
	-webkit-transition: width .5s;
    -moz-transition: width .5s;
    -o-transition: width .5s;
    transition: width .5s;
}

.client_content
{
	display: block;
	float: left;
}

.one_third img.team_pic
{
	max-width: 304px;
	height: auto;
}

.pricing_content_wrapper
{
	margin: 0;
	width: 100%;
	float: left;
}

.pricing_content_wrapper.light .one_half.pricing
{
	margin-right: 0;
	width: 50%;
}

.pricing_content_wrapper.light .one_third.pricing
{
	margin-right: 0;
	width: 33.3%;
}

.pricing_content_wrapper.light .one_fourth.pricing
{
	margin-right: 0;
	width: 25%;
}

.pricing_content_wrapper .one_half.pricing.featured, .pricing_content_wrapper .one_third.pricing.featured, .pricing_content_wrapper .one_fourth.pricing.featured
{
	margin-top: -20px;
	box-shadow: 0 0 15px 1px rgba(0,0,0,0.1);
}

.pricing_wrapper
{
	list-style: none;
	display: block;
	margin-left: 0 !important;
	border: 1px solid #e1e1e1;
}

.pricing_wrapper li
{
	margin: 0;
	padding: 0;
	background: #fff;
	text-align: center;
	padding: 13px 0px;
	background: #fff;
	border-bottom: 1px solid #e1e1e1;
	color: #555;
}

.pricing_wrapper li:last-child
{
	border-bottom: 0;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.pricing_wrapper li.title_row
{
	background: #f3f3f3;
	color: #000000;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.pricing_content_wrapper.light .pricing_wrapper li.title_row
{
	background: #fff;
}

.pricing_content_wrapper.normal .pricing_wrapper li.title_row
{
	background: #000;
	color: #fff;
}

.pricing_wrapper li.title_row.featured
{
	background: #000;
	color: #fff;
	padding: 23px 0px;
}

.pricing_content_wrapper.light .pricing_wrapper li.title_row.featured
{
	color: #000;
}

.pricing_wrapper li.price_row
{
	color: #000000;
	background: #f9f9f9;
	padding: 0;
}

.pricing_wrapper li.button_row
{
	padding: 20px 0px;
}

.pricing_wrapper li.button_row.featured
{
	padding: 30px 0px;
}

.pricing_wrapper li.price_row strong
{
	position: relative;
	top: -26px;
	font-size: 20px;
	font-weight: normal !important;
	margin-right: 2px;
	color: #000000;
}

.pricing_wrapper li.price_row em.exact_price
{
	display: inline !important;
	font-size: 60px !important;
	font-style: normal !important;
	letter-spacing: 0px;
}

.pricing_wrapper li.price_row em.time
{
	font-style: normal;
}

.one_third.pricing
{
	width: 31.66%;
	margin-right: 2.5%;
}

.one_fourth.pricing
{
	width: 23%;
	margin-right: 2.5%;
}

.one_fourth.pricing.last
{
	margin-right:0;
	float: right;
	clear: right;
}

.one_fourth.pricing .pricing_wrapper li.price_row
{
	padding: 13px 0px;
}

.one_fourth.pricing .pricing_wrapper li.price_row strong
{
	top: -25px;
	font-size: 20px;
}

.one_fourth.pricing .pricing_wrapper li.price_row em.exact_price
{
	font-size: 55px !important;
}

.one_half img, .one_third img, .one_fourth img, .one_fifth img, .two_third img
{
	max-width: 100%;
	height: auto;
}

.page_content_wrapper .one_half .map_shortcode_wrapper img, .page_content_wrapper .one_third .map_shortcode_wrapper img, .page_content_wrapper .one_fourth .map_shortcode_wrapper img, .page_content_wrapper .one_fifth .map_shortcode_wrapper img, .page_content_wrapper .two_third .map_shortcode_wrapper img
{
	max-width: none;
}

.animated
{
	visibility: hidden;
}

.ls-container
{
	overflow: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio:0)
{
	::i-block-chrome,div.thumb_content
	{
		border-radius: 0 !important;
	}
}
doesnotexist:-o-prefocus, div.thumb_content {
  border-radius: 0 !important;
}

.fancybox-inner div
{
	max-width: 100% !important;
	height: auto !important;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dynamic_height img
{
	max-width: 100% !important;
}

.mejs-overlay-button {display:block; z-index: 99999;}

.post_wrapper.grid_layout .slider_wrapper
{
	min-height: 190px;
	margin-bottom: 15px;
}

.portfolio_post_previous, .portfolio_post_next
{
	position: fixed;
	top: 48%;
	right: 20px;
	overflow: hidden;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.portfolio_post_previous
{
	left: 0;
	opacity: 0;
}

body:hover .portfolio_post_previous
{
	opacity: 1;
	left: 20px;
}

.portfolio_post_next
{
	right: 0;
	opacity: 0;
}

body:hover .portfolio_post_next
{
	right: 20px;
	opacity: 1;
}

.portfolio_post_previous a, .portfolio_post_next a
{
	width: 30px;
	background: #fff;
	color: #000;
	text-align: center;
	padding: 8px 7px 8px 7px;
	display: block;
}

.portfolio_post_previous a, .portfolio_post_next a, .portfolio_post_previous a:hover, .portfolio_post_next a:hover
{
	color: #222;
}

.portfolio_post_previous
{
	float: left;
	max-width: 50%;
}

.portfolio_post_next
{
	float: right;
	max-width: 50%;
}

.circliful
{
	margin: auto;
}

.teaser_wrapper.left
{
	text-align: left;
}

.teaser_wrapper.center
{
	text-align: center;
}

.teaser_wrapper.right
{
	text-align: right;
}

.teaser_content_wrapper
{
	box-sizing: border-box;
	width: 100%;
	padding: 15px 0 15px 0;
	margin-top: -7px;
}

.teaser_content
{
	margin-top: 10px;
}

.testimonial_image
{
	float: left;
	margin: 0 20px 0 0;
	width: 80px;
	height: 80px !important;
	border-radius: 100px;
	overflow: hidden;
}

.testimonial_image img
{
	width: 80px;
	height: auto;
	border-radius: 100px;
}

.testimonial_content
{
	overflow: hidden;
	margin-bottom: 20px;
}

.testimonial_customer_position
{
	color: #999;
	margin-right: 5px;
	display: inline;
	float: none;
}

.testimonial_customer_company
{
	display: inline;
	margin-left: 5px;
	float: none;
}

.testimonial_column_element
{
	margin: 15px 0 15px 0;
}

.testimonial_slider_meta_position, .testimonial_slider_meta_company
{
	display: inline;
	font-size: 16px;
	margin-left: 5px;
	margin-right: 5px;
	font-size: 14px;
}

.page_slider .tp-leftarrow.default, .page_slider .tp-rightarrow.default
{
	background: transparent;
	width: 40px;
	height: 40px;
	border: 2px solid #fff;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	transition: all .3s ease;
	border-radius: 200px;
	text-align: center;
	top: 47% !important;
}

body.hover .page_slider .tp-leftarrow.default, body.hover .page_slider .tp-rightarrow.default
{
	opacity: .5;
}

body.hover .page_slider .tp-leftarrow.default:hover, body.hover .page_slider .tp-rightarrow.default:hover
{
	opacity: 1;
}

.page_slider .tp-leftarrow.default:before, .page_slider .tp-rightarrow.default:before
{
	font-family: "FontAwesome";
	font-size: 22px;
	line-height: 40px;
	display: block;
	content: '\f104';
	color: #fff;
	margin-top: 0px;
	margin-left: -2px;
}

.page_slider .tp-rightarrow.default:before
{
	content: '\f105';
}

.page_slider .tp-bullets.simplebullets.round .bullet
{
	background: #fff;
	width: 7px;
	height: 7px;
	opacity: .5;
	border-radius: 100px;
	margin-left: 5px;
}

.page_slider .tp-bullets.simplebullets .bullet:hover, .page_slider .tp-bullets.simplebullets .bullet.selected
{
	opacity: 1;
}

.one_half_bg
{
	width: 50%;
	float: left;
	background-position: 50% 50% !important;
	background-size: cover !important;
	box-sizing: border-box;
	padding: 20px;
}

.ppb_content_half_bg .one_half_bg
{
	padding: 40px;
}

.one_third_bg
{
	width: 33.3%;
	float: left;
	background-position: 50% 50% !important;
	background-size: cover !important;
	box-sizing: border-box;
	padding: 20px;
}

.one_third_bg.nopadding, .one_half_bg.nopadding
{
	padding: 0;
}

.ppb_blog_minimal .one_third_bg
{
	height: 350px;
	border-left: 1px solid #e1e1e1;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
	position: relative;
}

.ppb_blog_minimal .one_third_bg .blog_minimal_wrapper
{
	width: 100%;
	height: 100%;
	padding: 60px 40px;
	box-sizing: border-box;
}

.ppb_blog_minimal .one_third_bg .blog_minimal_wrapper .content
{
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 3;
}

.ppb_blog_minimal .one_third_bg .blog_minimal_wrapper .featured_image
{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: 0;
	z-index: 2;

	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.ppb_blog_minimal .one_third_bg .blog_minimal_wrapper .background_overlay
{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3;
	opacity: 0;

	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.ppb_blog_minimal .one_third_bg:hover .blog_minimal_wrapper .featured_image, .ppb_blog_minimal .one_third_bg:hover .blog_minimal_wrapper .background_overlay
{
	opacity: 1;
}

.ppb_blog_minimal .one_third_bg .blog_minimal_wrapper .content .post_detail
{
	position: absolute;
	bottom: 0px;
}

.ppb_blog_minimal .one_third_bg:hover .blog_minimal_wrapper .content .post_detail
{
	color: #fff;
}

.ppb_blog_minimal .one_third_bg:hover .blog_minimal_wrapper .content, .ppb_blog_minimal .one_third_bg:hover .blog_minimal_wrapper .content h4
{
	color: #fff !important;
}

.two_third_bg
{
	width: 66.6%;
	float: left;
	background-position: 50% 50% !important;
	background-size: cover !important;
	box-sizing: border-box;
	padding: 20px;
}

.two_third_bg.team
{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.one_half_bg.contact_form
{
	padding: 60px;
}

.one_fourth_bg
{
	width: 25%;
	float: left;
	background-position: 50% 50% !important;
	background-size: cover !important;
	box-sizing: border-box;
	padding: 20px;
}

.parallax
{
	position: relative;
	clear: both;
	background-size: 100%;
}

/*------------------------------------------------------------------
[10. Project Elements]
*/

.one_half
{
	float: left;
	width: 48%;
	margin-right: 3.5%;
	box-sizing: border-box;
}

.one_half.last
{
	float: right;
	width: 48%;
	margin-right: 0;
	clear: right;
}

.team_wrapper .one_half
{
	margin-bottom: 2%;
}

.portfolio2filter_wrapper
{
	overflow: hidden;
	margin: 0 12px 0 0;
	float: left;
}

.one_half.portfolio2_wrapper
{
	width: 50%;
	margin-bottom: 0px;
}

.two_cols.gallery .element
{
	width: 47.8%;
	margin-right: 2%;
	margin-bottom: 2%;
	box-sizing: border-box;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;

	opacity: 1 !important;
}

.two_cols.gallery.wide .element
{
	width: 50%;
	margin: 0 -1px 0 0;
}

.two_cols.gallery.wide .element img
{
	width: 100%;
	height: auto;
}

.two_cols.gallery .element.fade
{
	opacity: 0.2 !important;
}

.two_cols.gallery .element .portfolio_title
{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 20px;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	box-sizing: border-box;
	line-height: 1.65em;
	z-index: 9;

	transform: translate3d(0, 30px, 0);
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.two_cols.gallery .element:hover .portfolio_title
{
	transform: translate3d(0, 0px, 0);
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	visibility: visible;
	opacity: 1;
}

.two_cols.gallery .element .portfolio_title .table
{
	width: 100%;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.two_cols.gallery .element:hover .portfolio_title .table
{
	bottom: 20px;
}

.two_cols.gallery .element .portfolio_title h5
{
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.two_cols.gallery .element .portfolio_title .post_detail
{
	color: #fff;
	font-size: 10px;
}

.one_half.gallery2
{
	width: 100%;
	height: 100%;
	float: left;
	margin: 0;
	overflow: hidden;
	position: relative;
}

.one_half.gallery2.static
{
	overflow: hidden;
}

.one_half.gallery2 a
{
	position: relative;
	display: block;
	line-height: 0;
}

.one_half.gallery2.portfolio_type a:after
{
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 3;
  	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.6)),to(rgba(0,0,0,0.69)));
  	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
  	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
}

.one_half.gallery2.portfolio_type a:hover:after
{
	opacity: 1;
}

.element.classic2_cols
{
	overflow: hidden;
	position: relative;
    width: 49.8%;
    height: auto;
    margin-right: 1px;
	margin-bottom: 1px;
	float: left;
}

.one_half.gallery2.filterable.portfolio_type
{
	margin: 0;
}

.one_half.gallery2 a
{
	display: block;
}

.one_half.gallery2.classic a
{
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
}

.one_half.gallery2.classic a img
{
  	-webkit-transition: .5s cubic-bezier(0.18,1,0.23,1);
  	transition: .5s cubic-bezier(0.18,1,0.23,1);
  	backface-visibility: hidden;
  	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.one_half.gallery2.classic a:hover img
{
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-o-transform: scale(1.3);
	transform: scale(1.3);
}

.one_half.gallery2.classic a:hover .portfolio_classic_icon_wrapper
{
	opacity: 1;
}

.one_third
{
	width: 30.66%;
	float: left;
	margin-right: 4%;
	margin-bottom: 2%;
	position: relative;
	box-sizing: border-box;
}

.one_third.last
{
	margin-right: 0 !important;
	float: right;
	width: 30.66%;
}

.one_third.portfolio3_wrapper
{
	width: 30%;
	margin-bottom: 0;
	margin-right: 25px;
}

#page_content_wrapper .inner .sidebar_content ul, .page_content_wrapper .inner .sidebar_content ul
{
	margin-left: 20px;
}

#page_content_wrapper .inner .sidebar_content.full_width.portfolio3_content_wrapper
{
	width: 1250px;
}

#page_content_wrapper .inner .sidebar_content.full_width.portfolio4_content_wrapper, .page_content_wrapper.portfolio4_content_wrapper
{
	width: 1260px;
}

.page_content_wrapper.portfolio4_content_wrapper
{
	margin-top: 50px;
}

.gallery_a_title
{
	text-align: center;
}

.three_cols.gallery .element
{
	width: 31.2%;
	margin-right: 2%;
	margin-bottom: 2%;
	padding: 0;
	box-sizing: border-box;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;

	opacity: 1 !important;
	position: relative;
}

.three_cols.gallery .element.double_size
{
	width: 64.4%;
}

.three_cols.gallery.wide .element
{
	width: 33.32%;
	margin: 0 -1px 0 0;
}

.three_cols.gallery.wide .element.double_size
{
	width: 66.6%;
	margin: 0 -1px 0 0;
}

.three_cols.gallery.wide .element img
{
	width: 100%;
	height: auto;
}

.sidebar_content.three_cols.gallery .element
{
	width: 46%;
	margin-right: 3%;
	margin-bottom: 4%;
}

.three_cols.gallery .element.grid
{
	padding: 0;
}

.three_cols.gallery .element.fade
{
	opacity: 0.2 !important;
}

@media only screen and (min-width: 1430px) {
	#page_content_wrapper.fullwidth .three_cols.gallery .element, .page_content_wrapper.fullwidth .three_cols.gallery .element
	{
		width: 22.4%;
		margin-bottom: 2.5%;
	}
}

@media only screen and (min-width: 1720px) {
	#page_content_wrapper.fullwidth .three_cols.gallery .element, .page_content_wrapper.fullwidth .three_cols.gallery .element
	{
		width: 17.8%;
		margin-bottom: 2.5%;
	}
}

@media only screen and (min-width: 2200px) {
	#page_content_wrapper.fullwidth .three_cols.gallery .element, .page_content_wrapper.fullwidth .three_cols.gallery .element
	{
		width: 14.6%;
		margin-bottom: 2.5%;
	}
}

.three_cols.gallery .element .portfolio_title
{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 15px;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	box-sizing: border-box;
	line-height: 1.65em;
	z-index: 9;

	transform: translate3d(0, 30px, 0);
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.three_cols.gallery .element:hover .portfolio_title
{
	transform: translate3d(0, 0px, 0);
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	visibility: visible;
	opacity: 1;
}

.one_third.gallery3.portfolio_type a:after
{
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 3;
  	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.6)),to(rgba(0,0,0,0.69)));
  	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
  	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
}

.one_third.gallery3.portfolio_type a:hover:after
{
	opacity: 1;
}

.three_cols.gallery .element .portfolio_title .table
{
	width: 100%;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.three_cols.gallery .element:hover .portfolio_title .table
{
	bottom: 20px;
}

.three_cols.gallery .element .portfolio_title h5
{
	font-size: 18px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.three_cols.gallery .element.double_size .portfolio_title h5
{
	font-size: 26px;
}

.three_cols.gallery .element .portfolio_title .post_detail
{
	color: #fff;
	font-size: 10px;
}

.one_third.gallery3
{
	position: relative;
	width: 100%;
	margin: 0;
	overflow: hidden;
}

.one_third.gallery3 a
{
	position: relative;
	display: block;
	line-height: 0;
}

.one_third.gallery3.static
{
	overflow: hidden;
}

.one_third.gallery3 a
{
	display: block;
}

.one_third.gallery3.classic:not(.proofing) a, .one_third.gallery3.classic .overlay_mask
{
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
}

.one_third.gallery3.classic a img
{
  	-webkit-transition: .5s cubic-bezier(0.18,1,0.23,1);
  	transition: .5s cubic-bezier(0.18,1,0.23,1);
  	backface-visibility: hidden;
  	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.one_third.gallery3.classic a:hover img
{
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-o-transform: scale(1.3);
	transform: scale(1.3);
}

.portfolio_classic_icon_wrapper
{
	background: #000;
	background: rgba(0,0,0,0.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	-webkit-transition: .5s;
	transition: .5s;
}

.one_third.gallery3.classic a:hover .portfolio_classic_icon_wrapper
{
	opacity: 1;
}

.portfolio_classic_icon_content
{
	display: table;
	width: 100%;
	height: 100%;
	text-align: center;
}

.portfolio_classic_icon_content i
{
	display: table-cell;
	vertical-align: middle;
	color: #fff;
	font-size: 40px;
}

.portfolio_classic_icon_wrapper
{
	background: #000;
	background: rgba(0,0,0,0.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;

	-webkit-transition: 1s;
	transition: 1s;
}

.one_third.gallery3.classic a:hover .portfolio_classic_icon_wrapper, .one_third.gallery3.classic:hover .overlay_mask .portfolio_classic_icon_wrapper
{
	opacity: 1;
}

.portfolio_classic_icon_content
{
	display: table;
	width: 100%;
	height: 100%;
	text-align: center;
}

.portfolio_classic_icon_content i
{
	display: table-cell;
	vertical-align: middle;
	color: #fff;
	font-size: 40px;

	transform: translateY(10%);
	-webkit-transition: .6s;
	transition: .6s;
}

.portfolio_classic_icon_content .portfolio_classic_icon_content_middle i
{
	font-size: 34px;
}

.portfolio_classic_icon_content_middle
{
	display: table-cell;
	vertical-align: middle;
	color: #fff;
	text-align: center;
}

.one_third.gallery3 .portfolio_classic_icon_content_middle a
{
	display: inline-block;
	margin: 0 8px 0 8px;
}

.classic a:hover .portfolio_classic_icon_content i
{
	transform: translateY(0%);
}

.one_third.gallery3 a.image_approve.hidden, .one_third.gallery3 a.image_unapprove.hidden
{
	display: none;
}

.one_third.gallery3 .onapprove
{
	position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 110px;
    text-align: center;
    color: #fff;
    display: block;
    margin: -40px -48px 0 0;
    padding: 60px 0 4px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 9;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 0;
    min-height: 0;
    line-height: 30px;
    background: #2bb213;
}

.one_third.gallery3 .loading
{
	color: #fff;
	position: absolute;
    bottom: 5px;
    right: 10px;
}

.one_third.gallery3 .loading.hidden
{
	display: none;
}

.one_third.gallery3 .proofing_id
{
	position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    background: #000;
	background: rgba(0,0,0,0.5);
	padding: 5px 10px 2px 10px;
}

.one_third.gallery3 .onapprove.hidden
{
	display: none;
}

.element.classic3_cols
{
	float: left;
}

.one_third.gallery3.last
{
	float: right;
	margin-right: 0;
}

.one_third.gallery3 img {
	max-width: 100%;
    display: block;
    position: relative;
}

.one_third.gallery3 .one_third_img
{
	position: absolute;
}

.one_third.filterable.gallery3.portfolio_type
{
	margin: 0;
}

.one_third.gallery3 a[rel=gallery]
{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 99999;
	background: #000;
}

.two_third
{
	float: left;
	width: 65.33%;
	margin-right: 4%;
	box-sizing: border-box;
}

.two_third.last
{
	margin-right: 0;
	float: right;
	clear: right;
}

.one_fourth
{
	width: 22%;
	float: left;
	margin-right: 4%;
	margin-bottom: 2%;
	position: relative;
	box-sizing: border-box;
}

.one_fourth.last
{
	width: 22%;
	margin-right: 0;
	float: right;
	clear: right;
}

.four_cols.gallery .element
{
	width: 22.9%;
	margin-bottom: 2%;
	margin-right: 2%;
	padding: 0;
	box-sizing: border-box;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;

	opacity: 1 !important;
}

.four_cols.gallery.wide .element
{
	width: 25%;
	margin: 0 -1px 0 0;
}

.four_cols.gallery.wide .element img
{
	width: 100%;
	height: auto;
}

.four_cols.gallery .element.grid
{
	padding: 0;
	background: transparent;
}

.four_cols.gallery .element.fade
{
	opacity: 0.2 !important;
}

.four_cols.gallery .element .portfolio_title
{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 15px;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	box-sizing: border-box;
	line-height: 1.65em;
	z-index: 9;

	transform: translate3d(0, 30px, 0);
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.four_cols.gallery .element:hover .portfolio_title
{
	transform: translate3d(0, 0px, 0);
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	visibility: visible;
	opacity: 1;
}

.one_fourth.gallery4.portfolio_type a:after
{
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 3;
  	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.6)),to(rgba(0,0,0,0.69)));
  	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
  	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
}

.one_fourth.gallery4.portfolio_type a:hover:after
{
	opacity: 1;
}

.four_cols.gallery .element .portfolio_title .table
{
	width: 100%;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.four_cols.gallery .element:hover .portfolio_title .table
{
	bottom: 20px;
}

.four_cols.gallery .element .portfolio_title h5
{
	font-size: 16px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.four_cols.gallery .element .portfolio_title .post_detail
{
	color: #fff;
	font-size: 10px;
	margin-top: -5px;
}

.one_fourth.gallery4
{
	position: relative;
    width: 100%;
    margin: 0;
    overflow: hidden;
}


.one_fourth.gallery4 a
{
	position: relative;
	display: block;
	line-height: 0;
}

.one_fourth.gallery4.static
{
	overflow: hidden;
}

.element.classic4_cols
{
	float: left;
}


.one_fourth.portfolio4
{
    margin:0;
}

.one_fourth.gallery4 .one_fourth_img
{
	position: absolute;
}

.one_fourth.gallery4 a
{
	display: block;
}

.one_fourth.gallery4.classic a
{
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
}

.one_fourth.gallery4.classic a img
{
  	-webkit-transition: .5s cubic-bezier(0.18,1,0.23,1);
  	transition: .5s cubic-bezier(0.18,1,0.23,1);
  	backface-visibility: hidden;
  	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.one_fourth.gallery4.classic a:hover img
{
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-o-transform: scale(1.3);
	transform: scale(1.3);
}

.one_fourth.gallery4.classic a:hover .portfolio_classic_icon_wrapper
{
	opacity: 1;
}

.one_fifth
{
	width: 18%;
	float: left;
	margin-right: 2%;
	margin-bottom: 2%;
	box-sizing: border-box;
}

.one_fifth.last
{
	margin-right: 0;
}

.five_cols.gallery.wide .element
{
	width: 20%;
	margin: 0 -1px 0 0;
}

.one_fifth.gallery5
{
  	position: relative;
  	width: 100%;
  	margin: 0;
  	overflow: hidden;
}

.one_fifth.gallery5 a
{
  	position: relative;
  	display: block;
  	line-height: 0;
}

.five_cols.gallery.wide .element img
{
	width: 100%;
	height: auto;
}

.five_cols.gallery .element.grid
{
	padding: 0;
	background: transparent;
}

.five_cols.gallery .element.fade
{
	opacity: 0.2 !important;
}

.five_cols.gallery .element .portfolio_title
{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 15px;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	box-sizing: border-box;
	line-height: 1.65em;
	z-index: 9;

	transform: translate3d(0, 30px, 0);
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.five_cols.gallery .element:hover .portfolio_title
{
	transform: translate3d(0, 0px, 0);
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	visibility: visible;
	opacity: 1;
}

.one_fifth.gallery5.portfolio_type a:after
{
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 3;
  	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.6)),to(rgba(0,0,0,0.69)));
  	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
  	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
}

.one_fifth.gallery5.portfolio_type a:hover:after
{
	opacity: 1;
}

.five_cols.gallery .element .portfolio_title h5
{
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.five_cols.gallery .element .portfolio_title .post_detail
{
	color: #fff;
	font-size: 10px;
	margin-top: -5px;
	font-size: 9px;
}

.one_sixth
{
	width: 11%;
	float: left;
	margin-right: 6%;
	margin-bottom: 2%;
	box-sizing: border-box;
}

.one_sixth.last
{
	margin-right: 0;
}

.image_classic_frame
{
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	line-height: 0;
}

.image_classic_frame.border
{
	border: 1px solid #e1e1e1;
}

.image_classic_frame.glow
{
	border: 1px solid #e1e1e1;
	-moz-box-shadow: 0 0 5px rgba(0,0,0,.2);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,.2);
	box-shadow: 0 0 5px rgba(0,0,0,.2);
}

.image_classic_frame.dropshadow
{
	padding:0;
	-moz-box-shadow: 0 0 15px rgba(0,0,0,.2);
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,.2);
	box-shadow: 0 0 15px rgba(0,0,0,.2);
}

.image_classic_frame.bottomshadow
{
	padding:0;
	position: relative;
}

.image_classic_frame.bottomshadow:before, .image_classic_frame.bottomshadow:after
{
	position: absolute;
	bottom: 15px;
	left: 10px;
	z-index: -1;
	height: 20%;
	width: 60%;
	content: "";
	opacity: 0.7;
	-webkit-box-shadow: 0 17px 10px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 0 17px 10px rgba(0, 0, 0, 0.6);
	box-shadow: 0 17px 10px rgba(0,0,0,0.6);
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	transform: rotate(-3deg);

	opacity: 0.5;
}

.image_classic_frame.bottomshadow:after
{
	left: auto;
	right: 10px;
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	transform: rotate(3deg);
}

.image_classic_frame.bottomshadow img
{
	margin: 0;
}

.image_caption, .gallery_image_caption
{
	padding: 20px 0 5px 0;
	font-size: 12px;
	font-style: italic;
}

.image_description
{
	clear: both;
	margin-top: 30px;
}

.image_description:empty
{
	display: none;
}

.one_half_bg .image_caption
{
	position: absolute;
	bottom: 15px;
	color: #fff;
}

.single_portfolio_gallery
{
	width: 100%;
	height: 400px;
	overflow: hidden;
	position: relative;
	z-index: 0;
	top: -35px;
	background: #f0f0f0;
}

.single_portfolio_gallery i.fa
{
	font-size: 30px;
	position: absolute;
	top: 43%;
	left: 49%;
}

.fullscreen_desc
{
	width: 80%;
	margin: auto;
	margin-bottom: 10px;
}

.portfolio_desc
{
	float: left;
	text-align: left;
	margin-top: 15px;
}

.portfolio_desc.team
{
	width: 100%;
	float: none;
	text-align: center;
	margin-bottom: 5px;
}

.team_wrapper .post_detail
{
	font-size: 11px;
}

.team_position
{
	color: #999;
	font-size: 11px;
}

.portfolio_desc.wide
{
	margin: 0;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border-left: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
}

.portfolio_desc.portfolio4, .portfolio_desc.portfolio3, .portfolio_desc.portfolio2
{
	width: 100%;
	padding: 0;
	text-align: center;
}

.portfolio_desc.portfolio2 h5
{
	font-size: 22px;
}

.portfolio_desc.portfolio2 .post_detail
{
	font-size: 10px;
	margin-bottom: 0;
}

.portfolio_desc.portfolio3 h5
{
	font-size: 18px;
}

.portfolio_desc.portfolio3 .post_detail
{
	font-size: 10px;
	margin-bottom: 0;
}

.portfolio_desc.portfolio4 h5
{
	font-size: 16px;
}

.portfolio_desc.portfolio4 .post_detail
{
	font-size: 10px;
	margin-bottom: 0;
}

.portfolio_desc p
{
	margin: 10px 0 0 0;
}

.gallery_archive_desc
{
	position: absolute;
	width: 100%;
	bottom: 15px;
	z-index: 3;
	text-align: center;
	line-height: normal;

	opacity: 0;
	transform: translate3d(0, 30px, 0);
	-webkit-transform: translate3d(0, 30px, 0);
	-moz-transform: translate3d(0, 30px, 0);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}

#fullpage .section.gallery_archive .gallery_archive_desc
{
	opacity: 1;
	bottom: 60px;
}

#fullpage .section.gallery_archive .gallery_archive_desc .post_detail
{
	color: #fff;
	margin: 0;
}

.gallery_archive_desc h4
{
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: initial;
}

#fullpage .section.gallery_archive .gallery_archive_desc h4
{
	font-size: 26px;
}

.one_third.gallery3 .gallery_archive_desc h4
{
	font-size: 16px;
}

.one_fourth.gallery4 .gallery_archive_desc h4
{
	font-size: 14px;
}

.gallery_type.archive a:before
{
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;

	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(50%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(93%,rgba(0,0,0,0.6)),to(rgba(0,0,0,0.69)));
	background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
	background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.69) 100%);
}

.gallery_type.archive:hover a:before
{
	opacity: 1;
}

.gallery_type.archive:hover .gallery_archive_desc
{
	 opacity: 1;
	 transform: translate3d(0, 0px, 0);
	 -webkit-transform: translate3d(0, 0px, 0);
	 -moz-transform: translate3d(0, 0px, 0);
}

.gallery_type.archive img
{
	opacity: 1;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}

.gallery_type.archive .post_detail
{
	margin: 0;
	float: none;
	color: #fff;
	font-size: 10px;
}

.one_fourth.gallery4.gallery_type.archive .post_detail
{
	font-size: 9px;
}

.gallery-main {
	display: none;
}

.wall_thumbnail {
	float:left;
	width: 100%;
	margin: 0;
	overflow: hidden;
	position: relative;
}

.wall_thumbnail img {
    display: block;
    position: relative;
    height: auto;
    max-width: 100%;
}

#photo_wall_wrapper, .photo_wall_wrapper {
	width: 100%;
	margin: 0 auto;
	height: 0;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	-webkit-transition: opacity 1s ease;
	-moz-transition: opacity 1s ease;
	-ms-transition: opacity 1s ease;
	-o-transition: opacity 1s ease;
	transition: opacity 1s ease;
	visibility: hidden;
	opacity: 0;
	overflow: visible !important;
}

.photo_wall_wrapper.shortcode.withmargin
{
	margin-left: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 99%;
}

#photo_wall_wrapper.visible, .photo_wall_wrapper.visible
{
	visibility: visible;
	opacity: 1;
}

#photo_wall_wrapper.has_sidebar {
	width: 670px;
}

#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry
{
	float: left;
	border: none;
	padding: 0;
	position: relative;
	width: 24.9%;
	margin: 0;
	word-wrap: break-word;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

#photo_wall_wrapper .wall_entry.fade, .photo_wall_wrapper .wall_entry.fade
{
	opacity: 0.2 !important;
}

#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry.withmargin
{
	width: 23.2%;
	margin: 0.5%;
	margin-bottom: 1%;
}

@media only screen and (min-width: 1400px) {
	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry
	{
		width: 19.99%;
		margin-bottom: 1%;
	}

	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry.withmargin
	{
		width: 18.5%;
		margin: 0.5%;
		margin-bottom: 1%;
	}
}

@media only screen and (min-width: 2100px) {
	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry
	{
		width: 16.6%;
	}

	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry.withmargin
	{
		width: 15%;
		margin: 0.5%;
	}
}

#photo_wall_wrapper .wall_entry .wall_entry_wrapper, .photo_wall_wrapper .wall_entry .wall_entry_wrapper {
	position: relative;
}

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.3s;
     -moz-transition-duration: 0.3s;
      -ms-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
          overflow: visible !important;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
          opacity: 1 !important;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

.gallery_mansory_wrapper, .gallery_mansory4_wrapper {
	padding: 0;
	margin: 0 auto;
	margin-bottom: 20px;
	width: 960px;
}

.page-template-template-gallery-thumbnails-php .gallery_mansory_wrapper {
  padding: 0px;
  margin: 0 auto;
}

.portfolio_filter_dropdown
{
	width: 15em;
	background: #fff;
	position: relative;
	z-index: 9;
	margin-top: 20px;
	font-family: 'Oswald', 'Helvetica Neue', Arial,Verdana,sans-serif;
	display: inline-block;
	text-align: left;

	-webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

#page_content_wrapper .inner .sidebar_wrapper .sidebar .content .portfolio_filter_dropdown
{
	float: left;
	margin-bottom: 40px;
	margin-top: 0;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_title a
{
	display: block;
	height: 3em;
	width: 100%;
	border-radius: 3px;
	text-decoration: none;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_title a span
{
	display: block;
	padding: 0 1em;
	cursor: pointer;
	line-height: 3em;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_title a span:after
{
	text-decoration: inherit;
	-webkit-font-smoothing: antialiased;
	display: inline;
	width: auto;
	height: auto;
	line-height: normal;
	vertical-align: 10%;
	background-image: none;
	background-position: 0% 0%;
	background-repeat: repeat;
	margin-top: 0;
	font-family: 'FontAwesome';
	content: "\f107";
	float: right;
	line-height: 3em;
	font-size: 13px;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_select
{
	position: relative;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_select ul.portfolio_select
{
	display: none;
	position: absolute;
	left: 0;
	top: -3em;
	width: 100%;
	list-style: none;
	background: #fff none repeat scroll 0 0;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_select ul.portfolio_select li a
{
	display: block;
	padding: 0 1em;
	line-height: 3em;
	text-decoration: none;
	color: #888;
}

.portfolio_filter_dropdown .portfolio_filter_dropdown_select ul.portfolio_select li a:hover
{
	background: #f9f9f9;
	color: #444;
}

#portfolio_filter_wrapper, .portfolio_filter_wrapper
{
	width: 960px;
	padding-top: 40px;
	height: 0;
	-webkit-transition: opacity 1s ease;
	-moz-transition: opacity 1s ease;
	-ms-transition: opacity 1s ease;
	-o-transition: opacity 1s ease;
	transition: opacity 1s ease;
	visibility: hidden;
	opacity: 0;
}

.portfolio_filter_wrapper
{
	opacity: 1;
}

.portfolio_filter_wrapper, body.tax-portfoliosets #portfolio_filter_wrapper, #portfolio_filter_wrapper
{
	padding-top: 0;
}

#portfolio_filter_wrapper.visible, .portfolio_filter_wrapper.visible
{
	visibility: visible;
	opacity: 1;
}

#portfolio_filter_wrapper.gallery, .portfolio_filter_wrapper.gallery
{
	width: 100%;
	margin: 0;
	box-sizing: border-box;
}

#portfolio_filter_wrapper.gallery.two_cols, .portfolio_filter_wrapper.gallery.two_cols, #portfolio_filter_wrapper.gallery.three_cols, .portfolio_filter_wrapper.gallery.three_cols, #portfolio_filter_wrapper.gallery.four_cols, .portfolio_filter_wrapper.gallery.four_cols
{
	width: 102%;
	margin: 0;
	box-sizing: border-box;
}

#portfolio_filter_wrapper.gallery.wide, .portfolio_filter_wrapper.gallery.wide
{
	width: 101%;
	margin: 0;
}

.page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, .page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery
{
	width: 98%;
	margin-left: 2%;
	box-sizing: border-box;
}

body.single-attachment p.attachment
{
	display: none;
}

.gallery_type, .portfolio_type
{
	opacity: 0;
}

.gallery_img
{
	opacity: 0;
	-webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
}

.project_info_label
{
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
	color: #999;
}

.project_info_value
{
	font-size: 20px;
	color: #444;
	margin-bottom: 20px;
}

.one_fourth.withbg .page_content_wrapper, .one_third.withbg .page_content_wrapper, .one_half.withbg .page_content_wrapper, .two_third.withbg .page_content_wrapper
{
	padding: 0 10% 0 10%;
	box-sizing: border-box;
}

.ppb_fullwidth_button h2.title
{
	font-size: 22px;
	display: block;
	float: left;
}

.ppb_fullwidth_button .button
{
	float: right;
}

.error_box
{
	background: #efc337;
	margin: 0 auto;
	width: 310px;
	height: 310px;
	position: relative;
}

.error_box .error_type
{
	font-size: 156px;
	text-align: right;
	letter-spacing: -15px;
	margin-bottom: 0;
	color: #fff;
	line-height: 0;
	right: 0;
	bottom: -26px;
	position: absolute;
	letter-spacing: -15px;
}

.error_text
{
	font-size: 53px;
	letter-spacing: -1.5px;
	color: #efc337;
	margin-top: 0;
	text-transform: uppercase;
	text-align: center;
	padding: 0 !important;
}

#client_header
{
	width: 70%;
	margin: auto;
	padding: 50px 0 50px 0;
}

#client_header .client_thumbnail, .client_thumbnail
{
	margin: auto;
	width: 150px;
}

.overlay_gallery_wrapper .client_thumbnail
{
	width: 150px;
	float: none;
	margin: auto;
}

#client_header .client_thumbnail img, .client_thumbnail img
{
	border-radius: 200px;
	margin-bottom: 10px;
}

#client_header .client_content
{
	margin: auto;
	text-align: center;
}

#portfolio_mixed_filter_wrapper:not(.wide)
{
	margin-top: 30px;
}
