html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td 
{
	margin:0;
	padding:0;
	border:0;
	font-weight:inherit;
	font-style:inherit;
	font-size:100%;
	font-family:inherit;
	vertical-align:baseline;
}
body 
{
	line-height:1.5;
}
blockquote:before, blockquote:after, q:before, q:after 
{
	content:"";
}
blockquote, q 
{
	quotes:"" "";
}
a img 
{
	border:none;
}