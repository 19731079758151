/**
*
* Widescreen Devices
*
**/
@media only screen and (min-width: 1100px) {
	#menu_wrapper
	{
		max-width: 1425px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 90px;
	}

	#page_content_wrapper, .page_content_wrapper, .standard_wrapper, .footer_bar_wrapper, #footer ul.sidebar_widget
	{
		max-width: 1425px;
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		padding: 0 90px;
	}

	#page_content_wrapper.wide, .page_content_wrapper.wide
	{
		width: 100%;
		max-width: 100%;
		padding: 0;
	}

	.footer_bar_wrapper
	{
		padding-top: 20px;
	}

	#page_content_wrapper.fullwidth, .page_content_wrapper.fullwidth
	{
		width: 100%;
		padding: 0;
		max-width: 100%;
	}

	.mobile_menu_wrapper .page_content_wrapper
	{
		padding: 0 !important;
	}

	#page_content_wrapper .inner .sidebar_content, .page_content_wrapper .inner .sidebar_content
	{
		width: 75%;
		width: calc(79% - 50px);
	}

	#page_content_wrapper .inner .sidebar_content.portfolio, .page_content_wrapper .inner .sidebar_content.portfolio
	{
		width: 73%;
		padding-right: 0;
		border: 0;
	}

	#page_content_wrapper .inner .sidebar_wrapper, .page_content_wrapper .inner .sidebar_wrapper, #page_content_wrapper .inner .sidebar_wrapper.left_sidebar
	{
		width: 21%;
	}

	#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content
	{
		width: 75%;
		margin-right: 0;
		padding-right: 10px;
		box-sizing: border-box;
	}

	#logo_wrapper .social_wrapper
	{
		left: 90px;
	}

	#logo_right_button
	{
		right: 90px;
	}
}


/*
#Tablet (Landscape)
==================================================
*/

@media only screen and (min-width: 960px) and (max-width: 1099px)
{
	#page_caption h1, .ppb_title, h2.ppb_title
	{
		font-size: 36px;
	}

	#page_content_wrapper.split #portfolio_filter_wrapper.two_cols.gallery .element
	{
		width: 100%;
		margin-right: 0;
	}

	.portfolio_desc.portfolio4 .post_detail
	{
		font-size: 8px;
	}

	html[data-style=flow], html[data-style=flow] body
	{
		overflow-y: hidden;
	}

	.gallery_type.archive a:before
	{
		opacity: 1;
	}

	.gallery_type.archive .gallery_archive_desc
	{
		 opacity: 1;
		 transform: translate3d(0, 0px, 0);
		 -webkit-transform: translate3d(0, 0px, 0);
		 -moz-transform: translate3d(0, 0px, 0);
	}

	.three_cols.gallery .element .portfolio_title, .two_cols.gallery .element .portfolio_title, .four_cols.gallery .element .portfolio_title, .five_cols.gallery .element .portfolio_title
	{
		transform: translate3d(0, 0px, 0);
		-webkit-transform: translate3d(0, 0px, 0);
		-moz-transform: translate3d(0, 0px, 0);
		visibility: visible;
		opacity: 1;
	}

	.one_third.gallery3.portfolio_type a:after, .one_half.gallery2.portfolio_type a:after, .one_fourth.gallery4.portfolio_type a:after, .one_fifth.gallery5.portfolio_type a:after
	{
		opacity: 1;
	}

	.view_fullscreen_wrapper
	{
		display: none;
	}

	.post_header.grid h6
	{
		font-size: 18px;
	}

	.three_cols.gallery.wide .element.double_size
	{
		width: 66.65%;
	}
}


/*
#Tablet (Portrait)
==================================================
*/

@media only screen and (min-width: 768px) and (max-width: 960px) {
	body { font-size: 13px; }
	.mobile_menu_wrapper { display: block; }
	#wrapper { padding-top: 80px; }
	.top_bar { width: 100%; padding: 0; }
	#logo_wrapper { padding: 20px 0 20px 0; }
	.logo_container { margin-top: -5px; }
	.logo_wrapper img { width: auto; max-height: 50px;}
	.footer_before_widget .footer_logo.logo_wrapper img { max-height: none; margin-top: 0; margin: auto; }
	html[data-style=fullscreen] #logo_wrapper, .top_bar.hasbg #logo_wrapper { border: 0; }
	#logo_wrapper { border: 0; }
	.top_bar { border: 0; }

	.standard_wrapper { width: 100%; padding: 0 30px 0 30px; box-sizing: border-box; }
	.page_content_wrapper .inner { width: 100%; padding: 0; box-sizing: border-box; }
	.ppb_text.fullwidth .page_content_wrapper .inner { padding: 0; }
	h1, { font-size: 30px; }
	h2 { font-size: 22px; }
	h3 { font-size: 20px; }
	h4 { font-size: 18px; }
	h5 { font-size: 16px; }
	h6 { font-size: 14px; }
	h7 { font-size: 12px; }
	.service_title h6 { font-size: 20px; }
	#page_caption h1 { font-size: 26px; }

	#page_content_wrapper .inner .sidebar_content.full_width, .gallery_mansory_wrapper { width: 100%; }
	#page_content_wrapper .inner .sidebar_content.full_width img, .page_content_wrapper img { max-width: 100%; height: auto; }
	#page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper, #page_content_wrapper .inner .sidebar_content.full_width#galleries_grid_wrapper { width: 00%; }
	#menu_wrapper { width: 100%; }
	#page_content_wrapper, .page_content_wrapper, #page_caption .page_title_wrapper { width: 100%; }
	.footer_bar { width: 100%; padding-bottom: 0; }
	#portfolio_filter_wrapper { width: 710px; }
	.page_control_static { display: none !important; }
	#menu_border_wrapper { height: auto; display: none; }
	.gallery_social { opacity: 0; }
	.portfolio_header h6 { width: 89%; }
	#page_content_wrapper .inner .sidebar_content, .page_content_wrapper .inner .sidebar_content, #page_content_wrapper .inner .sidebar_content.portfolio { width: 62%; border: 0; }

	body.page-template-portfolio-classic-fullwidth #page_content_wrapper .inner .sidebar_content.full_width #portfolio_filter_wrapper, body.page-template-portfolio-classic-masonry-fullwidth #page_content_wrapper .inner .sidebar_content.full_width #portfolio_filter_wrapper { width: 100%; margin-left: 0; }
	body.page-template-portfolio-grid-fullwidth #page_content_wrapper .inner .sidebar_content.full_width #portfolio_filter_wrapper, body.page-template-portfolio-grid-masonry-fullwidth #page_content_wrapper .inner .sidebar_content.full_width #portfolio_filter_wrapper { width: 100%; margin-left: 0.5%; }
	.one_half.gallery2 .mask { width: 100%; height: 231px; }
	.one_half.gallery2:hover .mask .mask_circle { margin-top: 28%; }
	.one_half.gallery2 h4 { font-size: 22px; }
	.portfolio2filter_wrapper { margin-right: 30px; }
	.element.classic2_cols { width: 47%; }
	.one_half.gallery2 .mask .mask_circle i { font-size: 28px; line-height: 32px; }
	.one_half.gallery2:hover .mask .mask_circle { width: 28px; height: 28px; margin-top: 24%; }

	#page_content_wrapper .inner .sidebar_content.full_width.portfolio3_content_wrapper { width: 780px; }

	.two_third, .two_third.last, .one_third, .one_third.last { width: 100%; margin-right: 0; margin-bottom: 2%; clear: both; }
	.one_third.gallery3:hover .mask .mask_circle { margin-top: 22%; width: 24px; height: 24px; }
	.one_third.gallery3 .mask { width: 100%; height: 100%; }
	.one_third.portfolio3 { height: 390px; }
	.one_third.gallery3 div.thumb_content h3 { font-size: 18px; }
	.element.classic3_cols { width: 31%; }

	.one_fourth, .one_fourth.last { width: 100%; margin-right: 0; margin-bottom: 2%; clear: both; }
	.one_fourth.gallery4 { margin: 0; }
	.one_fourth.gallery4 .button.circle img { width: 24px !important; height: 24px !important; }
	.one_fourth.gallery4 .mask { width: 100%; height: 100%; }
	.one_fourth.gallery4:hover .mask .mask_circle { margin-top: 27%; }
	.portfolio_desc.portfolio4 h7 { font-size: 16px; }
	.one_fourth.gallery4 div.thumb_content h3 { font-size: 18px; }
	.portfolio4filter_wrapper { margin-right: 0; }
	.element.classic4_cols { width: 22.5%; }

	.one_third_bg, .one_fourth_bg { width: 100%; }

	.one_fifth, .one_fifth.last { width: 100%; margin-right: 0; margin-bottom: 2%; clear: both; }

	#portfolio_filter_wrapper.shortcode.four_cols.gallery .element { width: 50%; }

	#page_content_wrapper .inner .sidebar_content.full_width.portfolio4_content_wrapper { width: 760px; }
	.portfolio_header h6 { font-size: 16px; }
	.one_fourth.portfolio4 { height: 320px; }
	.portfolio_desc { width: 100%; }
	.post_img.animate div.thumb_content i { font-size: 2em; margin: 0 5px 0 5px; }
	#page_content_wrapper .inner .sidebar_wrapper { width: 220px; margin: 0; }
	#page_content_wrapper .inner .sidebar_wrapper .sidebar .content { margin: 0; }
	#page_content_wrapper .inner .sidebar_wrapper .sidebar { width: 100%; }
	.social_wrapper ul li img { width: 22px; }
	div.home_header { font-size: 70px; letter-spacing: -2px; line-height: 80px; margin-left: 40px; margin-right: 40px; }

	#page_content_wrapper .inner { width: 100%; }
	.post_date .date { font-size: 40px; }
	.post_date .month { font-size: 14px; line-height: 18px; }
	.post_date .year { font-size: 14px;  line-height: 15px; }
	.post_date { margin-top: -13px; }
	#page_content_wrapper .sidebar .content .posts.blog li img, #page_content_wrapper .posts.blog li img { width: 50px; height: auto; }
	.post_header.quote { padding-bottom: 10px; }
	#page_content_wrapper .sidebar .content .sidebar_widget li h2.widgettitle, h2.widgettitle, h5.widgettitle { font-size: 13px; }
	#footer { width: 100%; padding: 40px 30px 40px 30px; box-sizing: border-box; }
	#footer ul.sidebar_widget { width: 100%; margin: auto; }
	#page_content_wrapper { width: 100%; margin-top: 0; padding: 0 30px 0 30px; box-sizing: border-box; }
	#page_content_wrapper.wide { padding: 0; }
	#page_content_wrapper .sidebar .content .posts.blog { float: left; margin-bottom: 20px; width: 100%; }
	.comment { width: 720px; }
	.comment .right { width: 82%; }
	ul.children div.comment .right { width: 78%; }
	#content_wrapper ul.children ul.children { width: 77%; }
	#menu_wrapper .nav ul, #menu_wrapper div .nav { display: none; }

	.social_wrapper { float: none; margin: auto; text-align: center; width: auto; }
	.footer_bar_wrapper .social_wrapper { margin-right: 10px; }
	.social_wrapper ul { margin-top: 2px; }
	.above_top_bar .social_wrapper ul { margin-top: 0; }
	.footer_bar_wrapper { width: 726px; }
	.footer_bar.fullscreen, .footer_bar.flow, .footer_bar.kenburns, .footer_bar.flip, .footer_bar.static, .footer_bar.fullscreen_video { width: 200px; left: 37%; }
	.footer_bar_wrapper.fullscreen, .footer_bar_wrapper.flow, .footer_bar_wrapper.kenburns, .footer_bar_wrapper.flip, .footer_bar_wrapper.static, .footer_bar_wrapper.fullscreen_video { width: 200px; text-align: center; }
	#photo_wall_wrapper .wall_entry img { max-width: 260px; }
	.wall_thumbnail h6 { font-size: 14px !important; margin-top: 30%; }
	.wall_thumbnail.dynamic_height h6 { margin-top: 25%; }
	.mansory_thumbnail.double_height { width: 31.5%; height: auto; }
	.mansory_thumbnail.double_width { width: 64%; height: auto; }
	.mansory_thumbnail .gallery_type img { max-width: 100% !important; height: auto; }
	.mansory_thumbnail.double_width { height: 282px; }
	.mansory_thumbnail { width: 31.5%; }
	#imageFlow.single_portfolio { height: 800px; top: 5px; }
	.single_portfolio_gallery { height: 412px; }
	#contact_form label { margin-top: 20px; }
	#contact_submit_btn { margin-top: 20px; }
	.page_layerslider { height: auto; margin-bottom: 0; }
	#imageFlow { top: 12%; }
	.page_audio { top: 14px; right: 55px; }
	#page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper { width: 100%; }
	body.page-template-blog_g-php .post.type-post, body.page-template-galleries-php .gallery.type-gallery { width: 210px; }
	#page_content_wrapper .inner .sidebar_content.full_width .post_wrapper.grid_layout .post_img img { width: 100% !important; height: auto !important; }

	#page_content_wrapper .inner .sidebar_content { margin-right: 0; margin-top: 0; padding-right: 10px; padding-top: 0; }
	#page_content_wrapper .inner .sidebar_content.left_sidebar { margin-right: 0; padding-top: 0; padding-left: 30px; }
	#page_content_wrapper .inner .sidebar_wrapper, #page_content_wrapper .inner .sidebar_wrapper.left_sidebar { width: 28%; margin-right: 0; padding-left: 10px; padding-top: 0; margin-right: 0; }
	#page_content_wrapper .inner .sidebar_wrapper, .page_content_wrapper .inner .sidebar_wrapper { margin-left: 25px; }
	.page_content_wrapper .inner .sidebar_wrapper { width: 34%; margin-top: 20px; }
	#page_content_wrapper .inner .sidebar_wrapper.left_sidebar { padding-left: 0; margin-right: 0; }
	#page_content_wrapper .inner .sidebar_content.full_width .post_wrapper .post_img img, #page_content_wrapper .inner .sidebar_content .post_wrapper .post_img img { max-width: 100%; height: auto; }

	.post_content_wrapper { width: 100%; }
	#page_content_wrapper .inner .sidebar_content.full_width .post_wrapper.full .post_img img { max-width: 726px; height: auto; }
	.comment .right { width: 54%; }
	ul.children div.comment .right { width: 62%; }
	#page_content_wrapper .sidebar .content .sidebar_widget li ul.flickr li img { width: 62px; height: auto; }
	.page_control { left: 46%; }	#page_content_wrapper .inner .sidebar_content.full_width.portfolio4_content_wrapper, .page_content_wrapper.portfolio4_content_wrapper { width: 760px; }
	body.page-template-blog_g-php .post.type-post, body.error404 .post.type-post, body.page-template-galleries-php .galleries.type-galleries, body.tax-gallerycat .galleries.type-galleries, .ppb_blog_posts .post.type-post { width: 100%; margin-right: 0; }
	.ppb_blog_posts .post.type-post { margin-right: 0; }
	#blog_grid_wrapper { width: 740px; }
	.post_img.grid img { max-width: 218px; height: auto; }
	#footer ul.sidebar_widget li ul.posts.blog li img { width: 50px; }
	.post_circle_thumb { width: 50px; height: 50px; }
	a#imgflow-prevslide, a#imgflow-nextslide { top: 30%; }
	body[data-style=carousel] #slidecaption, body[data-style=carousel].hover #slidecaption { bottom: 280px; }
	.wall_thumbnail:hover .mask .mask_circle { margin-top: 28%; }
	.mansory_thumbnail:hover .mask .mask_circle { margin-top: 12%; }
	.mansory_thumbnail.double_width:hover .mask .mask_circle { margin-top: 23%; }
	.imageFlow_gallery_info_wrapper #flow_info_button { bottom: 7px; }
	#photo_wall_wrapper .wall_entry.three_cols { width: 31%; }
	.service_title { max-width: 90%; }
	.one_third.gallery3 .mask .mask_circle i { font-size: 24px; line-height: 24px; }
	.ppb_transparent_video_bg .post_title h3 { font-size: 40px; line-height: 44px; }
	.ppb_transparent_video_bg .post_title .post_excerpt { font-size: 16px; }
	.service_wrapper.center .service_icon { width: 44px; height: 44px; margin-bottom: 5px !important; }
	.social_follow { display: none; }
	.social_wrapper.team { width: 100%; }
	#photo_wall_wrapper.has_sidebar { width: 470px; }
	.wall_thumbnail div.thumb_content h3 { font-size: 18px; }
	.wall_thumbnail div.thumb_content h4 { font-size: 18px; }
	#vimeo_bg, #youtube_bg { top: 65px; }
	#photo_wall_wrapper { width: 746px; max-width: 746px !important; margin: auto; padding-top: 30px; }
	#map_contact { height: 300px; }
	.mejs-overlay-button {display:block; z-index: 99999;}
	#nav_wrapper.header_style3, #nav_wrapper.header_style4 { margin-top: 0; border: 0; }

	#footer .sidebar_widget.four > li { width: 46%; margin-right: 3%; margin-bottom: 3%; }
	#footer .sidebar_widget.four > li:nth-child(2), #footer .sidebar_widget.four > li:nth-child(4) { width: 46%; margin-right: 0; float: right; }
	#footer .sidebar_widget.four > li:nth-child(3) { clear: both; }
	.portfolio_desc.team h5 { font-size: 20px; }
	.portfolio_desc h6, .portfolio_desc h5 { font-size: 18px; }
	#page_caption.hasbg h1, h1.hasbg { font-size: 26px; }

	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry.withmargin { width: 47.7%; }
	body.page-template-portfolio-classic-fullwidth .three_cols.gallery .element, body.page-template-portfolio-classic-masonry-fullwidth .three_cols.gallery .element { width: 47%; margin: 0 1% 2% 1%; }

	#page_caption.hasbg { height: 500px; }
	#slidecaption { padding-right: 0; height: auto; padding-bottom: 20px; padding-right: 20px; }
	.post_previous_content, .post_next_content { width: 80%; }
	.post_previous_content h6, .post_next_content h6 { font-size: 14px; }
	.one.withsmallpadding:not(.withbg):not(.fullwidth), .one.withpadding:not(.withbg):not(.fullwidth) { padding: 30px 0 30px 0 !important; background-attachment: scroll !important; background-repeat: repeat !important; background-position: center top !important; }
	.one_fourth.pricing, .one_fourth.pricing.last { clear: none; width: 23%; }
	.one_third.pricing, .one_third.pricing.last { clear: none; width: 31%; }
	.post_type_icon { margin-right: 20px; }
	.page_caption_desc { margin-bottom: 50px; }
	h1.product_title { font-size: 26px; }
	p.price ins span.amount, p.price span.amount { font-size: 20px; }
	.post_img.small img.thumbnail_gallery { max-width: 143px !important; }

	#photo_wall_wrapper .wall_entry.three_cols, .photo_wall_wrapper .wall_entry.three_cols { width: 47%; }
	.team_wrapper .one_half, .team_wrapper .one_half.last, .testimonial_wrapper .one_half, .testimonial_wrapper .one_half.last { width: 100%; margin-right: 0; margin-bottom: 4%; clear: both; }

	.carousel_img img { max-width: 100% !important; height: auto; }
	.one.withbg, .one.withbg[data-mouse="mouse_pos"], .one.withbg[data-type="background"] { background-attachment: scroll !important; background-size: cover !important; background-position: center top !important; }
	#page_caption.hasbg h1.withtopbar, h1.hasbg.withtopbar, #page_caption.hasbg.notransparent h1.withtopbar { margin-top: 160px !important; }
	.ppb_subtitle { font-size: 12px; margin-bottom: 0; }
	.one_half_bg.nopadding { padding: 0 !important; }
	.one_half_bg, .one_third_bg, .two_third_bg { padding: 0; }
	.icon_list li .service_title strong { font-size: 14px; }
	a.button.fullwidth.ppb .title { font-size: 26px; }
	#copyright { float: none; width: 100%; margin-bottom: 0; line-height: 23px; }
	input[type=submit].medium, input[type=button].medium, a.button.medium { padding: .3em 1.2em .3em 1.2em; }
	.contact_form_wrapper input[type=text], .contact_form_wrapper input[type=email], .contact_form_wrapper input[type=date] { margin-bottom: 4%; }
	.one_half_bg.contact_form { padding: 40px; }
	#page_content_wrapper .inner .sidebar_content, .page_content_wrapper .inner .sidebar_content { margin-right: 0; }
	#page_content_wrapper .inner .sidebar_content.left_sidebar { border: 0; padding-left: 10px; }
	.ppb_content { width: 100%; }
	.page_content_wrapper.fullwidth .portfolio_filter_dropdown, #page_content_wrapper.fullwidth .portfolio_filter_dropdown { margin-right: 3%; }
	#page_content_wrapper .inner #portfolio_filter_wrapper.sidebar_content { width: 100%; padding: 0; }
	.sidebar_content.three_cols.gallery .element { width: 100%; margin-right: 0; }
	.portfolio_next_prev_wrapper .portfolio_prev, .portfolio_next_prev_wrapper .portfolio_next { height: 300px; }
	.portfolio_next_prev_wrapper .portfolio_prev img, .portfolio_next_prev_wrapper .portfolio_next img { width: auto; height: 300px; }
	#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content { width: 460px; margin-right: 10px; }
	#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content.left_sidebar { padding-left: 0; }
	#blog_grid_wrapper.sidebar_content:not(.full_width) .post.type-post { width: 100%; }
	#page_content_wrapper .posts.blog li, .page_content_wrapper .posts.blog li { width: 100%; float: none; margin-right: 0; }
	#page_content_wrapper .inner .sidebar_content #commentform p.comment-form-author, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-email, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-url { width: 27.6%; }
	.page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, .page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery { width: 97%; margin-left: 2%; }
	.post_img.team { border-radius: 0; }
	.post_header h5, .post_quote_title { font-size: 16px; }
	.pricing_wrapper li.price_row em.exact_price { font-size: 50px !important; }
	.pricing_wrapper li.price_row strong { top: -18px; }
	.pricing_wrapper li.button_row, .pricing_wrapper li.button_row.featured { padding: 10px 0; }
	.pricing_wrapper li.button_row a.button { margin: 0 10px 0 10px; }
	.post_header.search { width: 80%; }
	#searchform input[type=text] { width: 70%; }

	.animate, .animated, .gallery_type, .portfolio_type, .fadeIn, .bigEntrance, .post_img.fadeIn, .post_img.grid.fadeIn { -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important; opacity: 1; visibility: visible; }

  	.ppb_fullwidth_button { text-align: center; }
  	.ppb_fullwidth_button h2.title { float: none; width: 100%; }
  	.ppb_fullwidth_button .button { float: none; margin-top: 10px; }
  	.parallax { max-height: 500px; }
  	.one_half .standard_wrapper, .one_third .standard_wrapper, .one_fourth .standard_wrapper, .one_fifth .standard_wrapper, .two_third .standard_wrapper, .one_half .page_content_wrapper, .one_third .page_content_wrapper, .one_fourth .page_content_wrapper, .one_fifth .page_content_wrapper, .two_third .page_content_wrapper

  	.one_half .page_content_wrapper .inner, .one_third .page_content_wrapper .inner, .one_fourth .page_content_wrapper .inner, .one_fifth .page_content_wrapper .inner, .two_third .page_content_wrapper .inner
	{
	    max-width: 100%;
	    width: 100%;
	    padding: 0;
	}

	#portfolio_filter_wrapper.gallery.two_cols, .portfolio_filter_wrapper.gallery.two_cols, #portfolio_filter_wrapper.gallery.three_cols, .portfolio_filter_wrapper.gallery.three_cols, #portfolio_filter_wrapper.gallery.four_cols, .portfolio_filter_wrapper.gallery.four_cols
	{
		width: 103%;
	}
	#page_caption h1, .ppb_title, h2.ppb_title
	{
		font-size: 36px;
	}
	.one_half_bg { padding: 30px !important; }

	.one_third_bg.team_photo { width: 33.3%; }
	.two_third_bg.team { width: 66.6%; padding: 20px; }
	#page_content_wrapper .inner .sidebar_content #commentform p.comment-form-author, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-email, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-url { width: 100%; box-sizing: border-box; }

	.extend_top_contact_info { float: right; padding-top: 20px; float: right; margin-left: 7px; }
	.view_fullscreen_wrapper
	{
		display: none;
	}
	.gallery_type.archive a:before
	{
		opacity: 1;
	}

	.gallery_type.archive .gallery_archive_desc
	{
		 opacity: 1;
		 transform: translate3d(0, 0px, 0);
		 -webkit-transform: translate3d(0, 0px, 0);
		 -moz-transform: translate3d(0, 0px, 0);
	}

	.three_cols.gallery .element .portfolio_title, .two_cols.gallery .element .portfolio_title, .four_cols.gallery .element .portfolio_title, .five_cols.gallery .element .portfolio_title
	{
		transform: translate3d(0, 0px, 0);
		-webkit-transform: translate3d(0, 0px, 0);
		-moz-transform: translate3d(0, 0px, 0);
		visibility: visible;
		opacity: 1;
	}

	.one_third.gallery3.portfolio_type a:after, .one_half.gallery2.portfolio_type a:after, .one_fourth.gallery4.portfolio_type a:after, .one_fifth.gallery5.portfolio_type a:after
	{
		opacity: 1;
	}

	.three_cols.gallery .element .portfolio_title h5
	{
		font-size: 16px;
	}

	.three_cols.gallery .element .portfolio_title .post_detail
	{
		font-size: 8px;
	}

	.four_cols.gallery .element .portfolio_title .post_detail, .five_cols.gallery .element .portfolio_title .post_detail
	{
		display: none;
	}

	.three_cols.gallery .element .portfolio_title
	{
		bottom: 5px;
	}

	#page_content_wrapper.split #portfolio_filter_wrapper.two_cols.gallery .element
	{
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}

	#page_content_wrapper.split #portfolio_filter_wrapper.two_cols.gallery.wide .element
	{
		margin-bottom: 0;
	}

	.portfolio_desc.portfolio4 .post_detail
	{
		display: none;
	}

	.top_bar.scroll #logo_wrapper
	{
		display: block;
	}

	.portfolio_desc.portfolio3 .post_detail
	{
		font-size: 8px;
	}

	.filter li a
	{
		padding: 0.7em 0.7em 0.7em;
	}

	.header_cart_wrapper
	{
		margin-right: 10px;
	}

	#mobile_nav_icon
	{
		display: inline-block !important;
	}

	.three_cols.gallery.wide .element.double_size
	{
		width: 66.65%;
	}
}

/*
#Mobile (Portrait)
==================================================
*/

@media only screen and (max-width: 767px) {
	body { -webkit-text-size-adjust: none; }
	body { font-size: 13px; }
	.logo_container { display: block; }
	.logo_wrapper img { max-width: 250px; height: auto; margin-top: 5px; margin-bottom: 5px; }
	.footer_before_widget .footer_logo.logo_wrapper img { max-height: none; margin-top: 0; margin: auto; }

	.top_bar { width: 100%; padding: 0 40px 0 30px; height: 48px !important; border: 0; }
	#top_contact_hours, #top_contact_number { display: none; }

	.standard_wrapper { width: 100%; padding: 0 30px 0 30px; box-sizing: border-box; }
	.ppb_text.fullwidth .page_content_wrapper .inner { padding: 0; }
	h1 { font-size: 24px!important; }
	h2 { font-size: 22px; }
	h3 { font-size: 20px; }
	h4 { font-size: 18px; }
	h5 { font-size: 16px; }
	h6 { font-size: 15px; }
	h7 { font-size: 14px; }
	.post_header.grid h6, .post_quote_title.grid { font-size: 16px; }
	.portfolio_desc h5, .wall_thumbnail div.thumb_content h4 { font-size: 16px; }
	#page_caption h1 { font-size: 22px !important; line-height: 30px; }
	.filter li a { padding: 1em 0.5em 1em 0.5em; }
	#crumbs { float: none; clear: both; line-height: 20px; padding-top: 5px; }
	#page_caption .page_title_wrapper { width: 100%; margin: auto; }
	#page_caption { margin: 0; padding-left: 30px; padding-right: 30px; padding-top: 40px; padding-bottom: 40px; box-sizing: border-box; }

	#page_content_wrapper .inner .inner_wrapper { padding: 0; }
	#page_content_wrapper .inner .sidebar_content.full_width, .gallery_mansory_wrapper { width: 100%; margin-top: 15px; margin-bottom: 0; }
	#page_content_wrapper .inner .sidebar_content img { max-width: 100%; height: auto; }
	#page_content_wrapper { width: 100%; margin-top: 0; padding: 0 30px 0 30px; box-sizing: border-box; }
	#page_content_wrapper.wide, .page_content_wrapper.wide { padding: 0; }
	#page_content_wrapper .inner { width: 100%; padding: 0; }
	#page_content_wrapper .inner #page_main_content { margin-top: 0; padding-bottom: 0; margin-bottom: 30px; }
	#page_content_wrapper .inner, { width: 100%; margin: auto; }
	#page_content_wrapper .inner .sidebar_content { width: 100%; margin-top: 0; padding-bottom: 0; float: none; border: 0 !important; }
	#page_content_wrapper .inner .sidebar_content.portfolio, #page_content_wrapper .inner .sidebar_content.portfolio #portfolio_filter_wrapper { padding-top: 0; }
	#page_content_wrapper .inner .sidebar_content.full_width { width: 100%; margin-top: 0; }
	#page_content_wrapper .inner #page_caption.sidebar_content.full_width { padding-bottom: 20px; }
	#page_content_wrapper .inner .sidebar_content.full_width img { max-width: 100%; height: auto !important; }
	#page_content_wrapper .inner .sidebar_wrapper { width: 100%; margin-left: 0; margin-bottom: 0; margin-top: 25px; padding-top: 0; }
	#page_content_wrapper .inner .sidebar_wrapper .sidebar .content { margin: 0; }
	#page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper, #page_content_wrapper .inner .sidebar_content.full_width#galleries_grid_wrapper { width: 100%; }
	.portfolio_header h6 { width: 31%; font-size: 12px; }
	#page_content_wrapper .inner .sidebar_content img { max-width: 100%; height: auto; }
	.comment { width: 270px; }
	.comment_arrow { display: none; }
	.fullwidth_comment_wrapper .comment .right { width: 66%; margin-left: 15px; }
	.fullwidth_comment_wrapper ul.children div.comment .right { width: 64%; padding: 0; }

	ul.children div.comment ul.children div.comment .right { width: 50%; }

	#content_wrapper ul.children ul.children { width: 100%; }
	ul.children .comment { margin-left: 0; }
	#content_wrapper ul.children { border: 0; }
	#content_wrapper ul.children ul.children { margin-left: -16px; }

	.comment_wrapper #respond { margin-left: 20px; }
	.comment_wrapper ul.children #respond { margin-left: -55px; }
	#menu_wrapper { width: 100%; display: none; }
	#menu_border_wrapper { height: auto; display: none; }
	input[type="submit"], input[type="button"], a.button, .button { font-size: 12px; }
	#portfolio_filter_wrapper { width:300px; }

	#portfolio_filter_wrapper.two_cols { width: 100%; }
	#portfolio_filter_wrapper.two_cols .element { width: 100%; float: none; }
	.one_half.gallery2, .one_half.portfolio2, .one_half.gallery2 .mask, .one_half.gallery2.filterable { width: 100%; height: auto; }
	.one_half.gallery2 .mask { width: 100%; height: 100% !important; }
	.one_half.portfolio2 { margin-bottom: 5%; margin-right: 5%; }
	.one_half.gallery2 .mask .button.circle { margin-top: 30%; }
	.one_half.gallery2 img.blur_effect, .one_half.portfolio2 img.blur_effect { width: 100%; height: auto; }
	.one_half.gallery2 h4 { font-size: 14px !important; }
	.one_half.gallery2:hover .mask .mask_circle { width: 32px; height: 32px; margin-top: 22%; }
	.one_half.gallery2 .mask .mask_circle i { font-size: 30px; line-height: 34px; }

	#portfolio_filter_wrapper.three_cols { width: 100%; }
	#portfolio_filter_wrapper.three_cols .element { width: 100%; float: left; margin-right: 0%;}
	.one_third.gallery3, .one_third.portfolio3, .one_third.gallery3 .mask, .one_third.gallery3.filterable { width: 100%; height: auto; }
	.one_third.gallery3 .button.circle img { width: 30px !important; height: 30px !important; }
	.one_third.gallery3 .mask { width: 100%; height: 100% !important; }
	.one_third.gallery3 img.blur_effect, .one_third.portfolio3 img.blur_effect { width: 100%; height: auto; }
	.one_third.gallery3 h5 { font-size: 14px !important; }
	.one_third.gallery3:hover .mask .mask_circle { width: 32px; height: 32px; margin-top: 22%; }
	.one_third.gallery3 .mask .mask_circle i { font-size: 30px; line-height: 34px; }

	#portfolio_filter_wrapper.four_cols { width: 100%; }
	#portfolio_filter_wrapper.four_cols .element { width: 100%; float: none; margin-right: 0;}
	.one_fourth, .one_fourth.last { width: 100%; margin-right: 0; margin-bottom: 2%; clear: both; }
	.one_fourth.gallery4, .one_fourth.portfolio4, .one_fourth.gallery4 .mask, .one_fourth.gallery4.filterable { width: 100%; height: auto; }
	.one_fourth.gallery4 img.blur_effect, .one_fourth.portfolio4 img.blur_effect { width: 100%; height: auto; }
	.one_fourth.gallery4 .mask { width: 100%; height: 100% !important; }
	.one_fourth.gallery4.portfolio_type h6 { margin-top: 34%; }
	.one_fourth.gallery4:hover .mask .mask_circle { width: 32px; height: 32px; margin-top: 22%; }
	.one_fourth.gallery4 .mask .mask_circle i { font-size: 30px; line-height: 34px; }

	#page_content_wrapper .inner .sidebar_content.full_width.portfolio3_content_wrapper, #page_content_wrapper .inner .sidebar_content.full_width.portfolio4_content_wrapper { width: 300px; }
	.one_third.portfolio3_wrapper, .one_fourth.portfolio4_wrapper { width: 100%; clear: both; }

	.post_date { display: none; }
	.post_header { width: 100%; }
	.post_header h5 { font-size: 22px; letter-spacing: 0; }
	blockquote { width: 100%; margin: 0; font-size: 18px; }
	blockquote:before { font-size: 2.0em; }
	.nav_wrapper_inner { padding-top: 10px; }
	#respond { margin-bottom: 0; width: 100%; }
	.post_wrapper { width: 100%;; margin-top: 0; }
	#nav_wrapper { display: none; }
	.mansory_thumbnail h6{ margin-top: 20%; font-size: 14px !important; }
	.mansory_thumbnail.double_width h6 { margin-top: 20%; }
	#footer { padding-top: 20px; padding-bottom: 0; }
	#page_content_wrapper .inner .sidebar_wrapper .sidebar { width: 100%; }

	#photo_wall_wrapper { width: 300px; }
	#footer { width: 100%; }
	#footer ul.sidebar_widget { margin-left: 10px; }
	#footer ul.sidebar_widget { width: 100%; float: none; margin: auto; padding: 0 30px 0 30px; box-sizing: border-box; }
	#footer .sidebar_widget.four > li, #footer .sidebar_widget.three > li, #footer .sidebar_widget.two > li, #footer .sidebar_widget.one > li, #footer .sidebar_widget.four > li:nth-child(4), #footer .sidebar_widget.three > li:nth-child(3), #footer .sidebar_widget.two > li:nth-child(2) { width: 100%; }
	.footer_bar { margin-bottom: 0; }
	#copyright { width: 100%; }
	.social_wrapper { float: none; margin: auto; text-align: center; width: auto; }
	.social_wrapper ul { margin-top: 15px; }
	.social_wrapper ul, .social_wrapper ul li { display: inline-table; }
	input#gallery_password { width: 60% !important; }
	.footer_bar_wrapper { width: 100%; }
	#copyright { float: left; width: 100%; padding: 0 30px 0 30px; box-sizing: border-box; }
	.one_third, .one_third.last, .one_third.pricing, .one_fourth.pricing { width: 100%; margin-bottom: 3%; margin-right: 0; }
	.one_third.pricing { margin-bottom: 25px; }
	.two_third, .two_third.last { width: 100%; margin-bottom: 3%; }

	#footer .sidebar_widget.four > li { margin-bottom: 20px; }
	#photo_wall_wrapper.has_sidebar { width: 100%; }
	.footer_bar_wrapper.fullscreen .social_wrapper , .footer_bar_wrapper.flow .social_wrapper, .footer_bar_wrapper.kenburns .social_wrapper, .footer_bar_wrapper.wall, .footer_bar_wrapper.flip .social_wrapper, .footer_bar_wrapper.static .social_wrapper { display: none !important; }
	.post_content_wrapper { width: 100%; }
	ul.children div.comment .right { width: 86%; }
	.page_control { left: 39%; bottom: 65px; }
	.one.fullwidth .page_content_wrapper { padding: 0; }
	.page_content_wrapper.nopadding { padding: 0 !important; }
	#page_content_wrapper .inner .sidebar_content.full_width.portfolio4_content_wrapper, .page_content_wrapper.portfolio4_content_wrapper { width: 100% !important; }

	.one_fourth, .one_fourth.last { width: 100%; margin-right: 0; }

	.post_img img { max-width: 100% !important; height: auto; }
	body.page-template-blog_g-php .post.type-post, body.error404 .post.type-post, body.search .hentry, body.page-template-galleries-php .galleries.type-galleries, body.tax-gallerycat .galleries.type-galleries, .ppb_blog_posts .post.type-post { width: 100%; }
	#blog_grid_wrapper .post.type-post { width: 100% !important; margin-left: 0; }
	#page_content_wrapper .inner .sidebar_wrapper.left_sidebar { width: 100%; padding-top: 0; margin-right: 0; margin-top: 30px; margin-bottom: 25px; }
	.post_header.full, .post_excerpt_full { width: 96%; }
	#page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper { width: 100%; }

	#blog_grid_wrapper { width: 100%; }
	.ppb_transparent_video_bg .post_title h3 { font-size: 24px; line-height: 28px; }
	.ppb_transparent_video_bg .post_title .post_excerpt { font-size: 14px; }
	.client_logo { max-width: 100%; }
	.one_half, .one_half.last { width: 100%; float: none; clear: both; margin-bottom: 15px; }
	#blog_grid_wrapper { padding-top: 20px; }
	#photo_wall_wrapper .wall_entry.three_cols, .photo_wall_wrapper .wall_entry.three_cols { width: 100%; }
	.promo_box { text-align: center; }
	.promo_box .button { float: none; margin: auto; margin-bottom: 15px; }
	#portfolio_filter_wrapper.shortcode { margin-top: 20px; }
	#photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry { width: 100%; float: none; }
	#photo_wall_wrapper .wall_entry.two_cols, .photo_wall_wrapper .wall_entry.two_cols, #photo_wall_wrapper .wall_entry.four_cols, .photo_wall_wrapper .wall_entry.four_cols { width: 100%; margin-bottom: 10px; }
	.post_img { margin-bottom: 20px; }
	#social_share_wrapper, .social_share_wrapper { margin-top: 30px; }
	#vimeo_bg, #youtube_bg { top: 65px; }
	.post_img .mask { height: 121px !important; }
	.post_img:hover .mask .mask_circle { width: 32px; height: 32px; margin-top: 14%; padding: 10px; }
	.post_img.full:hover .mask .mask_circle { margin-top: 11%; }
	.post_img:hover .mask .mask_circle i { font-size: 24px; line-height: 28px; }
	.post_img.grid .mask { height: 159px !important; }
	#page_content_wrapper .posts.blog li { width: 100%; }
	body.single .post.type-post { float: none; }
	.post_header h6 { font-size: 16px; }
	#about_the_author .description { width: 180px; }
	body.author .post_img .mask, body.search .post_img .mask { height: 228px !important; }
	body.author .post_img.small.medium  .mask .mask_circle, body.search .post_img.small.medium  .mask .mask_circle { margin-top: 26%; width: 34px; height: 34px; }
	body.page-template-contact-php #page_content_wrapper { margin-top: 0; }
	.alert_box_msg { max-width: 80% !important; }
	.social_wrapper.shortcode ul li { margin: 5px; }
	.one_fifth { width: 100%; float: none; }
	.post_img.square_thumb { width: 140px; float: left; }
	.post_img.square_thumb .mask { height: 100% !important; }
	.post_img.square_thumb:hover .mask .mask_circle { margin-top: 30%; }
	.service_wrapper { padding: 0; }
	.footer_bar_wrapper .social_wrapper ul { margin-top: 0; }
	.above_top_bar .page_content_wrapper { margin: 0; margin: auto; }
	.above_top_bar .top_contact_info { width: 100%; float: none; margin: auto; text-align: center; border: 0; }
	.above_top_bar .top_contact_info span { border: 0; }
	.above_top_bar .social_wrapper { display: none; }
	.mejs-overlay-button {display:block; z-index: 99999;}
	.one.withsmallpadding:not(.withbg):not(.fullwidth), .one.withpadding:not(.withbg):not(.fullwidth) { padding: 20px 0 20px 0 !important; box-sizing: border-box; }
	.promo_box_wrapper .promo_box h5 { padding-right: 0; }
	.post_qoute_mark { display: none; }
	#portfolio_filter_wrapper.shortcode.four_cols.gallery .element { width: 100%; margin-right: 0; margin-bottom: 2%; }
	.page_caption_desc { margin-bottom: 30px; }

	.footer_bar_wrapper .social_wrapper { width: 100%; text-align: center; float: none; margin: 0 0 10px 0; }
	.footer_bar_wrapper .social_wrapper ul li { float: none; display: inline-block; margin: 0 5px 0 5px; }

	.post_quote_title { font-size: 16px; }
	.post_previous_icon, .post_next_icon { display: none; }
	.post_previous_content h6, .post_next_content h6 { font-size: 14px; line-height: 20px; }
	.comment .right { width: 100%; margin-left: 0; padding: 0 0 15px 0; }

	.post_type_icon { width: 40px; height: 40px !important; }
	body.search .post_header { width: 72%; }

	h1.product_title { font-size: 20px; }
	.carousel_img img { max-width: 100% !important; height: auto; }
	.post_img.small img.thumbnail_gallery { max-width: 140px !important; }
	.promo_box h5 { text-align: center; width: 100%; }
	.one.withbg, .one.withbg[data-mouse="mouse_pos"], .one.withbg[data-type="background"] { background-attachment: scroll !important; background-size: cover !important; background-position: center top !important; }

	.three_cols.gallery .element, .four_cols.gallery .element, #photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry, #photo_wall_wrapper .wall_entry.three_cols, #photo_wall_wrapper .wall_entry.four_cols, .photo_wall_wrapper .wall_entry.four_cols { width: 100%; margin-right: 0%; }
	.page_content_wrapper .inner, .standard_wrapper { width: 100%; }

	.one_half_bg, .one_third_bg, .two_third_bg, .one_fourth_bg { width: 100%; padding: 30px !important; }
	.one_half_bg.nopadding, .two_third_bg > div, .one_half_bg > div, .one_third_bg > div, .one_fourth_bg > div { padding: 0 !important; }
	.one .page_content_wrapper.withbg { max-height: 300px; }
	.ppb_subtitle { margin-bottom: 0; }
	#page_content_wrapper .inner .sidebar_content, .page_content_wrapper .inner .sidebar_content, #page_content_wrapper .inner .sidebar_content.portfolio { width: 100%; margin-right: 0; border: 0; margin-bottom: 30px; float: left; padding-right: 0; }
	#page_content_wrapper .inner .sidebar_content.left_sidebar, .page_content_wrapper .inner .sidebar_content.left_sidebar { width: 100%; float: none; padding: 0; }
	#page_content_wrapper .inner .sidebar_wrapper, .page_content_wrapper .inner .sidebar_wrapper { width: 100%; float: none; margin: 0; }

	.post_img.team { border-radius: 0; }
	.portfolio_desc.team { margin-bottom: 15px; }
	.page_content_wrapper.fullwidth .portfolio_filter_dropdown, #page_content_wrapper.fullwidth .portfolio_filter_dropdown, .portfolio_filter_dropdown { width: 50%; float: left; margin-bottom: 20px; }
	.page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth #portfolio_filter_wrapper.gallery, .page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery, #page_content_wrapper.fullwidth .portfolio_filter_wrapper.gallery { width: 100%; margin: 0; }
	#page_content_wrapper .inner #portfolio_filter_wrapper.sidebar_content { width: 100%; }
	.portfolio_next_prev_wrapper .portfolio_prev, .portfolio_next_prev_wrapper .portfolio_next { width: 100%; height: 250px; }
	.portfolio_next_prev_wrapper .portfolio_prev img, .portfolio_next_prev_wrapper .portfolio_next img { width: auto; height: 250px; }
	.portfolio_next_prev_wrapper .portfolio_prev .effect .caption > div, .portfolio_next_prev_wrapper .portfolio_next .effect .caption > div { height: 80%; }
	.page_content_wrapper .inner > div { width: 100% !important; }
	#page_content_wrapper .inner #blog_grid_wrapper.sidebar_content, #blog_grid_wrapper.sidebar_content:not(.full_width) .post.type-post { width: 100%; margin-right: 0; }
	.post_share_bubble { left: 30px; }
	.photo_wall_wrapper.shortcode, #photo_wall_wrapper .wall_entry, .photo_wall_wrapper .wall_entry.withmargin { width: 100%; margin: 0; }
	.pricing_content_wrapper.light .one_half.pricing { width: 100%; }
	.one_third_bg.team_photo { padding-bottom: 0!important; }
	.map_shortcode_wrapper { max-height: 300px; }
	#page_content_wrapper .inner .sidebar_content.full_width img, .page_content_wrapper img{ max-width: 100%; height: auto; }
	#footer_menu { float: left; width: 100%; padding: 0 30px 0 30px; box-sizing: border-box; }
	#footer_menu li:first-child { margin-left: 0; }

	.animate, .animated, .gallery_type, .portfolio_type, .fadeIn, .bigEntrance, .post_img.fadeIn, .post_img.grid.fadeIn { -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important; opacity: 1; visibility: visible; }

  #page_content_wrapper .inner .sidebar_content.full_width#blog_grid_wrapper, .page_content_wrapper .inner .sidebar_content.full_width.blog_grid_wrapper { width: 100%; }
  #page_content_wrapper .inner #blog_grid_wrapper.sidebar_content.left_sidebar { padding-left: 0; }

  	.ppb_fullwidth_button { text-align: center; }
  	.ppb_fullwidth_button h2.title { float: none; width: 100%; }
  	.ppb_fullwidth_button .button { float: none; margin-top: 10px; }
  	.parallax { max-height: 400px; }
  	.tg_stats { margin-left: -8%; }
  	h2.ppb_title { font-size: 26px; margin-top: 10px; }
  	h2.ppb_title .ppb_title_first { font-size: 18px; margin-bottom: 15px; }
  	#footer_before_widget_text { width: 85%; }
  	#toTop { right: 17px; }
  	.portfolio_filter_dropdown .portfolio_filter_dropdown_title a span { padding-right: 1.5em; }
  	.one.withsmallpadding, .one.withpadding { padding: 20px 0 20px 0 !important; box-sizing: border-box; }
  	.team_card_wrapper { margin: 0; }
  	.post.type-post { margin-bottom: 40px; }
  	#page_caption.hasbg .post_detail, #page_caption.hasbg .post_detail a, #page_caption.hasbg .post_detail a:hover, #page_caption.hasbg .post_detail a:active, .page_tagline { font-size: 11px; }
  	.post_type_icon i { line-height: 38px; }
  	.extend_top_contact_info { float: right; padding-top: 20px; float: right; margin-left: 7px; }
  	#searchform input[type=text] { width: 71%; }
  	#page_content_wrapper .inner .sidebar_content #commentform p.comment-form-author, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-email, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-url { width: 100%; box-sizing: border-box; }

  #logo_wrapper .social_wrapper { display: none; }
  #logo_wrapper, html[data-style=fullscreen] #logo_wrapper, .top_bar.hasbg #logo_wrapper { border: 0; }
  .view_fullscreen_wrapper
  {
	  display: none;
  }
  .gallery_type.archive a:before
	{
		opacity: 1;
	}

	.gallery_type.archive .gallery_archive_desc
	{
		 opacity: 1;
		 transform: translate3d(0, 0px, 0);
		 -webkit-transform: translate3d(0, 0px, 0);
		 -moz-transform: translate3d(0, 0px, 0);
	}

	.three_cols.gallery .element .portfolio_title, .two_cols.gallery .element .portfolio_title, .four_cols.gallery .element .portfolio_title, .five_cols.gallery .element .portfolio_title
	{
		transform: translate3d(0, 0px, 0);
		-webkit-transform: translate3d(0, 0px, 0);
		-moz-transform: translate3d(0, 0px, 0);
		visibility: visible;
		opacity: 1;
	}

	.one_third.gallery3.portfolio_type a:after, .one_half.gallery2.portfolio_type a:after, .one_fourth.gallery4.portfolio_type a:after, .one_fifth.gallery5.portfolio_type a:after
	{
		opacity: 1;
	}

	.two_cols.gallery.wide .element, .three_cols.gallery.wide .element, .two_cols.gallery .element, .four_cols.gallery.wide .element, .five_cols.gallery.wide .element
	{
		width: 100%;
		margin-right: 0;
	}

	.two_cols.gallery.wide .element, .three_cols.gallery.wide .element, .four_cols.gallery.wide .element, .five_cols.gallery.wide .element
	{
		margin-bottom: 0;
	}

	.footer_photostream li
	{
		width: 20%;
	}

	.post_excerpt
	{
		width: 100%;
	}

	#horizontal_gallery, .horizontal_gallery
	{
		opacity: 1 !important;
	}

	#horizontal_gallery_wrapper img.horizontal_gallery_img, .horizontal_gallery_wrapper img.horizontal_gallery_img
	{
		max-height: 200px !important;
	}

	.top_bar.scroll #logo_wrapper
	{
		display: block !important;
	}

	.portfolio_desc
	{
		margin-bottom: 15px;
	}

	.page_tagline
	{
		padding-top: 20px;
	}

	#page_caption.split, #page_caption.hasbg.split
	{
		width: 100%;
		position: relative;
		height: 200px;
		min-height: initial;
	}

	#page_caption.hasbg
	{
		height: 200px;
	}

	#page_caption.hasbg #bg_regular, #page_caption.hasbg #bg_blurred
	{
		height: 200px;
	}

	#page_content_wrapper.split, .page_content_wrapper.split, #page_content_wrapper.split.wide, .page_content_wrapper.split.wide
	{
		width: 100%;
		position: relative;
	}

	#page_caption.split, #page_caption.hasbg.split, #page_content_wrapper.split, .page_content_wrapper.split, #page_content_wrapper.split.wide, .page_content_wrapper.split.wide
	{
		top: 0 !important;
	}

	#page_content_wrapper.split, .page_content_wrapper.split, #page_content_wrapper.split.wide, .page_content_wrapper.split.wide
	{
		padding-top: 0;
	}

	#page_caption.split .page_title_wrapper
	{
		position: absolute;
		width: 100%;
		bottom: 0;
	}

	.page_tagline, .post_header .post_detail, .recent_post_detail, .post_detail, .thumb_content span, .portfolio_desc .portfolio_excerpt, .testimonial_customer_position, .testimonial_customer_company
	{
		font-size: 11px;
	}

	.textwidget img
	{
		text-align: center;
	}

	.mobile_menu_wrapper
	{
		width: 270px;
	}

	.mobile_main_nav li.menu-item-has-children > a:after
	{
		left: 240px;
	}

	.mobile_menu_wrapper #searchform input[type=text]
	{
		width: 70%;
	}

	.mobile_menu_wrapper .sidebar .content .sidebar_widget li ul.flickr li
	{
		width: 50%;
	}

	.fullscreen_share_content #social_share_wrapper ul li a i
	{
		font-size: 3em;
	}

	.fullscreen_share_content #social_share_wrapper ul li
	{
		margin: 0 10px 0 10px;
	}

	.header_cart_wrapper
	{
		margin-right: 10px;
	}

	body.js_nav #overlay_background {
	  visibility: visible;
	  opacity: 1;
	  -webkit-transform: translate(-270px,0px);
	  -moz-transform: translate(-270px,0px);
	  transform: translate(-270px,0px);
	}

	.ppb_text
	{
		padding: 20px 0 20px 0 !important;
	}

	.one_half.ppb_text, .one_third.ppb_text, .two_third.ppb_text, .one_fourth.ppb_text
	{
		padding: 20px 0 0 0 !important;
	}

	#mobile_nav_icon
	{
		display: inline-block !important;
	}

	#post_featured_slider, #post_featured_slider li .slider_image
	{
	    margin-top: 0;
	    height: 300px !important;
	}

	#post_featured_slider.three_cols
	{
	    height: 450px !important;
	}

	#post_featured_slider li .slider_image .slide_post h2
	{
	    font-size: 18px;
	}

	#post_featured_slider li .slider_image.three_cols
	{
	    width: 100%;
	    height: 150px !important;
	}

	#post_featured_slider
	{
	    margin-bottom: 30px;
	}

	#post_featured_slider li .slider_image .slide_post
	{
	    bottom: 5px;
	}

	#post_featured_slider li .slider_image.three_cols .slide_post
	{
	    padding: 0 10px 0 10px;
	}

	.three_cols.gallery.wide .element.double_size, .two_cols.gallery.wide .element, .three_cols.gallery.wide .element, .two_cols.gallery .element, .four_cols.gallery.wide .element, .five_cols.gallery.wide .element
	{
		width: 100%;
		margin-right: 0;
	}
}

/*
#Mobile (Landscape)
==================================================
*/

@media only screen and (min-width: 480px) and (max-width: 767px) {
	body { -webkit-text-size-adjust: none; }
	.map_shortcode_wrapper { max-height: 200px; }
	.portfolio_next_prev_wrapper .portfolio_prev, .portfolio_next_prev_wrapper .portfolio_next { width: 50%; height: 200px; }
	.portfolio_next_prev_wrapper .portfolio_prev img, .portfolio_next_prev_wrapper .portfolio_next img { width: auto; height: 200px; }
	#page_content_wrapper .inner .sidebar_content #commentform p.comment-form-author, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-email, #page_content_wrapper .inner .sidebar_content #commentform p.comment-form-url { width: 25.8%; }
	.tg_stats { margin-left: 0; }
}

@media screen and (max-width: 667px) and (orientation:landscape) {
  body { -webkit-text-size-adjust: none; }
  .tg_stats { margin-left: 0; }
}
